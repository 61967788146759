import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";

const SkeletonLoader = (props) => {
    const { isImage, rowCount } = props;
    return (
        <>
            {isImage && (
                <div className="avatar-xl rounded-circle text-center mb-5">
                    <Skeleton className="avatar-xl rounded-circle text-center" />
                </div>
            )}
            {rowCount && (
                <div className="hc-skeleton-loader">
                    <Skeleton count={rowCount} />
                </div>
            )}
        </>
    );
};

export default SkeletonLoader;
