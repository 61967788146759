import React from "react";
import { Spinner } from "reactstrap";
import "./Loader.scss";
const Loader = (props) => {
    const {
        classes = "",
        size = "40",
        color = "primary",
        showVerifyText = false,
        fullPageLoader = false,
        paddingTop = false
    } = props;
    return (
        <div
            className={`d-flex flex-column justify-content-center align-items-center w-100  ${fullPageLoader && "hc-loader-full-page "
                } ${classes} ${paddingTop && "padding-top-required "
                }`}
        >
            <Spinner size={size} color={color} />
            {showVerifyText && <p>Verifying</p>}
        </div>
    );
};

export default Loader;
