import React, { useState } from "react";
import ModalGroup from "./ModalAssets/Groups/Groups";
import ModalFooter from "./ModalAssets/Footer/Footer";
import RadioButton from "../../components/RadioButton/RadioButton";
import CustomButton from "../../components/Button/Button";
import { withNamespaces } from "react-i18next";
import "./BasicModal.scss";
import { isSubmitting } from "redux-form";

const RemovalModal = (props) => {
    const [isvalid, setIsValid] = useState(true);
    const [data, setData] = useState(null);
    const onChange = (e) => {
        setIsValid(true);
        setData({ option: parseInt(e.currentTarget.value) });
    };
    const onSubmit = () => {
        if (data == null || !data.option) {
            setIsValid(false);
        } else {
            props.onClick(data);
        }
    };

    return (
        <>
            <ModalGroup
                title={props.t(
                    `Are you sure you want to remove ${
                        props?.reportAction?.ReportedName || "-"
                    } from the platform?`
                )}
                subTitle={props.t("You can choose from other actions as well")}
            >
                <div className="removable-modal-container">
                    <div className="d-flex flex-column justify-content-between removal-modal-row">
                        <div className="removal-modal-items">
                            <RadioButton
                                radioName="removal-modal"
                                label="Warning"
                                value={1}
                                difference="1"
                                onChange={onChange}
                            />
                        </div>
                        <div className="removal-modal-items">
                            <RadioButton
                                radioName="removal-modal"
                                label="Suspension"
                                value={2}
                                difference="2"
                                onChange={onChange}
                            />
                        </div>
                        <div className="removal-modal-items">
                            <RadioButton
                                radioName="removal-modal"
                                label="Removal"
                                value={3}
                                difference="3"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>
                {!isvalid && (
                    <div
                        className={
                            !isvalid &&
                            "custom-inp-error-text justify-content-between radio-button-row radio-button-row-reduce-padding"
                        }
                    >
                        {props.t("Please select any option")}
                    </div>
                )}
            </ModalGroup>
            <ModalFooter>
                <CustomButton className="button-type-3 reduce-radius btn-sm" onClick={onSubmit}>
                    <span className="h6 color-white">{props.t("Confirm")}</span>
                </CustomButton>
            </ModalFooter>
        </>
    );
};

export default withNamespaces()(RemovalModal);
