import React, { Component, createRef } from "react";
import { Col, Row } from "reactstrap";
import { withNamespaces } from "react-i18next";
import "./EditableContent.css";
import { Loader } from "../Loader";
import NotAvailablePlaceholder from "../NotAvailablePlaceholder";
import { isNumber } from "../../utils/validateEmailHelpers";
import notificationToastr from "../../utils/toastUtil";

class EditableContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            isHover: false,
            contentValue: props.content || ""
        };
        this.containerRef = createRef();
        this.inputRef = createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.content !== this.props.content) {
            this.setState({ contentValue: this.props.content || "" });
        }
        if (prevProps.isLoading !== this.props.isLoading && !this.props.isLoading) {
            this.handleCancelClick();
            this.inputRef.current.blur();
        }
    }

    bindDocumentLister = () => {
        document.getElementById("root").addEventListener("click", this.documentClick);
    };

    unbindDocumentLister = () => {
        document.getElementById("root").removeEventListener("click", this.documentClick);
    };

    documentClick = (e) => {
        const { isEditing } = this.state;
        const { isLoading } = this.props;

        if (
            isEditing &&
            !isLoading &&
            this.containerRef.current &&
            !this.containerRef.current.contains(e.target)
        ) {
            this.handleCancelClick();
            this.unbindDocumentLister();
        }
    };

    handleMouseEnter = () => {
        this.setState({ isHover: true });
    };

    handleMouseLeave = () => {
        this.setState({ isHover: false });
    };

    handleInputFocus = () => {
        this.setState({ isEditing: true });
        this.bindDocumentLister();
    };

    handleContentChange = (e) => {
        const { inputType = "text" } = this.props;
        let value = e.target.value;

        if (inputType === "number" && !isNumber(value)) {
            return;
        }
        this.setState({ contentValue: value });
    };

    handleCancelClick = () => {
        this.setState({ isEditing: false, contentValue: this.props.content });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const { isRequired = true } = this.props;
        const { contentValue } = this.state;

        if (isRequired && (!contentValue || !contentValue.trim().length === 0)) {
            notificationToastr.error(this.props.t("FieldMustBeFilled"));
            return;
        }
        this.props.onContentSubmit(this.state.contentValue);
    };

    render() {
        const { contentValue, isEditing, isHover } = this.state;
        const { isLoading, contentClasses, isShowEditIcon } = this.props;
        const editIcon = require("../../assets/images/edit.svg");

        return (
            <div className={!isShowEditIcon ? "editable-content-main" : ""} ref={this.containerRef}>
                {!isEditing && !isHover ? (
                    <>
                        <div
                            className={`${contentClasses}`}
                            onMouseOver={!isShowEditIcon ? this.handleMouseEnter : null}
                        >
                            {contentValue ? contentValue : <NotAvailablePlaceholder />}
                            {isShowEditIcon && (
                                <span>
                                    <img
                                        className="cursor-pointer m-l-sm align-bottom"
                                        src={editIcon}
                                        onClick={() => {
                                            this.handleMouseEnter();
                                        }}
                                    />
                                </span>
                            )}
                        </div>
                    </>
                ) : (
                        <form
                            className="editable-content-form"
                            onSubmit={
                                !isShowEditIcon
                                    ? this.handleSubmit
                                    : (e) => {
                                        this.handleSubmit(e);
                                        this.handleMouseLeave();
                                    }
                            }
                        >
                            <input
                                ref={this.inputRef}
                                className={`editable-content-input ${isEditing ? "editing" : ""} ${!contentValue && !isEditing ? "no-value" : ""
                                    }`}
                                value={contentValue ? contentValue : !isEditing ? "" : ""}
                                id={"input-Field"}
                                onMouseLeave={this.handleMouseLeave}
                                onChange={this.handleContentChange}
                                onFocus={this.handleInputFocus}
                                onBlur={
                                    isShowEditIcon &&
                                    (() => {

                                        this.handleMouseLeave();
                                    })
                                }
                            />
                            {isEditing && (
                                <div className="editable-content-buttons">
                                    <button className="content-btn" type="submit">
                                        {isLoading ? <Loader /> : <i className="fas fa-check"></i>}
                                    </button>
                                    <div
                                        className="content-btn"
                                        onClick={
                                            !isShowEditIcon
                                                ? this.handleCancelClick
                                                : () => {
                                                    this.handleCancelClick();
                                                    this.handleMouseLeave();
                                                }
                                        }
                                    >
                                        <i className="fas fa-times"></i>
                                    </div>
                                </div>
                            )}
                        </form>
                    )}
            </div>
        );
    }
}

export default withNamespaces()(EditableContent);
