import React from "react";
import { Table } from "reactstrap";

import TableHead from "./TableHead";
import { Loader } from "../../components/Loader";

const DataTable = (props) => {
    const {
        config,
        data,
        onRowClick,
        currentSortOrder,
        currentSortColumn,
        onSortClick,
        loading = false
    } = props;

    const isRowClickable = () => {
        return onRowClick && typeof onRowClick === "function";
    };

    const handleRowClick = (e, dataObj) => {
        if (isRowClickable()) {
            onRowClick(e, dataObj);
        }
    };

    return (
        <div className="table-responsive table-min-height table-container">
            <Table className="table-centered table-nowrap">
                <thead className="thead-light">
                    <tr>
                        {config.map((item, index) => {
                            return (
                                <TableHead
                                    key={index}
                                    options={item}
                                    currentSortOrder={currentSortOrder}
                                    currentSortColumn={currentSortColumn}
                                    onSortClick={onSortClick}
                                />
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        data.length > 0 &&
                        !loading &&
                        data.map((dataObj, index) => {
                            return (
                                <tr
                                    key={index}
                                    onClick={(e) => handleRowClick(e, dataObj.User)}
                                    className={`${isRowClickable() ? "cursor-pointer" : ""}`}
                                >
                                    {config.map((item, index) => {
                                        return <td key={index}>{item.render(dataObj, index)}</td>;
                                    })}
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
            {(!data || !data.length) && (
                <div className="table-loader-container">
                    {loading ? (
                        <Loader paddingTop={true} />
                    ) : (
                            <p className="text-muted text-center">No Data Available</p>
                        )}
                </div>
            )}
        </div>
    );
};

export default DataTable;
