import React from "react";

import "./RoundedInput.scss";

const RoundedInput = (props) => {
    const { placeholder = "", containerClass = "", inputClass = "" } = props;
    return (
        <div className={`chat-nav-search-input-wrapper ${containerClass}`}>
            <input
                className={`p-v-sm p-h-md bg-white w-100 h5 ${inputClass}`}
                type="search"
                placeholder={placeholder}
            />
        </div>
    );
};

export default RoundedInput;
