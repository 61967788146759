import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";

export const SUGGESTION_USER_UPDATE = "SUGGESTION_USER_UPDATE";

export const getUsersDetail = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        method: "GET",
        url: appendQueryParams("/user/users-details", { ...data }),
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const findAnotherMatch = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        body: JSON.stringify(data),
        method: "POST",
        url: "/match/find-another-match",
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const sendReferral = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        body: JSON.stringify(data),
        method: "POST",
        url: "/match/send-referral",
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
