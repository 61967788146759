import React from "react";
import { withNamespaces } from "react-i18next";

export default ({ component: Component, title = "HalalConnection" }) => {
    class Title extends React.Component {
        constructor(props) {
            super(props);
            document.title = this.props.t(title) + " - HalalConnection";
        }

        render() {
            return <Component {...this.props} />;
        }
    }
    return withNamespaces()(Title);
};
