import Config from "../../Config";
import {
    CHAT_LISTING_REQUEST,
    CHAT_LISTING_SUCCESS,
    CHAT_LISTING_FAIL,
    MESSAGE_LIST_REQUEST,
    MESSAGE_LIST_SUCCESS,
    MESSAGE_LIST_FAIL,
    CLEAR_CURRENT_CHAT,
    SET_CURRENT_CHAT,
    ADD_NEW_CHAT,
    ADD_NEW_MESSAGE,
    MOVE_CHAT_TOP,
    UPDATE_MESSAGE_ID,
    MESSAGE_UNREAD_COUNT
} from "../actions";

const initialState = {
    list: [],
    count: 0,
    fetching: false,
    dataAvailable: true,
    unReadSingleCount: 0,
    unReadGroupCount: 0,
    currentChat: {
        fetching: false,
        dataAvailable: true,
        id: null,
        customerId: null,
        messages: [],
        totalMessages: 0
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHAT_LISTING_REQUEST:
            return {
                ...state,
                fetching: true,
                list: (() => {
                    if (!action.Page || action.Page === 1) {
                        return [];
                    }
                    return state.list;
                })()
            };
        case CHAT_LISTING_SUCCESS:
            return {
                ...state,
                list: [...state.list, ...action.payload],
                count: action.count,
                dataAvailable: action.payload.length === Config.LIMIT,
                fetching: false
            };
        case CHAT_LISTING_FAIL:
            return {
                ...state,
                fetching: false
            };
        case MESSAGE_LIST_REQUEST:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    fetching: true,
                    messages: (() => {
                        if (!action.Page || action.Page === 1) {
                            return [];
                        }
                        return state.currentChat.messages;
                    })()
                }
            };
        case MESSAGE_LIST_SUCCESS:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    messages: [...action.payload, ...state.currentChat.messages],
                    totalMessages: action.totalMessages,
                    fetching: false,
                    dataAvailable: action.payload.length === Config.LIMIT
                }
            };
        case MESSAGE_LIST_FAIL:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    fetching: false
                }
            };
        case CLEAR_CURRENT_CHAT:
            return {
                ...state,
                currentChat: {
                    ...initialState.currentChat
                }
            };
        case SET_CURRENT_CHAT:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    id: action.chatId,
                    customerId: action.customerId
                }
            };
        case ADD_NEW_CHAT:
            return {
                ...state,
                list: action.payload
            };
        case ADD_NEW_MESSAGE:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    messages: [...state.currentChat.messages, action.payload]
                }
            };
        case MOVE_CHAT_TOP:
            return {
                ...state,
                list: action.newList
            };
        case UPDATE_MESSAGE_ID:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    messages: state.currentChat.messages.map((msg) => {
                        if (msg.Id === action.messageUUID) {
                            return {
                                ...msg,
                                Id: action.messageId
                            };
                        }
                        return msg;
                    })
                }
            };
        case MESSAGE_UNREAD_COUNT:
            return {
                ...state,
                unReadSingleCount: action.payload?.unReadSingleCount
                    ? action.payload?.unReadSingleCount
                    : 0,
                unReadGroupCount: action.payload?.unReadGroupCount
                    ? action.payload?.unReadGroupCount
                    : 0
            };
        default:
            return state;
    }
};
