import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import ChatHistoryMessage from "../../components/ChatHistoryMessage/ChatHistoryMessage";
import { Roles } from "../../constants";
import { getChatMessageSenderName } from "../../utils/commonUtils";
import { getChatMessageDate, getChatMessageTimeDifference } from "../../utils/dateTimeUtils.js";
import "../../components/ChatHistoryMessage/ChatHistoryMessage.scss";

const ChatMessageList = (props) => {
    const { messages, currentChatData } = props;

    const getSenderInfo = (id) => {
        return currentChatData.Users.filter(function (user) {
            return user.UserId === id;
        });
    };

    return messages.map((message, index) => {
        const sender = getSenderInfo(message.SenderId)[0];
        const isUserMessage = sender?.Type === Roles.USER;
        const showDate = getChatMessageTimeDifference(message.SendDate, index, messages);
        const days = getChatMessageDate(message, index, messages);
        const name = getChatMessageSenderName(message, index, messages);
        const attachments = message?.Attachments;

        return (
            <ChatHistoryMessage
                key={index}
                outerUsername={true}
                historyMessages={[
                    {
                        userImg: sender?.MediaPath,
                        date: showDate && message.SendDate,
                        days: days,
                        userName: !isUserMessage ? null : name,
                        message: message.Content,
                        receive: isUserMessage,
                        send: !isUserMessage,
                        type: message.Type,
                        sender,
                        attachments
                    }
                ]}
            />
        );
    });
};

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps)(ChatMessageList);
