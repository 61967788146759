import { ME_SUCCESS, SET_TOKEN, IS_USER_VERIFIED, SIGN_OUT, UPDATE_USER } from "../actions";
import { UserStatusCode } from "../../constants";

const initialState = {
    isAuthenticated: false,
    isVerified: false,
    user: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ME_SUCCESS:
            const isUserVerify = action.payload.User.Status === UserStatusCode.VERIFIED;
            return {
                ...state,
                isAuthenticated: true,
                isVerified: isUserVerify,
                isLoading: false,
                user: {
                    ...state.user,
                    ...action.payload.User
                }
            };
        case SET_TOKEN:
            return {
                ...state,
                user: {
                    ...state.user,
                    Token: action.token
                }
            };
        case IS_USER_VERIFIED:
            return {
                ...state,
                isVerified: true,
            };
        case SIGN_OUT:
            return {
                ...state,
                isAuthenticated: false,
                isVerified: false,
                user: {
                    ...state.user,
                    Token: null
                }
            };
        case UPDATE_USER:
            return {
                ...state,
                user: { ...state.user, ...action.payload?.User }
            };
        default:
            return state;
    }
};
