import React from "react";

import "./StatusIndicator.scss";
import "./StatusIndicator.scss";
const StatusIndicator = ({
    color = "success",
    customColor,
    badgeTitle = "",
    width = "0.9375rem",
    height = "0.9375rem",
    titleClass = "",
    containerClass = ""
}) => {
    return (
        <div className={`d-flex flex-row align-items-center ${containerClass}`}>
            <div
                className={` hc-status-indicator ${color}`}
                style={{
                    backgroundColor: customColor ? customColor : "",
                    width: width,
                    height: height
                }}
            ></div>
            <span className={`ml-4 p ${titleClass}`}>{badgeTitle}</span>
        </div>
    );
};

export default StatusIndicator;
