import React, { useState } from "react";
import { withNamespaces } from "react-i18next";

import ModalGroup from "./ModalAssets/Groups/Groups";
import CustomButton from "../../components/Button/Button";
import RadioButton from "../../components/RadioButton/RadioButton";

import "./BasicModal.scss";

const WarningModal = (props) => {
    const [data, setData] = useState(null);
    const [isRadioValid, setIsRadioValid] = useState(true);
    const radiosButtonsArray = props.radioOption;
    const onChangeRemarks = (event) => {
        setData({
            ...data,
            optionValue: event.currentTarget.value
        });
        setIsRadioValid(true);
    };
    const onSubmit = () => {
        if (data == null || !data.optionValue) {
            setIsRadioValid(false);
        } else {
            props.onClick(data);
        }
    };
    return (
        <span>
            <ModalGroup title={props.t("Please select between remarks")}>
                <div className="radio-button-container">
                    <div className="d-flex flex-row justify-content-between radio-button-row flex-wrap">
                        {radiosButtonsArray.map((singleRadio, key) => {
                            return (
                                <div className="radio-items w-50" key={key}>
                                    <RadioButton
                                        radioName={singleRadio.radioName}
                                        label={singleRadio.label}
                                        value={singleRadio.value}
                                        difference={key}
                                        onChange={onChangeRemarks}
                                    />
                                </div>
                            );
                        })}
                        <div className="h6 color-secondary m-t-lg m-b-sm">
                            {props.t("Warning Remarks Message")}
                        </div>
                    </div>
                    {!isRadioValid && (
                        <div
                            className={
                                !isRadioValid &&
                                "custom-inp-error-text justify-content-between radio-button-row radio-button-row-reduce-padding"
                            }
                        >
                            {props.t("Please select remarks")}
                        </div>
                    )}
                </div>
            </ModalGroup>
            <ModalGroup title={props.t("Please type your remarks below:")}>
                <div className="radio-button-container text-center">
                    <div className="d-flex flex-column justify-content-between radio-button-row flex-wrap radio-button-row-reduce-padding">
                        <div className="form-group">
                            <textarea
                                className="form-control"
                                rows="3"
                                maxlength="1000"
                                onChange={(event) => {
                                    if (event.currentTarget.value) {
                                        setData({ ...data, comments: event.currentTarget.value });
                                    }
                                }}
                            ></textarea>
                        </div>
                        <CustomButton
                            className="btn btn-xs button-type-3 reduce-radius "
                            onClick={onSubmit}
                        >
                            {props.t("Send")}
                        </CustomButton>
                    </div>
                </div>
            </ModalGroup>
        </span>
    );
};

export default withNamespaces()(WarningModal);
