export default {
    age: "Age",
    children: "Children",
    maritalStatus: "Marital Status",
    currentLocation: "Current Location",
    sect: "Sect",
    prayerRoutine: "Prayer Routine",
    halalPreferences: "Halal Preferences",
    smokingHabits: "Smoking Habits",
    alcoholConsumption: "Alcohol Consumption",
    levelOfEducation: "Level Of Education",
    workingPreference: "Working Preference",
    kind: "Kind",
    funny: "Funny",
    brave: "Brave",
    optimistic: "Optimistic",
    witty: "Witty"
};
