import React from 'react';
import '../../BasicModal.scss';

const Groups = ({children,title,subTitle,titleClass,titleClassDiv}) => {
    return(
        <div className="modal-group-container">
            <div className="group-header-container">
                {
                    title && (
                    <div className={`modal-group-title text-center ${titleClassDiv}`}>
                        <h2 className={titleClass}>{title}</h2>
                    </div>
                    )
                }
                {
                    subTitle && (
                    <div className="modal-group-subtitle text-center">
                        <p className="body-text-9">{subTitle}</p>
                    </div>
                    )
                }
            </div>
            <div className="group-body-container">
                {children}
            </div>

        </div>
    )
};

export default Groups;