import React from "react";
import "./Pill.scss";

const Pill = ({ color = "", classes = "", children, onClick }) => {
    return (
        <div
            style={{ color: color ? color : "" }}
            className={`hc-pill-wrapper body-text-6 d-inline-flex mb-2 mr-2 ${classes}`}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

export default Pill;
