import React, { useState } from "react";
import { Button, Spinner } from "reactstrap";
import CustomCard from "../../components/Card/CustomCard";
import { Loader } from "../Loader";

import "./Button.scss";

const BlockButton = ({
    className,
    isLoading = false,
    color = "primary",
    loaderSize = "sm",
    children,
    onClick,
    disabled,
    type = "button",
    btn_class = "",
    btnIcon,
    btnImg,
    btnMenus
}) => {
    const [menu, setMenu] = useState(false);
    const menuHandle = () => {
        setMenu(!menu);
    };
    return (
        <div className="main-btn position-relative">
            <Button
                className={className}
                color={color}
                onClick={btnMenus?.length > 0 ? menuHandle : onClick}
                disabled={disabled || isLoading}
                type={type}
            >
                <span className={btn_class}>
                    {btnImg && (
                        <span className="d-inline-block">
                            <img src={btnImg} className="btn-img" />
                        </span>
                    )}
                    {isLoading && <Loader size={loaderSize} classes="loader-center" />}
                    <span className={`${isLoading ? "invisible" : ""}`}>{children}</span>
                    {btnIcon && <span className="d-inline-block btn-icon">{btnIcon}</span>}
                </span>
            </Button>
            {btnMenus && menu && (
                <div className="button-menus position-absolute">
                    <CustomCard>
                        {btnMenus.map((singleButton) => {
                            return (
                                <div
                                    className="d-flex flex-row justify-content-between"
                                    onClick={() => {
                                        setMenu(!menu);
                                        singleButton.clickFunc();
                                    }}
                                >
                                    <div className="flex-items-menu p">{singleButton.name}</div>
                                    <div className="flex-items-menu p">
                                        <i className="dripicons-chevron-right back-icon"></i>
                                    </div>
                                </div>
                            );
                        })}
                    </CustomCard>
                </div>
            )}
        </div>
    );
};

export default BlockButton;
