import React, { useState } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const toastrOptions = {
    closeButton: true,
    preventDuplicates: false
};
const notificationToastr = { info, warning, error, success };

function info(message, title = null) {
    toastr.info(message, title, toastrOptions);
}
function warning(message, title = null) {
    toastr.warning(message, title, toastrOptions);
}

function error(message, title = null) {
    toastr.error(message, title, toastrOptions);
}
function success(message, title = null) {
    toastr.success(message, title, toastrOptions);
}

export default notificationToastr;
