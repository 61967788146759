import React from "react";
import DataToggle from "../../../components/Toggle/DataToggle";
import "../Settings.scss";

const ToggleList = (props) => {
    return (
        <>
            {props.title ? (
                <>
                    <ul className="ns-toggle-wrapper list-style-none">
                        <li className="d-flex flex-row align-items-center justify-content-between mt-4">
                            <h3>{props.title}</h3>
                        </li>
                        <li className="d-flex flex-row align-items-center justify-content-between">
                            <ul className="list-style-none w-100">
                                {props.data?.map((item, id) => {
                                    return (
                                        <DataToggle
                                            onClick={(e) => {
                                                props.onClick({ ...item, Value: !item.Value });
                                            }}
                                            id={id}
                                            value={item.Value}
                                            settingId={item.SettingId}
                                            name={item.Name}
                                        />
                                    );
                                })}
                            </ul>
                        </li>
                    </ul>
                </>
            ) : (
                <>
                    <ul className="ns-toggle-wrapper list-style-none">
                        {props.data?.map((item, id) => {
                            return (
                                <DataToggle
                                    onClick={(e) => {
                                        props.onClick({ ...item, Value: !item.Value });
                                    }}
                                    id={id}
                                    value={item.Value}
                                    settingId={item.SettingId}
                                    name={item.Name}
                                />
                            );
                        })}
                    </ul>
                </>
            )}
        </>
    );
};

export default ToggleList;
