import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, UncontrolledTooltip, Button } from "reactstrap";

const ChatMessageInput = (props) => {
    const [messageContent, setMessageContent] = useState("");
    const sendIcon = require("../../assets/images/send-message.svg");
    const handleSubmit = (e) => {
        e.preventDefault();
        if (messageContent && messageContent.trim().length !== 0) {
            props.onMessageSent(messageContent);
            setMessageContent("");
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <div className="chat-field-wrapper d-flex align-items-center justify-content-between p-h-lg p-v-sm bg-white">
                <div className="chat-field-left  d-flex align-items-center w-100 p-r-lg">
                    <div className={`chat-nav-search-input-wrapper w-100`}>
                        <textarea
                            rows="1"
                            className={`p-v-sm p-h-md bg-white w-100 h5 m-0 chat-textbox`}
                            type="text"
                            value={messageContent}
                            onKeyDown={(e) => {
                                if (e.which == 13 && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSubmit(e);
                                } else {
                                }
                            }}
                            onChange={(e) => {
                                const regex = new RegExp("^(.|\n){0,1000}$");
                                if (!regex.test(e.target.value)) {
                                    return;
                                }
                                if (e.target.value === " " || e.target.value === "\n") {
                                    return;
                                }
                                setMessageContent(e.target.value);
                            }}
                            placeholder="Enter Message..."
                        />
                    </div>
                </div>
                <Button
                    type="submit"
                    color="primary"
                    className="p-0 bg-transparent border-0"
                    disabled={!messageContent}
                >
                    <span className="send-icon d-inline-flex align-items-center justify-content-center">
                        <img className="m-l-xs" src={sendIcon} alt="send-icon" />
                    </span>
                </Button>
            </div>
        </form>
    );
};

export default ChatMessageInput;
