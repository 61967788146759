import React from "react";
import { withNamespaces } from "react-i18next";
import { Row, Col, Card, CardBody } from "reactstrap";
import { DataTable } from "../../components/DataTable";
import { Avatar } from "../../components/Avatar";

import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import "./AutoMatchListing.scss";
import BlockButton from "../../components/Button/Button";

const AUTO_MATCH_COLUMNS = {
    AVATAR: "",
    NAME: "Name",
    MATCH: "%Match",
    VIEW_PROFILE: ""
};
const matchListingData = [
    {
        backButton: true,
        Name: "Ayesha",
        match: "97%",
        viewProfile: true
    },
    {
        backButton: true,
        Name: "Ayesha",
        match: "97%",
        viewProfile: true
    },
    {
        backButton: true,
        Name: "Ayesha",
        match: "97%",
        viewProfile: true
    }
];
const AutoMatchListing = (props) => {
    const avatar = require("../../assets/images/realuser_lg.png");
    const tableBackIcon = require("../../assets/images/table-back.svg");

    const renderBackIcon = () => {
        return (
            <th className="cursor-pointer p-0">
                <img src={tableBackIcon} alt="table-back" />
            </th>
        );
    };
    const renderAutoMatch = () => {
        return <div className="h1 color-secondary-pink">97% Match</div>;
        //TODO: GRAY-COLOR return <div className="h1 color-secondary-purple opacity-6">43% Match</div>;
    };

    const renderName = () => {
        return (
            <div className="am-listing-table-name-wrapper">
                <div className="am-listing-table-name h3 color-typrography-3 text-truncate">
                    {props.t("Ayesha Sheikh")}
                </div>
                <div className="am-listing-table-origin h4 color-typrography-3 m-t-sm text-truncate">
                    {props.t(" Scottish Pakistani")}
                </div>
            </div>
        );
    };

    return (
        <>
            <LayoutWrapper breadCrumb={false}>
                <div className="hc-listing-table am-listing-table table-responsive">
                    <Row className="p-0 m-0">
                        <Col xs="12" className="p-0">
                            <Card>
                                <CardBody className="p-0">
                                    <DataTable
                                        data={matchListingData}
                                        loading={false}
                                        config={[
                                            {
                                                title: renderBackIcon(),
                                                render: (data, index) => (
                                                    <Avatar
                                                        className="am-listing-avatar"
                                                        width="100"
                                                        height="100"
                                                        src={avatar}
                                                        name="avatar"
                                                    />
                                                )
                                            },
                                            {
                                                title: AUTO_MATCH_COLUMNS.NAME,
                                                render: (data) => renderName()
                                            },
                                            {
                                                title: AUTO_MATCH_COLUMNS.MATCH,
                                                render: (data) => renderAutoMatch()
                                            },
                                            {
                                                render: (data, index) => (
                                                    <BlockButton className="am-view-profile-btn h5 color-white p-v-xs p-h-lg btn button-type-3">
                                                        {props.t("View Profile")}
                                                    </BlockButton>
                                                )
                                            }
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </LayoutWrapper>
        </>
    );
};

export default withNamespaces()(AutoMatchListing);
