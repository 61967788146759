import { USER_LISTING_REQUEST, USER_LISTING_SUCCESS, USER_LISTING_FAIL } from "../actions";

const initialState = {
    fetching: false,
    count: 0,
    list: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_LISTING_REQUEST:
            return {
                ...state,
                fetching: true,
                list: []
            };
        case USER_LISTING_SUCCESS:
            return {
                ...state,
                fetching: false,
                count: action.count,
                list: action.payload
            };
        case USER_LISTING_FAIL:
            return {
                ...state,
                fetching: false
            };
        default:
            return state;
    }
};
