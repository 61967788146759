import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import "./Settings.scss";
class Settings extends Component {
    render() {
        return (
            <LayoutWrapper isBackIcon={false}>
                <div className="hc-settings-list-container">
                    <ul className="hc-settings-list">
                        <li>
                            <Link
                                className="h1 color-typrography-3"
                                to={APP_ROUTES.ACCOUNT_SETTINGS}
                            >
                                {this.props.t("Account Settings")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="h1 color-typrography-3"
                                to={APP_ROUTES.NOTIFICATION_SETTINGS}
                            >
                                {this.props.t("Notification Settings")}
                            </Link>
                        </li>
                        <li>
                            <Link className="h1 color-typrography-3" to={APP_ROUTES.HELPFAQS}>
                                {this.props.t("Help/FAQs")}
                            </Link>
                        </li>
                        <li>
                            <Link className="h1 color-typrography-3" to={APP_ROUTES.ABOUT_US}>
                                {this.props.t("About Us")}
                            </Link>
                        </li>
                    </ul>
                </div>
            </LayoutWrapper>
        );
    }
}

export default withNamespaces()(Settings);
