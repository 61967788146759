const modalConstant = {
    UserSelectionModal: "modal-type-1",
    WarningModal: "modal-type-2",
    RemovalModal: "modal-type-3",
    SuspendModal: "modal-type-4",
    SuspendConfirmationModal: "modal-type-5",
    LogoutModal: "modal-type-6",
    MessageModal: "modal-type-7"
};

export default modalConstant;
