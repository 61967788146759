import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Modal } from "react-responsive-modal";
import { Spinner } from "reactstrap";

import { SRLWrapper } from "simple-react-lightbox";

import "./Verification.scss";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import "../../../node_modules/react-responsive-modal/styles.css";

import { PageTitle } from "../../components/PageTitle";
import VerificationItem from "./VerificationItem";
import { ThemeButton } from "../../components/ThemeButton";
import {
    getPendingUsersListing,
    updateVerificationStatus,
    addNewUserVerification
} from "../../store/actions";
import { getMediaThumbPath } from "../../utils/mediaService";
import { UserVerificationStatus, MediaTypes } from "../../constants";
import notificationToastr from "../../utils/toastUtil";

import { Socket, SocketTypes } from "../../utils/socketUtils";

const Verification = (props) => {
    const {
        getPendingUsersListing,
        fetching,
        data,
        updateVerificationStatus,
        totalUsers,
        addNewUserVerification
    } = props;

    const [page, setPage] = useState(1);
    const [verifyModal, setVerifyModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [selectedUser, setSelectdUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const scrollRef = useRef(null);

    useEffect(() => {
        getPendingUsersListing({ Page: page }).then((res) => {
            handleLoadMore(res.Data.Users);
        });

        //es-lint-disable-next-line react-hooks/exhaustive-deps
    }, [page, getPendingUsersListing]);

    useEffect(() => {
        function handleNewUserJoined(data) {
            addNewUserVerification(data.User);
        }
        Socket.onNewUserJoined(handleNewUserJoined);

        return () => {
            Socket.remove(SocketTypes.NEW_USER_ID_VERIFICATION, handleNewUserJoined);
        };
    }, [addNewUserVerification]);

    useEffect(() => {
        bindWindowResizeListner();

        return () => {
            unbindWindowResizeListner();
        };
    }, [data]);

    const confirmModalOpen = () => {
        setConfirmModal(true);
    };
    const confirmModalClose = () => {
        setConfirmModal(false);
    };
    const verificationModalClose = () => setVerifyModal(false);

    const lightBoxOptions = {
        progressBar: {
            backgroundColor: "#f2f2f2",
            fillColor: "#000000",
            height: "3px",
            showProgressBar: true
        },
        buttons: {
            showDownloadButton: false
        }
    };

    const handleUserSelect = (data) => {
        setSelectdUser(data);
        setVerifyModal(true);
    };

    const handleUserValidate = (status) => {
        setLoading(true);
        let payload = {
            UserId: selectedUser?.Id,
            Status: status
        };

        updateVerificationStatus(payload)
            .then((res) => {
                notificationToastr.success(res.Message);
                setVerifyModal(false);
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
            })
            .finally(() => {
                setConfirmModal(false);
                setLoading(false);
            });
    };

    const handleLoadMore = (newData = []) => {
        let container = scrollRef.current;

        if (container) {
            const isScrollable = container.scrollHeight - 127 > container.clientHeight;

            if (!isScrollable) {
                if ([...data, ...newData].length !== totalUsers) {
                    setPage(page + 1);
                }
            }
        }
    };

    const onScrollToBottom = (event) => {
        const bottom =
            Math.floor(event.target.scrollHeight - event.target.scrollTop) ===
            event.target.clientHeight;

        if (bottom && data.length !== totalUsers) {
            setPage(page + 1);
        }
    };

    const handleWindowResize = () => {
        handleLoadMore();
    };

    const bindWindowResizeListner = () => {
        window.addEventListener("resize", handleWindowResize);
    };

    const unbindWindowResizeListner = () => {
        window.removeEventListener("resize", handleWindowResize);
    };

    const getVideoUrl = (array) => {
        if (array && Boolean(array.length)) {
            let videoUrl = array.find((item) => item.Type === MediaTypes.VIDEO);

            if (videoUrl) {
                return { thumb: getMediaThumbPath(videoUrl), url: videoUrl.Path };
            }
        }
        return null;
    };

    return (
        <>
            <div ref={scrollRef} className="scroll-view" onScroll={onScrollToBottom}>
                <PageTitle title="Verification" />

                <div className="row d-flex justify-content-center mx-auto">
                    {Boolean(data.length) ? (
                        <>
                            {data.map((item, index) => {
                                return (
                                    <VerificationItem
                                        onClick={handleUserSelect}
                                        key={index}
                                        data={item}
                                    />
                                );
                            })}
                        </>
                    ) : (
                        !fetching &&
                        !data.length && (
                            <div className="row d-flex justify-content-around m-auto w-80">
                                <h4>Currently No Pending Users</h4>
                            </div>
                        )
                    )}
                </div>
                <Modal
                    classNames={{ modal: "verificationModal" }}
                    open={verifyModal}
                    onClose={verificationModalClose}
                    showCloseIcon={true}
                    center
                >
                    <div className="d-flex align-items-center justify-content-between verifiy__modal ">
                        <div className="verifiy__modal-info text-center">
                            <img src={getMediaThumbPath(selectedUser?.Media)} alt="profile" />
                            <h3 className="pt-4 pb-1">{selectedUser?.FullName}</h3>
                            <div className="d-flex justify-content-center">
                                <p>{selectedUser?.UserProfile?.Age} &nbsp;</p>
                                <p>{selectedUser?.UserProfile?.Country?.Nationality}</p>
                            </div>
                            <p className="pt-2">{selectedUser?.UserProfile?.Country?.Name} </p>
                        </div>

                        <img
                            onClick={() => setOpen(true)}
                            src={getVideoUrl(selectedUser?.IdVerificationMedia)?.thumb}
                            alt="video-thumnail"
                            style={{ height: "200px" }}
                        />

                        <div className="verifiy__modal-images fancy-scroll">
                            <SRLWrapper options={lightBoxOptions}>
                                {Boolean(selectedUser?.IdVerificationMedia) &&
                                    selectedUser.IdVerificationMedia.map((item, index) => {
                                        return (
                                            item.Type === MediaTypes.ID && (
                                                <img src={item?.Path} key={index} />
                                            )
                                        );
                                    })}
                            </SRLWrapper>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <ThemeButton
                            title="Accept"
                            isLoading={loading}
                            disabled={loading}
                            onClick={() => handleUserValidate(UserVerificationStatus.VERIFIED)}
                            class="btn-2 btn-primary-2"
                        />
                        <ThemeButton
                            onClick={confirmModalOpen}
                            class="ml-3"
                            disabled={loading}
                            title="Decline"
                            class="btn-2 btn-primary-2 ml-3"
                        />
                    </div>
                </Modal>
                <Modal open={isOpen} onClose={() => setOpen(false)} showCloseIcon={true} center>
                    <video
                        id="steam_video"
                        width="660"
                        height="440"
                        playsInline
                        controls="controls"
                        autoPlay
                    >
                        <source
                            src={getVideoUrl(selectedUser?.IdVerificationMedia)?.url}
                            type="video/mp4"
                        />
                    </video>
                </Modal>

                {fetching && (
                    <div className="d-flex flex-row justify-content-center">
                        <p className="mr-2">Loading</p>
                        <Spinner size={"40"} color={"primary"} />
                    </div>
                )}
            </div>
            <Modal
                classNames={{ modal: "ConfirmModal" }}
                open={confirmModal}
                onClose={confirmModalClose}
                showCloseIcon={false}
                center
            >
                <div className="confirm-modal d-flex flex-column align-items-center justify-content-center text-center ">
                    <p>Are you sure?</p>
                    <p>
                        By declining this, {selectedUser?.FullName} will have to do the ID
                        verification process again
                    </p>
                    <div className="d-flex justify-content-center mt-3">
                        <ThemeButton
                            class="confrim-btn"
                            isLoading={loading}
                            disabled={loading}
                            onClick={() => handleUserValidate(UserVerificationStatus.DECLINED)}
                            title="Yes"
                        />
                        <ThemeButton
                            onClick={confirmModalClose}
                            disabled={loading}
                            class="ml-3 confrim-btn"
                            title="No"
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

const mapDispatchToProps = {
    getPendingUsersListing,
    updateVerificationStatus,
    addNewUserVerification
};

const mapStateToProps = (state) => {
    const { verification } = state;

    return {
        data: verification.list,
        fetching: verification.loading,
        totalUsers: verification.totalUsers
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
