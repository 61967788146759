import { combineReducers } from "redux";

import Layout from "../layout/reducer";
import authReducer from "./authReducer";
import root from "./rootReducer";
import entityReducer from "./entityReducer";
import userReducer from "./userReducer";
import reportReducer from "./reportReducer";
import chatReducer from "./chatReducer";
import matchSuggestionReducer from "./matchSuggestionReducer";
import notificationReducer from "./notificationReducer";
import compareProfileReducer from "./compareProfileReducer";
import verificationReducer from "./verificationReducer";

import { SIGN_OUT } from "../actions";

const appReducer = combineReducers({
    Layout,
    root,
    auth: authReducer,
    entities: entityReducer,
    user: userReducer,
    report: reportReducer,
    chat: chatReducer,
    matchSuggestion: matchSuggestionReducer,
    notification: notificationReducer,
    compareProfile: compareProfileReducer,
    verification: verificationReducer
});

const rootReducer = (state, action) => {
    if (action.type === SIGN_OUT) {
        const { Layout } = state;
        state = { Layout };
    }

    return appReducer(state, action);
};

export default rootReducer;
