import React from 'react';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import './LayoutWrapper.scss';
const LayoutWrapper = ({ children, className = '', breadCrumb = true, isBackIcon = true, title, chatLayout = false }) => {
    return (
        <>
            { breadCrumb && <BreadCrumb isBackIcon={isBackIcon} title={title} />}
            <div className={`page-content ${className} ${!breadCrumb && 'page-content-2'} ${chatLayout && 'page-content-3'}`}>
                {children}
            </div>
        </>
    )
};

export default LayoutWrapper;