import React from "react";
import "./CompareProfile.scss";
import Pill from "../../components/Pill/Pill";

const AccordionInnerListing = ({ heading, contents, pills, titleClasses = "mb-2" }) => {
    return (
        <>
            <div className="hc-user-detail-category-head body-text-7 mb-2">{heading}</div>
            {contents &&
                contents.map((singleContent, key) => {
                    return (
                        <div className={"hc-user-detail-info " + titleClasses} key={key}>
                            <span className="hc-user-detail-pill-head body-text-8">
                                {singleContent.title} :
                            </span>
                            <span className="hc-user-detail-info-desc body-text-6">
                                {singleContent.content}
                            </span>
                        </div>
                    );
                })}
            {pills &&
                pills.map((singlePill, key) => {
                    return <Pill key={key}>{singlePill.name}</Pill>;
                })}
        </>
    );
};

export default AccordionInnerListing;
