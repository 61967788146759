export default {
    DASHBOARD: "Dashboard",
    USER_LISTING: "User Listing",
    REPORT_LISTING: "Report Listing",
    REPORT_DETAIL: "Report Detail",
    HELPFAQS: "Help & FAQs",
    USER_DETAIL: "User Detail",
    COMPARE_PROFILE: "Compare Profile",
    ABOUT_US: "About Us",
    SETTINGS: "Settings",
    ACCOUUNT_SETTINGS: "Account Settings",
    NOTIFICATION_SETTINGS: "Notification Settings",
    ACTIVE_CHATS: "Active Chats",
    PREVIOUS_CHATS: "Previous Chats",
    CHAT: "Chat",
    PREVIOUS_CHAT: "Previous Chat",
    SEE_CONNECTIONS: "See Connections",
    AUTO_MATCH_LISTING: "Auto Match Listing",
    MATCH_LISTING: "Match Listing",
    ID_VERIFICATION: "Id Verification"
};
