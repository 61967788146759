export const normalizeChatData = (chats) => {
    let chatData = {},
        vendorList = [];

    for (let chat of chats) {
        chatData[chat.ChatId] = { ...chat };
        if (chat.Vendor) {
            vendorList.push({ ...chat.Vendor });
            chatData[chat.ChatId].VendorId = chat.Vendor.Id;
            delete chatData[chat.ChatId].Vendor;
        }
    }

    return { chatData, vendorList };
};
