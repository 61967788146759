import firebase from "firebase/app";
import "firebase/messaging";
import Config from "../Config";
import { PostMessageTypes } from "../constants";

const FIREBASE_CONFIG = {
    apiKey: Config.env().FIREBASE_API_KEY,
    authDomain: Config.env().FIREBASE_AUTH_DOMAIN,
    projectId: Config.env().FIREBASE_PROJECT_ID,
    messagingSenderId: Config.env().FIREBASE_SENDER_ID,
    appId: Config.env().FIREBASE_APP_ID
};

export class Notificaion {
    messaging;
    notificationListener;
    constructor() {
        this.init();
    }

    init() {
        if (!firebase.apps.length) {
            firebase.initializeApp(FIREBASE_CONFIG);
        }
        this.messaging = firebase.messaging();

        this.notificationListener = this.messaging.onMessage(this.handleMessageRecieved.bind(this));

        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.addEventListener(
                "message",
                this.listenMessageFromServiceWorker
            );
        }
    }

    removeListeners() {
        if (this.notificationListener) {
            // removes this onMessage firebase listner

            this.notificationListener();
        }
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.removeEventListener(
                "message",
                this.listenMessageFromServiceWorker
            );
        }
    }

    // listens in app notifications
    handleMessageRecieved(payload) {
        const { data } = payload;

        if (!data) {
            return;
        }

        this.sendMessageToServiceWorker({
            Type: PostMessageTypes.SHOW_NOTIFICATION,
            Payload: payload
        });
    }

    listenMessageFromServiceWorker = (event) => {
        const { Type, Payload } = event.data;

        this.showNotification(event.data);
        switch (Type) {
            case PostMessageTypes.SHOW_NOTIFICATION:
                break;
            default:
                break;
        }
    };

    showNotification(data) {
        if (
            "serviceWorker" in navigator &&
            window.Notification &&
            window.Notification.permission === "granted" &&
            data &&
            data.notification
        ) {
            navigator.serviceWorker.ready
                .then((reg) => {
                    reg.showNotification(data.notification.title, {
                        icon:
                            "https://s3-eu-west-1.amazonaws.com/static.development.halal-connections/Icon_HC_PB_CMI.png",
                        body: data.notification.body,
                        data: { FCM_MSG: data }
                    });
                })
                .catch((err) => {});
        }
    }

    sendMessageToServiceWorker = ({ Type, Payload }) => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.ready.then((reg) => {
                reg.active.postMessage({ Type, Payload });
            });
        }
    };

    async requestPermission() {
        return await this.messaging.requestPermission();
    }

    async getToken() {
        try {
            await this.requestPermission();
            return await this.messaging.getToken();
        } catch (err) {
            throw err;
        }
    }
}
