import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Notificaion } from "../../utils/notificationUtils";
import routes from "../../routes";
import { updateFCMToken } from "../../store/actions/authActions";
import { Loader } from "../../components/Loader";
import { ErrorPage } from "../../components/Utility";
import { getMe } from "../../store/actions";
import { Socket } from "../../utils/socketUtils";

const DefaultLayout = (props) => {
    const {
        Layout,
        isAuthenticated,
        tokenVerified,
        isLoading,
        getMe,
        isVerified,
        updateFCMToken
    } = props;

    useEffect(() => {
        let notification;
        if (isAuthenticated) {
            Socket.init();
            notification = new Notificaion();
            notification
                .getToken()
                .then((token) => {
                    updateFCMToken({
                        FCMToken: token
                    })
                        .then((res) => {})
                        .catch((err) => {});
                })
                .catch((err) => {});
            if (!tokenVerified) {
                getMe();
            }
            return () => {
                if (notification) {
                    notification.removeListeners();
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isAuthenticated || !isVerified) {
        return <Redirect to="/login" />;
    }

    return tokenVerified && isVerified ? (
        <Switch>
            {routes.map((route, index) => {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => (
                            <Layout>
                                <route.component {...props} />
                            </Layout>
                        )}
                    />
                );
            })}
            <Redirect to="/dashboard" />
        </Switch>
    ) : !tokenVerified && isLoading ? (
        <Loader classes="vh-100" showVerifyText={true} />
    ) : (
        <ErrorPage />
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isLoading: state.root.isLoading,
        tokenVerified: state.root.tokenVerified,
        isVerified: state.auth.isVerified
    };
};

const mapDispatchToProps = {
    getMe,
    updateFCMToken
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
