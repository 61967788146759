export default {
    BreakingPrivacyPolicy: 1,
    Bullying: 2,
    Discrimination: 3,
    Harassment: 4,
    ImpersonatingToBeSomeone: 5,
    InAppropriateLanguage: 6,
    NoPolygamy: 7,
    SexualContent: 8,
    Violence: 9
};
