import React from "react";
import { withNamespaces } from "react-i18next";
import { RoundedInput } from "../../components/RoundedInput";
import "./Chats.scss";

const ChatMessageField = (props) => {
    const { message = "" } = props;
    const sendIcon = require("../../assets/images/send-message.svg");
    return (
        <div className="chat-field-wrapper d-flex align-items-center justify-content-between p-h-lg p-v-sm bg-white">
            <div className="chat-field-left  d-flex align-items-center w-100 p-r-lg">
                <RoundedInput containerClass="w-100" inputClass="mb-0" />
            </div>
            <span className="send-icon d-inline-flex align-items-center justify-content-center">
                <img src={sendIcon} alt="send-icon" />
            </span>
        </div>
    );
};

export default withNamespaces()(ChatMessageField);
