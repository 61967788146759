import React, { useState, useEffect, useRef, Component } from "react";
import { withNamespaces } from "react-i18next";
import { DataTable } from "../../components/DataTable";
import { Pagination } from "../../components/Pagination";
import { useHistory } from "react-router-dom";
import "./MatchSuggestionListing.scss";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
    getMatchListing,
    resetMatchListing,
    getFilteredMatchListing,
    SUGGESTION_USER_UPDATE
} from "../../store/actions";
import { Avatar } from "../../components/Avatar";
import { SortOrder } from "../../constants";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useParams } from "react-router-dom";
import BlockButton from "../../components/Button/Button";
import tableBackIcon from "../../assets/images/table-back.svg";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { camalToPascalCase } from "../../utils/commonUtils";
import Config from "../../Config";

const MATCH_SUGGESTION_COLUMNS = {
    NAME: "Name",
    MATCH: "% Match",
    BUTTON: "Button"
};

const SORTABLE_COLUMNS = {
    [MATCH_SUGGESTION_COLUMNS.NAME]: "Name",
    [MATCH_SUGGESTION_COLUMNS.MATCH]: "MatchPercentage"
};

const MatchSuggestionListing = (props) => {
    const { fetching, data, count } = props;
    const [page, setPage] = useState(1);
    const [currentSortOrder, setCurrentSortOrder] = useState(SortOrder.DESC);
    const [currentSortColumn, setCurrentSortColumn] = useState("MatchPercentage");
    const history = useHistory();
    const { id } = useParams();
    const matchSuggestionListingAbortController = useRef(null);
    const dispatch = useDispatch();
    const isManual = props.location?.state?.isManual;

    useEffect(() => {
        matchSuggestionListingAbortController.current = new AbortController();
        dispatch(
            !isManual || !isManual?.count > 0
                ? getMatchListing(
                      {
                          UserId: id,
                          Page: page,
                          ...(currentSortColumn &&
                              currentSortOrder && {
                                  Column: currentSortColumn,
                                  Direction: currentSortOrder
                              })
                      },
                      matchSuggestionListingAbortController.current.signal
                  )
                : getFilteredMatchListing(getFiltersPayload())
        );
        return () => {
            dispatch(resetMatchListing());
            if (matchSuggestionListingAbortController.current) {
                matchSuggestionListingAbortController.current.abort();
            }
        };
    }, [page, currentSortOrder, currentSortColumn, dispatch]);

    const getFiltersPayload = () => {
        return {
            UserId: id,
            Personal: getPersonalFilters(),
            Professional: getProfessionalFilter(),
            Religion: getReligiousFilter(),
            Traits: getTraitsFilter(),
            Pagination: { Page: page, Limit: Config.LIMIT },
            Sort: currentSortOrder && {
                Column: currentSortColumn,
                Direction: currentSortOrder
            }
        };
    };

    const getTraitsFilter = () => {
        let details = isManual?.traits;

        return Object.keys(details).filter((item) => details[item] === true);
    };
    const getProfessionalFilter = () => {
        let details = isManual?.professional;
        return {
            LevelOfEducation: details?.levelOfEducation,
            WorkingPreference: details?.workingPreference
        };
    };
    const getPersonalFilters = () => {
        let details = isManual?.personal;
        return {
            Age: details?.age,
            Children: details?.children,
            MaritalStatus: details?.maritalStatus,
            CurrentLocation: details?.currentLocation
        };
    };
    const getReligiousFilter = () => {
        let details = isManual?.religion;

        return {
            Sect: details?.sect,
            PrayerRoutine: details?.prayerRoutine,
            HalalPreferences: details?.halalPreferences,
            SmokingHabits: details?.smokingHabits,
            AlcoholConsumption: details?.alcoholConsumption
        };
    };
    const renderBackIcon = () => {
        return (
            <th className="cursor-pointer p-0">
                <img
                    src={tableBackIcon}
                    alt="table-back"
                    onClick={() => history.push(getRoute(APP_ROUTES.USER_DETAIL, { id: id }))}
                />
            </th>
        );
    };

    const handleSortClick = (data) => {
        let sortOrder;
        if (!currentSortOrder || currentSortOrder === SortOrder.DESC) {
            sortOrder = SortOrder.ASC;
        } else {
            sortOrder = SortOrder.DESC;
        }

        setCurrentSortColumn(SORTABLE_COLUMNS[data.title]);
        setCurrentSortOrder(sortOrder);
    };

    const handlePageClick = (pageNo) => {
        setPage(pageNo);
    };
    const handleRowClick = (clickedId) => {
        let details = { userId: id, users: [] };
        let up, down;

        if (data.length < 3) {
            details = {
                ...details,
                users: data.map((item) => {
                    return { UserId: item.UserId, MatchPercentage: item.MatchPercentage };
                })
            };
        } else {
            up = data.findIndex((item) => item.UserId === clickedId) - 1;
            down = data.findIndex((item) => item.UserId === clickedId) + 1;
            if (down >= data.length) down -= 3;
            if (up < 0) up += 3;
            let calculatedDetails = [];
            let findDetail = data.find((item) => item.UserId === clickedId);
            calculatedDetails.push({
                UserId: findDetail.UserId,
                MatchPercentage: findDetail.MatchPercentage
            });
            findDetail = data[up];
            calculatedDetails.push({
                UserId: findDetail.UserId,
                MatchPercentage: findDetail.MatchPercentage
            });
            findDetail = data[down];
            calculatedDetails.push({
                UserId: findDetail.UserId,
                MatchPercentage: findDetail.MatchPercentage
            });
            details = { ...details, users: calculatedDetails };
        }

        dispatch({
            type: SUGGESTION_USER_UPDATE,
            users: details.users
        });

        history.push(getRoute(APP_ROUTES.COMPARE_PROFILE), {
            ...details,
            filters: isManual ? getFiltersPayload() : null,
            selectedMatchId: clickedId
        });
    };

    const renderMatchPercentage = (data) => {
        return <div className="h1 color-secondary-pink">{data}% Match</div>;
    };
    const renderNameColumn = (detail) => {
        return (
            <div className="am-listing-table-name-wrapper">
                <div className="am-listing-table-name h3 color-typrography-3 text-truncate">
                    {detail.Name}
                </div>
                <div className="am-listing-table-origin h4 color-typrography-3 m-t-sm text-truncate">
                    {detail.EthinicOrigin}
                </div>
            </div>
        );
    };

    return (
        <>
            <LayoutWrapper breadCrumb={false}>
                <div className="hc-listing-table table-responsive">
                    <Row className="p-0 m-0">
                        <Col xs="12" className="p-0">
                            <Card>
                                <CardBody className="p-0">
                                    <DataTable
                                        data={data}
                                        loading={fetching}
                                        currentSortOrder={currentSortOrder}
                                        currentSortColumn={currentSortColumn}
                                        onSortClick={handleSortClick}
                                        config={[
                                            {
                                                title: renderBackIcon(),
                                                render: (data, index) => (
                                                    <Avatar
                                                        className="am-listing-avatar"
                                                        width="100"
                                                        height="100"
                                                        src={data?.ProfilePictureUrl}
                                                        name={data?.Name}
                                                    />
                                                )
                                            },
                                            {
                                                title: MATCH_SUGGESTION_COLUMNS.NAME,
                                                isSortable: true,
                                                render: (data) => renderNameColumn(data),
                                                sortColumn:
                                                    SORTABLE_COLUMNS[MATCH_SUGGESTION_COLUMNS.NAME]
                                            },
                                            {
                                                title: MATCH_SUGGESTION_COLUMNS.MATCH,
                                                render: (data) =>
                                                    renderMatchPercentage(data?.MatchPercentage),
                                                isSortable: true,
                                                sortColumn:
                                                    SORTABLE_COLUMNS[MATCH_SUGGESTION_COLUMNS.MATCH]
                                            },

                                            {
                                                render: (data, index) => {
                                                    return (
                                                        <BlockButton
                                                            className="am-view-profile-btn h5 color-white p-v-xs p-h-lg btn button-type-3"
                                                            onClick={(e) => {
                                                                handleRowClick(data.UserId);
                                                            }}
                                                        >
                                                            {props.t("View Profile")}
                                                        </BlockButton>
                                                    );
                                                }
                                            }
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={count}
                                        onPageClick={handlePageClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </LayoutWrapper>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        fetching: state.matchSuggestion.fetching,
        count: state.matchSuggestion.count,
        data: state.matchSuggestion.list
    };
};

export default withNamespaces()(connect(mapStateToProps, null)(MatchSuggestionListing));
