import React from "react";
import { withNamespaces } from "react-i18next";
import ChatNavListItemAvatar from "./ChatNavListItemAvatar";
import "./Chats.scss";

const ChatContentHeader = (props) => {
    const { chatTitle = "Zain,Aysha" } = props;
    const infoIcon = require("../../assets/images/info.svg");
    return (
        <div className="chat-content-header-wrapper d-flex align-items-center justify-content-between p-v-md p-l-lg bg-white">
            <div className="chat-content-header-left  d-flex align-items-center">
                <div className="position-relative">
                    <ChatNavListItemAvatar classes="chat-content-header-avatar" multiple={true} />
                </div>
                <h1 className="color-secondary-blue mb-0 m-l-xl">{chatTitle}</h1>
            </div>
            <span className="info-icon">
                <img src={infoIcon} alt="info-icon" />
            </span>
        </div>
    );
};

export default withNamespaces()(ChatContentHeader);
