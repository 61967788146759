import React from "react";

import "./DataTable.scss";
import { SortOrder } from "../../constants";
import FilterCell from "./FilterCell";

const TableHead = (props) => {
    const { options, currentSortOrder, currentSortColumn, onSortClick } = props;

    const handleTableHeadClick = () => {
        if (options.isSortable && onSortClick && typeof onSortClick === "function") {
            onSortClick(options);
        }
    };

    const renderSortIcon = () => {
        if (!currentSortColumn || currentSortColumn !== options.sortColumn) {
            return <i className="bx bx-sort"></i>;
        }
        return currentSortOrder === SortOrder.ASC ? (
            <i className="bx bx-sort-down"></i>
        ) : (
            <i className="bx bx-sort-up"></i>
        );
    };

    return (
        <th>
            {!options.isFilterable ? (
                <div
                    className={`data-table-head ${options.isSortable ? "sortable" : ""}`}
                    onClick={handleTableHeadClick}
                >
                    {options.title}
                    {options.isSortable && renderSortIcon()}
                </div>
            ) : (
                <FilterCell options={options} />
            )}
        </th>
    );
};

export default TableHead;
