import React from "react";

import "./Verification.scss";

import { getMediaThumbPath } from "../../utils/mediaService";
import { IdverificationLabels } from "../../constants/IdverificationTypes";

const ProfileCard = (props) => {
    const { data, onClick } = props;

    const country = data?.UserProfile?.Country?.Name;
    const age = data?.UserProfile?.Age;

    return (
        <div
            className=" d-flex align-items-center justify-content-between profile-card__wrapper ml-5"
            onClick={() => onClick(data)}
        >
            <div className="d-flex align-items-center profile-card__profile">
                <img src={getMediaThumbPath(data.Media)} />
                <p className="px-3 name" title={data.FullName}>
                    {data.FullName}
                </p>
            </div>
            <div className="profile-card__info">
                <div className="d-flex profile-card__info-detail">
                    <p>
                        {age}, {country}
                    </p>
                </div>
                <div className="profile-card__info-detail">
                    <p>ID Verification: {IdverificationLabels[data.IdVerificationType]}</p>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
