import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import {
    getCurrentLivingStatus,
    getEducationLevel,
    getHalalPreference,
    getMaritalStatus,
    getPrayerRoutine,
    getSect,
    getWorkingSituation
} from "../../common/common";
import { SkeletonLoader } from "../../components/Loader";
import BlockButton from "../../components/Button/Button";
import Pill from "../../components/Pill/Pill";
import { ErrorPage } from "../../components/Utility";
import { userDetails } from "../../store/actions/userDetailsAction";
import { convertInches } from "../../utils/commonUtils";
import { FilterConstants } from "../../constants";
import { useHistory } from "react-router-dom";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import "./UserDetail.scss";
import {
    FilterModal,
    FilterModalBody,
    FilterModalHeader,
    FilterModalFooter
} from "../../components/Modal/FilterModal";

const ManualMatchFilters = (props) => {
    const history = useHistory();
    const { id, isManualModal, setIsManualModal } = props;
    const [count, setCount] = useState(0);
    const [personalFilters, setPersonalFilters] = useState({
        age: false,
        children: false,
        maritalStatus: false,
        currentLocation: false
    });
    const [religionFilters, setReligionFilters] = useState({
        sect: false,
        prayerRoutine: false,
        halalPreferences: false,
        smokingHabits: false,
        alcoholConsumption: false
    });
    const [professionalFilters, setProfessionalFilters] = useState({
        levelOfEducation: false,
        workingPreference: false
    });

    const getObject = (obj, item) => {
        return { ...obj, [`${item}`]: false };
    };
    const [traitsFilter, setTraitsFilter] = useState(props.traitsFilter?.reduce(getObject, {}));

    const handlePersonalFiltersChange = (item) => {
        if (personalFilters[item]) {
            setCount(count - 1);
        } else {
            setCount(count + 1);
        }
        setPersonalFilters({
            ...personalFilters,
            [item]: !personalFilters[item]
        });
    };
    const handleProfessionalFiltersChange = (item) => {
        if (professionalFilters[item]) {
            setCount(count - 1);
        } else {
            setCount(count + 1);
        }
        setProfessionalFilters({
            ...professionalFilters,
            [item]: !professionalFilters[item]
        });
    };

    const handleReligionFilters = (item) => {
        if (religionFilters[item]) {
            setCount(count - 1);
        } else {
            setCount(count + 1);
        }
        setReligionFilters({
            ...religionFilters,
            [item]: !religionFilters[item]
        });
    };
    const handleTraitFilterChange = (item) => {
        if (traitsFilter[item]) {
            setCount(count - 1);
        } else {
            setCount(count + 1);
        }
        setTraitsFilter({
            ...traitsFilter,
            [item]: !traitsFilter[item]
        });
    };
    const handleSubmitFilter = () => {
        setIsManualModal(false);
        history.push(
            getRoute(APP_ROUTES.MATCH_LISTING, {
                id: id
            }),
            {
                isManual: {
                    traits: traitsFilter,
                    religion: religionFilters,
                    professional: professionalFilters,
                    personal: personalFilters,
                    count: count
                }
            }
        );
    };
    return (
        <FilterModal isOpen={isManualModal} toggle={() => setIsManualModal(!isManualModal)}>
            <FilterModalHeader
                onClose={() => setIsManualModal(!isManualModal)}
                title={props.t("Filters")}
                subTitle={props.t("Based on Preferences")}
            />

            <FilterModalBody>
                <Row>
                    <Col xs={12}>
                        <div className="fm-filter-wrapper d-flex flex-column flex-wrap">
                            <div className="body-text-13 color-typrography-3">
                                {props.t("Personal")}
                            </div>
                            <div className="d-flex flex-wrap fm-filter-btn-wrapper m-t-lg">
                                {Object.keys(personalFilters).map((item) => {
                                    return (
                                        <Pill
                                            classes={`filter-pill ${
                                                personalFilters[item] ? "" : "unselected"
                                            }`}
                                            onClick={() => handlePersonalFiltersChange(item)}
                                        >
                                            {props.t(FilterConstants[item])}
                                        </Pill>
                                    );
                                })}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="fm-filter-wrapper d-flex flex-column flex-wrap">
                            <div className="body-text-13 color-typrography-3">
                                {props.t("Professional")}
                            </div>
                            <div className="d-flex flex-wrap fm-filter-btn-wrapper m-t-lg">
                                {Object.keys(professionalFilters).map((item) => (
                                    <Pill
                                        classes={`filter-pill ${
                                            professionalFilters[item] ? "" : "unselected"
                                        }`}
                                        onClick={() => handleProfessionalFiltersChange(item)}
                                    >
                                        {props.t(FilterConstants[item])}
                                    </Pill>
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="fm-filter-wrapper d-flex flex-column flex-wrap">
                            <div className="body-text-13 color-typrography-3">
                                {props.t("Religion")}
                            </div>
                            <div className="d-flex flex-wrap fm-filter-btn-wrapper m-t-lg">
                                {Object.keys(religionFilters).map((item) => (
                                    <Pill
                                        classes={`filter-pill ${
                                            religionFilters[item] ? "" : "unselected"
                                        }`}
                                        onClick={() => handleReligionFilters(item)}
                                    >
                                        {props.t(FilterConstants[item])}
                                    </Pill>
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="fm-filter-wrapper d-flex flex-column flex-wrap">
                            <div className="body-text-13 color-typrography-3">
                                {props.t("Personality Traits")}
                            </div>
                            <div className="d-flex flex-wrap fm-filter-btn-wrapper m-t-lg">
                                {traitsFilter &&
                                    Object.keys(traitsFilter).map((item) => (
                                        <Pill
                                            classes={`filter-pill ${
                                                traitsFilter[item] ? "" : "unselected"
                                            }`}
                                            onClick={() => handleTraitFilterChange(item)}
                                        >
                                            {props.t(item)}
                                        </Pill>
                                    ))}
                            </div>
                        </div>
                    </Col>
                    <FilterModalFooter classes="d-flex align-items-center justify-content-center">
                        <BlockButton
                            className="button-type-1 btn btn-lg"
                            onClick={handleSubmitFilter}
                        >
                            {props.t("Find a Match")}
                        </BlockButton>
                    </FilterModalFooter>
                </Row>
            </FilterModalBody>
        </FilterModal>
    );
};

export default withNamespaces()(ManualMatchFilters);
