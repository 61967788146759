import React from "react";
import ReactDOM from "react-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./store";

const Root = (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <SimpleReactLightbox>
                <App />
            </SimpleReactLightbox>
        </PersistGate>
    </Provider>
);

ReactDOM.render(Root, document.getElementById("root"));
//serviceWorker.unregister();

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((reg) => {
            console.log("service worker registered");
        })
        .catch((err) => {
            console.log(err);
        });
}
