import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Col, Row } from "reactstrap";
import { Loader } from "../../components/Loader";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import ToggleList from "./Components/ToggleList";
import { ErrorPage } from "../../components/Utility";
import { NotificationCategory, Roles } from "../../constants/index";
import {
    getNotificationSettings,
    updateNotificationSettings
} from "../../store/actions/notificationSettingAction";
import VerticalTabs from "../../components/VerticalTabs/VerticalTabs";
import { NewMessage } from "./constant";
import notificationToastr from "../../utils/toastUtil";
import "./Settings.scss";

const NotificationSettings = (props) => {
    const dispatch = useDispatch();
    const [isUpdating, setIsUpdating] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [settings, setSettings] = useState(null);

    const tabsName = ["USER", "MESSAGES", "MATCHES", "PERSONAL"];
    const bottomAvatar = require("../../assets/images/animated-chat.svg");

    const settingsList = tabsName.map((name) => {
        return {
            tabTitle: props.t(name),
            tabContent:
                name !== tabsName[1] ? (
                    <div className="hc-ns-content">
                        <Row>
                            <Col xs={12} md={6} xl={6}>
                                <ToggleList
                                    data={settings?.filter(
                                        (item) => item.CategoryId === NotificationCategory[name]
                                    )}
                                    onClick={(data) => {
                                        handleUpdateSettings(data);
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <div className="hc-ns-content">
                        <Row>
                            <Col xs={12} md={6} xl={6}>
                                <ToggleList
                                    title={props.t("New Messages")}
                                    data={settings?.filter(
                                        (item) =>
                                            item.CategoryId === NotificationCategory[name] &&
                                            NewMessage.includes(item.SettingId)
                                    )}
                                    onClick={(data) => {
                                        handleUpdateSettings(data);
                                    }}
                                />
                                <ToggleList
                                    data={settings?.filter(
                                        (item) =>
                                            item.CategoryId === NotificationCategory[name] &&
                                            !NewMessage.includes(item.SettingId)
                                    )}
                                    onClick={(data) => {
                                        handleUpdateSettings(data);
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                )
        };
    });
    useEffect(() => {
        setIsFetchingData(true);
        dispatch(getNotificationSettings({ RoleId: Roles.MATCH_MAKER }))
            .then((res) => {
                setSettings(res.Data);
                setIsFetchingData(false);
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
                setIsFetchingData(false);
            });
    }, []);
    const handleUpdateSettings = (data) => {
        setIsUpdating(true);
        let payload = { RoleId: Roles.MATCH_MAKER, Settings: [data] };
        dispatch(updateNotificationSettings(payload))
            .then((res) => {
                setSettings(res.Data);
                setIsUpdating(false);
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
                setIsUpdating(false);
            });
    };

    return (
        <>
            <LayoutWrapper>
                {!isFetchingData &&
                    (settings ? (
                        <VerticalTabs
                            isUpdating={isUpdating}
                            isDivider={false}
                            isNotification={true}
                            tabs={settingsList}
                            footerImg={bottomAvatar}
                            tabTitleSpace={true}
                        />
                    ) : (
                        <ErrorPage />
                    ))}

                {isFetchingData && <Loader fullPageLoader={true} />}
            </LayoutWrapper>
        </>
    );
};

export default withNamespaces()(NotificationSettings);
