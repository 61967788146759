import { fetchUtil } from "../../utils/fetchUtil";
import { updateDeviceId } from "../../store/actions";

export const ME_REQUEST = "ME_REQUEST";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_ERROR = "ME_ERROR";

export const SET_TOKEN = "SET_TOKEN";
export const SIGN_OUT = "SIGN_OUT";
export const IS_USER_VERIFIED = "IS_USER_VERIFIED";

export const login = (bodyData) => (dispatch, getState) => {
    const body = JSON.stringify({ ...bodyData });

    return fetchUtil({
        url: "/user/login",
        method: "POST",
        body
    })
        .then((res) => {
            dispatch(getMe(res?.Data?.Token));
            //dispatch({ type: IS_USER_VERIFIED, payload: res.Data });
            dispatch({ type: SET_TOKEN, token: res.Data.Token });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getMe = (token) => (dispatch, getState) => {
    dispatch({ type: ME_REQUEST });
    return fetchUtil({
        url: "/user/me",
        token: token || getState().auth.user.Token
    })
        .then((res) => {
            dispatch({ type: ME_SUCCESS, payload: res.Data });
            localStorage.isAuthenticated = true;
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            dispatch({ type: ME_ERROR });
            return Promise.reject(err);
        });
};

export const userVerified = () => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    dispatch({ type: IS_USER_VERIFIED });
};

export const updateFCMToken = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        url: "/user/device-id",
        method: "POST",
        body: JSON.stringify(data),
        token
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const logout = () => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        url: "/user/logout",
        method: "POST",
        token
    })
        .then((res) => {
            dispatch({ type: SIGN_OUT, payload: res.Data });
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
