import React from 'react';
import '../../BasicModal.scss';

const Header = ({backClick}) => {
    return(
        <div className="modalHeader d-flex">
             <i className="dripicons-chevron-left icon" onClick={backClick} ></i> <p className="body-text-0">Back</p>
        </div>
    )
};

export default Header;