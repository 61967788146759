import React from "react";
import "../ReportDetail.scss";

const HeadingText = ({ containerClass, title, description }) => {
    return (
        <div className={containerClass}>
            <h1 className="mb-5 color-black">{title}</h1>
            <p className="color-black">{description}</p>
        </div>
    );
};

export default HeadingText;
