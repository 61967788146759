import React from "react";

import "./CountBadge.scss";

const CountBadge = (props) => {
    const { count, classes } = props;
    return (
        <>
        {count != 0 && <span
            className={`count-badge body-text-12 color-white position-absolute d-inline-flex justify-content-center align-items-center ${classes}`}
        >
            {count}
        </span>}
        </>
    );
};

export default CountBadge;
