import React from "react";
import { withNamespaces } from "react-i18next";

import ModalGroup from "./ModalAssets/Groups/Groups";

const UserSelectionModal = (props) => {
    const { title } = props;

    const onSubmit = (e, singleListing) => {
        singleListing.onClick({ id: singleListing.id, name: singleListing?.username });
    };

    return (
        <ModalGroup title={title} subTitle={props.t("")}>
            <div className="listing-container">
                {props.listings.map((singleListing) => {
                    return (
                        <div
                            className="listing-elements d-flex flex-row justify-content-between cursor-pointer"
                            onClick={(e) => onSubmit(e, singleListing)}
                        >
                            <div className="d-flex flex-row justify-content-between align-items-center align-content-center">
                                {singleListing.image && (
                                    <div className="image">
                                        <img src={singleListing.image} />
                                    </div>
                                )}
                                {singleListing.username && (
                                    <div>
                                        <h6>{singleListing.username}</h6>
                                    </div>
                                )}
                            </div>
                            <div>
                                {singleListing.icon && <i className="dripicons-chevron-right"></i>}
                            </div>
                        </div>
                    );
                })}
            </div>
        </ModalGroup>
    );
};

export default withNamespaces()(UserSelectionModal);
