import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { getReportStatus, getCauseType } from "../../common/common";
import { Roles } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import {
    getReportDetail,
    sendReportAction,
    createReport,
    getMessageListing,
    sendAppealAction
} from "../../store/actions/reportDetailActions";
import { Avatar } from "../../components/Avatar";
import { store } from "../../store";
import BlockButton from "../../components/Button/Button";
import InfoModal from "../../components/Modal/InfoModal/InfoModal";
import InfoModalBody from "../../components/Modal/InfoModal/InfoModalBody";
import InfoModalHeader from "../../components/Modal/InfoModal/InfoModalHeader";
import { SeeProfileBadge } from "../../components/SeeProfileBadge";
import { useHistory } from "react-router-dom";
import ChatListItemAvatar from "./ChatListItemAvatar";
import notificationToastr from "../../utils/toastUtil";
import {
    ReportAction,
    ReportStatus,
    CauseType,
    ChatType,
    AppealAction,
    UserStatusCode
} from "../../constants";
import modalConstant from "../../constants/ModalConstant";
import BasicModal from "../../components/Modal/BasicModal";

const ChatMessageHeader = (props) => {
    const dispatch = useDispatch();
    const { name, users, status, onHamburgerClick, chatTab, chatId, showInfoIcon } = props;
    const history = useHistory();
    const [infoModal, showInfoModal] = useState(false);
    const [reportsModal, setReportsModal] = useState(false);
    const [reportAction, setReportAction] = useState(null);
    const [userModal, setUserModal] = useState(false);
    const [warningModal, setWarningModal] = useState(false);
    const [suspendModal, setSuspendModal] = useState(false);
    const [confirmSuspendModal, setConfirmSuspendModal] = useState(false);
    const [resolveReport, setResolveReport] = useState(false);
    const [removeModal, setRemoveModal] = useState(false);
    const [removeConfirmationModal, setRemoveConfirmationModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [messageModal, setMessageModal] = useState({ isOpen: false, message: "" });

    const infoIcon = require("../../assets/images/info.svg");
    const reportActions = [
        {
            name: props.t("Warning"),
            clickFunc: () => {
                openUserModal(ReportAction.WARNING);
            }
        },
        {
            name: props.t("Suspension"),
            clickFunc: () => {
                openUserModal(ReportAction.SUSPEND);
            }
        },
        {
            name: props.t("Remove"),
            clickFunc: () => {
                openUserModal(ReportAction.REMOVE);
            }
        },
        {
            name: props.t("Resolved"),
            clickFunc: () => {
                setReportAction({ ...reportAction, Type: ReportAction.RESOLVED });
                setResolveReport(true);
            }
        }
    ];

    const remarksOption = Object.keys(CauseType).map((key) => {
        return {
            label: props.t(getCauseType(CauseType[`${key}`])),
            value: CauseType[`${key}`],
            radioName: "radioButton"
        };
    });
    const daysOption = [
        {
            label: props.t("7 Days"),
            value: "7",
            radioName: "daysRadioButton"
        },
        {
            label: props.t("14 Days"),
            value: "14",
            radioName: "daysRadioButton"
        }
    ];
    const getUserModalTitle = () => {
        let title = "";
        switch (reportAction.Type) {
            case ReportAction.WARNING:
                title = "which user do you want to send a warning";
                break;

            case ReportAction.REMOVE:
                title = "which user do you want to remove from platform";
                break;

            case ReportAction.SUSPEND:
                title = "which user do you wish to suspend";
                break;
        }
        return props.t(title);
    };

    const messageFunction = (message) => {
        setMessageModal({ isOpen: true, message: message });
    };

    const openUserModal = (reportType) => {
        setReportAction({ ...reportAction, Type: reportType });
        if (chatTab !== ChatType.Individual) {
            setUserModal(true);
        } else {
            let reportedUser = users.filter((item) => item.Type === Roles.USER);
            onUserModalClick(
                { id: reportedUser[0]?.UserId, name: reportedUser[0]?.Name },
                reportType
            );
        }
    };

    const onUserModalClick = ({ id: data, name }, reportType) => {
        setReportAction({
            ...reportAction,
            ReportedId: parseInt(data),
            ReportedName: name,
            Type: reportType ? reportType : reportAction.Type
        });
        if (reportAction?.Type === ReportAction.WARNING || reportType === ReportAction.WARNING) {
            setWarningModal(true);
        } else if (
            reportAction?.Type === ReportAction.SUSPEND ||
            reportType === ReportAction.SUSPEND
        ) {
            setSuspendModal(true);
        } else if (
            reportAction?.Type === ReportAction.REMOVE ||
            reportType === ReportAction.REMOVE
        ) {
            setRemoveModal(true);
        }
        setUserModal(false);
    };

    const onWarningModalSubmit = (data) => {
        let detail = {
            ...reportAction,
            Remarks: data.comments,
            CauseId: parseInt(data.optionValue)
        };
        setReportAction(detail);
        submitWarningActionReport(detail);
    };
    const onRemoveModalSubmit = (data) => {
        setReportAction({
            ...reportAction,
            Remarks: data.comments,
            CauseId: parseInt(data.optionValue)
        });
        setRemoveModal(false);
        setRemoveConfirmationModal(true);
    };
    const onMessageModalSubmit = () => {
        setMessageModal({ isOpen: false, message: "" });
    };
    const onSuspendModalSubmit = (data) => {
        setReportAction({
            ...reportAction,
            Remarks: data.comments,
            CauseId: parseInt(data.remarksOption),
            SuspendedDays: data.dayOption
        });

        setSuspendModal(false);
        setConfirmSuspendModal(true);
    };
    const handleConfirmSuspendModal = () => {
        submitSuspendActionReport(reportAction);
    };
    const handleCancelSuspendModal = () => {
        setConfirmSuspendModal(false);
        setSuspendModal(true);
    };
    const onSubmitRemoveConfirmationModal = (data) => {
        let detail = {
            ...reportAction,
            Type: data.option
        };
        setReportAction(detail);
        if (data.option === ReportAction.WARNING) {
            setRemoveConfirmationModal(false);
            setWarningModal(true);
        } else if (data.option === ReportAction.SUSPEND) {
            setRemoveConfirmationModal(false);
            setSuspendModal(true);
        } else if (data.option === ReportAction.REMOVE) {
            submitRemoveActionReport(detail);
        }
    };
    const getUserFromList = (Data) => {
        return users?.find((item) => item.UserId === Data?.ReportedId);
    };
    const getCreateReportPayload = (Data) => {
        return {
            ChatId: chatId,
            About: "",
            ReportedId: Data.ReportedId,
            CauseId: Data.CauseId
        };
    };
    const submitRemoveActionReport = (Data) => {
        setIsLoadingModal(true);
        dispatch(createReport(getCreateReportPayload(Data)))
            .then((res) => {
                dispatch(sendReportAction({ ...Data, ReportId: res.Data.Id }))
                    .then((res) => {
                        setIsLoadingModal(false);
                        setRemoveConfirmationModal(false);
                        messageFunction(`${getUserFromList(Data)?.Name} has been removed.`);
                        setReportAction({ ReportId: reportAction.ReportId });
                    })
                    .catch((err) => {
                        setIsLoadingModal(false);
                        notificationToastr.error(err.Message);
                    });
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
            });
    };
    const submitSuspendActionReport = (Data) => {
        setIsLoadingModal(true);
        dispatch(createReport(getCreateReportPayload(Data)))
            .then((res) => {
                dispatch(sendReportAction({ ...Data, ReportId: res.Data.Id }))
                    .then((res) => {
                        setIsLoadingModal(false);
                        setConfirmSuspendModal(false);
                        messageFunction(`${getUserFromList(Data)?.Name} has been suspended.`);
                        setReportAction({ ReportId: reportAction.ReportId });
                    })
                    .catch((err) => {
                        setIsLoadingModal(false);
                        notificationToastr.error(err.Message);
                    });
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
            });
    };
    const submitWarningActionReport = (Data) => {
        setIsLoadingModal(true);
        dispatch(createReport(getCreateReportPayload(Data)))
            .then((res) => {
                dispatch(sendReportAction({ ...Data, ReportId: res.Data.Id }))
                    .then((res) => {
                        setIsLoadingModal(false);
                        setWarningModal(false);
                        messageFunction(
                            <>
                                <strong className="fw-500">{getUserFromList(Data)?.Name}</strong>
                                {` has been warned for their violation / behaviour.`}
                            </>
                        );
                        setReportAction({ ReportId: reportAction.ReportId });
                    })
                    .catch((err) => {
                        setIsLoadingModal(false);
                        notificationToastr.error(err.Message);
                    });
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
            });
    };

    const getUserNames = (users) => {
        const chatUsers = [];
        {
            !!users.length &&
                users.map((user) => {
                    if (user.UserId !== store.getState().auth.user.Id) chatUsers.push(user.Name);
                });
        }

        return chatUsers.join(" , ");
    };
    const handleClickSeeConnection = (users) => {
        let UserId = users.find((user) => user.UserId !== store.getState().auth.user.Id)?.UserId;
        history.push(getRoute(APP_ROUTES.SEE_CONNECTIONS, { id: UserId }));
    };
    return (
        <>
            <div className="chat-content-header-wrapper d-flex align-items-center justify-content-between p-v-md p-l-lg bg-white">
                <div className="chat-content-header-left  d-flex align-items-center">
                    <span className="hamburger-menu" onClick={onHamburgerClick}>
                        <i className="fa fa-fw fa-bars color-black"></i>
                    </span>
                    <div className="position-relative">
                        <ChatListItemAvatar classes="chat-content-header-avatar" data={name} />
                    </div>
                    <h1 className="color-secondary-blue mb-0 m-l-xl text-truncate text-capitalize">
                        {getUserNames(name)}
                    </h1>
                </div>

                <span className="info-icon">
                    <img src={infoIcon} alt="info-icon" onClick={() => showInfoModal(true)} />
                </span>
            </div>
            {reportsModal && (
                <InfoModal>
                    <InfoModalHeader title="Report" onClose={() => setReportsModal(false)} />
                    <InfoModalBody>
                        <Col xs={12}>
                            <div className="d-flex align-items-center justify-content-center flex-wrap">
                                <div className="p-light color-secondary-blue">
                                    {props.t(`Report modal text`)}
                                </div>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="d-flex flex-column align-items-center justify-content-center flex-wrap">
                                <BlockButton
                                    className="button-type-3 btn-report-fw p text-capitalize m-t-xl"
                                    onClick={() => {
                                        setReportsModal(false);
                                        openUserModal(ReportAction.WARNING);
                                    }}
                                >
                                    {props.t("warning")}
                                </BlockButton>
                                <BlockButton
                                    className="button-type-3 btn-report-fw p text-capitalize m-t-xl"
                                    onClick={() => {
                                        setReportsModal(false);
                                        openUserModal(ReportAction.SUSPEND);
                                    }}
                                >
                                    {props.t("suspend account")}
                                </BlockButton>
                                <BlockButton
                                    className="button-type-3 btn-report-fw p text-capitalize m-t-xl"
                                    onClick={() => {
                                        setReportsModal(false);
                                        openUserModal(ReportAction.REMOVE);
                                    }}
                                >
                                    {props.t("Remove from the platform")}
                                </BlockButton>
                            </div>
                        </Col>
                    </InfoModalBody>
                </InfoModal>
            )}
            {infoModal && (
                <InfoModal>
                    <InfoModalHeader title="Chat Info" onClose={() => showInfoModal(false)} />
                    <InfoModalBody>
                        <Col xs={12}>
                            <div className="d-flex align-items-center justify-content-center flex-wrap">
                                {!!name.length &&
                                    name
                                        .filter((item) => item.Type === Roles.USER)
                                        ?.map((user) => {
                                            if (user.UserId !== store.getState().auth.user.Id)
                                                return (
                                                    <SeeProfileBadge
                                                        name={user.Name}
                                                        img={user.MediaPath}
                                                        id={user.UserId}
                                                    />
                                                );
                                        })}
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="d-flex align-items-center justify-content-center flex-wrap flex-column">
                                {chatTab === ChatType.Individual && (
                                    <BlockButton
                                        className="button-type-2 btn-chat-info body-text-1 text-capitalize m-t-xl"
                                        onClick={() => handleClickSeeConnection(users)}
                                    >
                                        {"See Connections"}
                                    </BlockButton>
                                )}
                                {showInfoIcon && (
                                    <BlockButton
                                        className="button-type-2 btn-chat-info body-text-1 text-capitalize m-t-xl"
                                        onClick={() => {
                                            setReportsModal(true);
                                            showInfoModal(false);
                                        }}
                                    >
                                        {"Report Abuse"}
                                    </BlockButton>
                                )}
                                <div className="info-message secondary_blue_hc body-text-10 text-center m-t-xl m-b-xl text-capitalize">
                                    {`to give matchmaker the ability to `}
                                    <br />
                                    {`create a new report`}
                                </div>
                            </div>
                        </Col>
                    </InfoModalBody>
                </InfoModal>
            )}

            <BasicModal
                isOpen={userModal}
                title={reportAction?.Type ? getUserModalTitle() : ""}
                backClick={() => {
                    setUserModal(false);
                }}
                userList={
                    users &&
                    users
                        .filter((item) => item.Type === Roles.USER)
                        ?.map((item) => {
                            return {
                                image: item.MediaPath,
                                id: item.UserId,
                                username: item.Name,
                                icon: true,
                                onClick: onUserModalClick
                            };
                        })
                }
                modalType={modalConstant.UserSelectionModal}
            />
            <BasicModal
                isOpen={warningModal}
                backClick={() => {
                    if (chatTab !== ChatType.Individual) setUserModal(true);
                    setWarningModal(false);
                }}
                onClick={onWarningModalSubmit}
                modalType={modalConstant.WarningModal}
                radioOption={remarksOption}
            />
            <BasicModal
                isOpen={removeModal}
                backClick={() => {
                    if (chatTab !== ChatType.Individual) setUserModal(true);
                    setRemoveModal(false);
                }}
                onClick={onRemoveModalSubmit}
                modalType={modalConstant.WarningModal}
                radioOption={remarksOption}
            />
            <BasicModal
                containerClasses="msg-violation-modal-max-width"
                isOpen={messageModal.isOpen}
                onClick={onMessageModalSubmit}
                modalType={modalConstant.MessageModal}
                content={messageModal.message}
                isModalHeader={false}
            />
            <BasicModal
                isOpen={suspendModal}
                backClick={() => {
                    if (chatTab !== ChatType.Individual) setUserModal(true);
                    setSuspendModal(false);
                }}
                onClick={onSuspendModalSubmit}
                modalType={"modal-type-4"}
                remarksOptionArray={remarksOption}
                daysOptionArray={daysOption}
            />
            <BasicModal
                isOpen={confirmSuspendModal}
                backClick={() => {
                    setConfirmSuspendModal(false);
                    setSuspendModal(true);
                }}
                title={`Are you sure ${
                    users && getUserFromList(reportAction)?.Name
                } will put their chats, connections and profile on hold for ${
                    reportAction?.SuspendedDays
                } days`}
                handleConfirm={handleConfirmSuspendModal}
                handleCancel={handleCancelSuspendModal}
                modalType={modalConstant.SuspendConfirmationModal}
            />
            <BasicModal
                isOpen={removeConfirmationModal}
                backClick={() => {
                    setRemoveModal(true);
                    setRemoveConfirmationModal(false);
                }}
                modalType={modalConstant.RemovalModal}
                reportAction={reportAction}
                onClick={onSubmitRemoveConfirmationModal}
            />
        </>
    );
};

export default withNamespaces()(ChatMessageHeader);
