import React from "react";
import BlockButton from "../../../components/Button/Button";
import "../ReportDetail.scss";

const ButtonListing = ({ ContainerClass, title, listingButtons }) => {
    return (
        <div className={ContainerClass}>
            <h3 className="mb-3 color-black">{title}</h3>
            <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row flex-sm-column flex-wrap">
                {listingButtons &&
                    listingButtons.map((singleListing, key) => {
                        return (
                            <div key={key}>
                                <BlockButton
                                    className="button-type-3 reduce-radius btn-md reduce-padding p mr-5 mb-4"
                                    btnImg={singleListing.image}
                                    btnIcon={singleListing.icon}
                                    onClick={singleListing.onClick}
                                >
                                    {" "}
                                    {singleListing.name}
                                </BlockButton>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default ButtonListing;
