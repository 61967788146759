import React from "react";
import "./UserListingBadge.scss";

import "./UserListingBadge.scss";
const UserListingBadge = (props) => {
    return (
        <div className={`hc-user-listing-badge ${props.className && props.className}`} onClick={props?.onClick}>
            {props.src ? <img className="rounded-circle" src={props.src} alt={props.name} width="50" height="50" />
                :
                <span className="avatar-title rounded-circle">{props?.name?.charAt(0)}</span>
            }
            <span className="hc-user-listing-badge-name p">{props?.name}</span>
        </div>
    );
};

export default UserListingBadge;
