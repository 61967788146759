import React from "react";

import "./FilterModal.scss";
const FilterModalHeader = ({ title = "", subTitle = "", onClose }) => {
    const crossIcon = require("../../../assets/images/close-button.svg");
    return (
        <div className="filter-modal-header m-b-xs position-relative d-flex align-items-center justify-content-center">
            <div>
                <span className="h1 m-0 p-r-sm">{title}</span>
                <span className="h4">{subTitle}</span>
            </div>
            <span className="position-absolute" onClick={onClose}>
                <img src={crossIcon} alt="cross" />
            </span>
        </div>
    );
};
export default FilterModalHeader;
