import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";
import Config from "../../Config";

export const PENDING_USERS_LISTING_REQUEST = "PENDING_USERS_LISTING_REQUEST";
export const PENDING_USERS_LISTING_SUCCESS = "PENDING_USERS_LISTING_SUCCESS";
export const PENDING_USERS_LISTING_FAILURE = "PENDING_USERS_LISTING_FAILURE";
export const FILTER_USER_VERIFICATION = "FILTER_USER_VERIFICATION";
export const NEW_VERIFICATION_IDENTITY = "NEW_VERIFICATION_IDENTITY";

export const getPendingUsersListing = (params = {}, abortSignal = null) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    dispatch({
        type: PENDING_USERS_LISTING_REQUEST,
        Page: params.Page
    });

    return fetchUtil({
        url: appendQueryParams("/user/id_verification", { ...params, Limit: Config.LIMIT }),
        token
    })
        .then((res) => {
            if (res && res.Data) {
                dispatch({
                    type: PENDING_USERS_LISTING_SUCCESS,
                    payload: res.Data.Users,
                    totalUsers: res.Data.TotalUsers
                });

                return Promise.resolve(res);
            }
        })
        .catch((err) => {
            dispatch({
                type: PENDING_USERS_LISTING_FAILURE
            });

            return Promise.reject(err);
        });
};

export const updateVerificationStatus = (obj) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: "/user/id_verification",
        token,
        method: "PUT",
        body: JSON.stringify(obj)
    })
        .then((res) => {
            dispatch({
                type: FILTER_USER_VERIFICATION,
                payload: obj.UserId
            });

            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const addNewUserVerification = (obj) => (dispatch, getState) => {
    dispatch({
        type: NEW_VERIFICATION_IDENTITY,
        payload: obj
    });
};
