import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import image_2 from "../../assets/images/about-us/image-2.png";
import VerticalTabs from '../../components/VerticalTabs/VerticalTabs';
import "./HelpFaqs.scss";

class HelpFaqs extends Component {
    render() {
        const Faqs = [
            {
                tabTitle: <p>{this.props.t("What is my role as a Matchmaker?")}</p>,
                tabContent: (
                    <div className="panel-body">
                        <h4>{this.props.t("Your role will be")}</h4>
                        <div className="divider-1"> ;</div>
                        <p className="h4 points">
                            {this.props.t(
                                "ensure that the both users and the mahram have a positive and safe experience using the app."
                            )}
                        </p>
                        <div className="divider-2"> ;</div>
                        <p className="h4 points">
                            {this.props.t(
                                "To match up users using their referrals on the dashboard with suitable match."
                            )}
                        </p>
                        <div className="divider-2"> ;</div>
                        <p className="h4 points">
                            {this.props.t(
                                "Be able to view and check any previous chat between users."
                            )}
                        </p>
                        <div className="divider-2"> ;</div>
                        <p className="h4 points">
                            {this.props.t(
                                "To deal with any reporting issue and to issue an appropriate course of action "
                            )}
                        </p>
                    </div>
                )
            },
            {
                tabTitle: <p>{this.props.t("Is it important for a Matchmaker to be involved")}</p>,
                tabContent: (
                    <div className="panel-body">
                        <h4>{this.props.t("Yes!")}</h4>
                        <div className="divider-1"> ;</div>
                        <p className="h4">
                            {this.props.t(
                                "it is very important for yourself to be involved to avoid any inappropriate chat or keeping the chat within appropriate topic. Also, for the safeguard of any user. "
                            )}
                        </p>
                    </div>
                )
            },
            {
                tabTitle: <p>{this.props.t("Can I change the preferences of a user")}</p>,
                tabContent: (
                    <div className="panel-body">
                        <h4>{this.props.t("Yes! ")}</h4>
                        <div className="divider-1"> ;</div>
                        <p className="h4">
                            {this.props.t(
                                "You do have the authority to do this but only in very rare situations should this happen as the user choice should be valued."
                            )}
                        </p>
                    </div>
                )
            },
            {
                tabTitle: <p>{this.props.t("Am I allowed to comment in the chat too")}</p>,
                tabContent: (
                    <div className="panel-body">
                        <h4>{this.props.t("Yes!")}</h4>
                        <div className="divider-1"> ;</div>
                        <p className="h4">
                            {this.props.t(
                                "You will be allowed to chat with all the users and mahrams."
                            )}
                        </p>
                    </div>
                )
            },
            {
                tabTitle: (
                    <p>{this.props.t("Will I be informed about all chats even if I missed it")}</p>
                ),
                tabContent: (
                    <div className="panel-body">
                        <h4>{this.props.t("Yes!")}</h4>
                        <div className="divider-1"> ;</div>
                        <p className="h4">
                            {this.props.t(
                                "The user can remove you from the chat however you will be able to view the current or previous chat."
                            )}
                        </p>
                    </div>
                )
            },
            {
                tabTitle: <p>{this.props.t("Can I as matchmaker be removed from the chat")}</p>,
                tabContent: (
                    <div className="panel-body">
                        <h4>{this.props.t("Yes!")}</h4>
                        <div className="divider-1"> ;</div>
                        <p className="h4">
                            {this.props.t(
                                "You will be notified and will have access to all the live and previous chats."
                            )}
                        </p>
                    </div>
                )
            },
            {
                tabTitle: (
                    <p>{this.props.t("If I have missed any chats, can I see them another time")}</p>
                ),
                tabContent: (
                    <div className="panel-body">
                        <h4>{this.props.t("Yes")}</h4>
                        <div className="divider-1"> ;</div>
                        <p className="h4">
                            {this.props.t(
                                "You are able to see any missed chat that you were invited into. "
                            )}
                        </p>
                    </div>
                )
            },
            {
                tabTitle: <p>{this.props.t("Will I see all types of notifications?")}</p>,
                tabContent: (
                    <div className="panel-body">
                        <h4>{this.props.t("Yes!")}</h4>
                        <div className="divider-1"> ;</div>
                        <p className="h4">
                            {this.props.t(
                                "You will see all types of notifications e.g. to all live chats, missed or previous chats, reporting, messages from mahram or user directed to you alone and preferences up dates. "
                            )}
                        </p>
                    </div>
                )
            },
            {
                tabTitle: (
                    <p>
                        {this.props.t("If any inappropriate behaviour")}
                    </p>
                ),
                tabContent: (
                    <div className="panel-body">
                        <h4>{this.props.t("You have the authority to issue a")}</h4>
                        <div className="divider-1"> ;</div>
                        <p className="h3 points">{this.props.t("Formal Written Warning")}</p>
                        <p className="h4">
                            {this.props.t(
                                "This will be an initial warning for the user if after investigating the incident you decide to take no further action after the reporting."
                            )}
                        </p>
                        <div className="divider-2"> ;</div>
                        <p className="h3 points">{this.props.t("Suspension")}</p>
                        <p className="h4">
                            {this.props.t(
                                "If the user has been reported again or if an incident does require a more severe punishment due to the level of misconduct after an investigation then a 7 or 14 day suspension can be issued, in that they will not be able access their account."
                            )}
                        </p>
                        <div className="divider-2"> ;</div>
                        <p className="h3 points">
                            {this.props.t("Removal from platform (account be deleted)")}
                        </p>
                        <p className="h4">
                            {this.props.t(
                                "This is the final and most severe reprimand a matchmaker can issue"
                            )}
                        </p>
                        <p className="h4">
                            {this.props.t("In every action a notification will be on the app")}
                        </p>
                    </div>
                )
            }
        ];
        return (
            <LayoutWrapper>
                <VerticalTabs
                    tabs={Faqs}
                    footerImg={image_2}
                />
            </LayoutWrapper>
        );
    }
}

export default withNamespaces()(HelpFaqs);
