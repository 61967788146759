import React from "react";
import { withNamespaces } from "react-i18next";
import { Col } from "reactstrap";
import BlockButton from "../../components/Button/Button";
import InfoModal from "../../components/Modal/InfoModal/InfoModal";
import InfoModalBody from "../../components/Modal/InfoModal/InfoModalBody";
import InfoModalHeader from "../../components/Modal/InfoModal/InfoModalHeader";
import { RoundedInput } from "../../components/RoundedInput";
import { SeeProfileBadge } from "../../components/SeeProfileBadge";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import ChatNavListing from "./ChatNavListing";
import ChatNavListItem from "./ChatNavListItem";
import ChatNavTabs from "./ChatNavTabs";
import ChatNavTabsItem from "./ChatNavTabsItem";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { useHistory } from "react-router-dom";

import "./Chats.scss";

const PreviousChats = (props) => {
    const history = useHistory();
    return (
        <>
            <LayoutWrapper>
                <div className="hc-chat-container">
                    <div className="hc-chat-layout h-100 d-flex flex-row">
                        <div className="chat-nav-container h-100 bg-white">
                            <div className="chat-nav-search-wrapper">
                                <RoundedInput placeholder="Search..." />
                            </div>
                            <div className="chat-nav-list-wrapper d-flex flex-column justify-content-between">
                                <ChatNavListing>
                                    <ChatNavListItem
                                        name="Zain"
                                        message="hello this is test"
                                        time="1h"
                                        unread={true}
                                        multipleAvatar={true}
                                    />
                                    <ChatNavListItem
                                        name="Zain, yasir"
                                        message="hello this is test and a long text with ellipses"
                                        time="22h"
                                        unread={true}
                                        multipleAvatar={false}
                                    />
                                    <ChatNavListItem
                                        name="Zain, yasir"
                                        message="hello this is test and a long text with ellipses"
                                        time="22h"
                                        unread={false}
                                        multipleAvatar={true}
                                    />
                                    <ChatNavListItem
                                        name="Zain, yasir"
                                        message="hello this is test and a long text with ellipses"
                                        time="22h"
                                        unread={false}
                                        multipleAvatar={false}
                                    />
                                </ChatNavListing>
                                <ChatNavTabs>
                                    <ChatNavTabsItem isGroupActive={true} />
                                </ChatNavTabs>
                            </div>
                        </div>
                        <div className="chat-nav-list-wrapper d-flex flex-column justify-content-between">
                            <ChatNavListing>
                                <ChatNavListItem
                                    name="Zain"
                                    message="hello this is test"
                                    time="1h"
                                    unread={true}
                                    multipleAvatar={true}
                                />
                                <ChatNavListItem
                                    name="Zain, yasir"
                                    message="hello this is test and a long text with ellipses"
                                    time="22h"
                                    unread={true}
                                    multipleAvatar={false}
                                />
                                <ChatNavListItem
                                    name="Zain, yasir"
                                    message="hello this is test and a long text with ellipses"
                                    time="22h"
                                    unread={false}
                                    multipleAvatar={true}
                                />
                                <ChatNavListItem
                                    name="Zain, yasir"
                                    message="hello this is test and a long text with ellipses"
                                    time="22h"
                                    unread={false}
                                    multipleAvatar={false}
                                />
                            </ChatNavListing>
                            <ChatNavTabs>
                                <ChatNavTabsItem isGroupActive={true} />
                            </ChatNavTabs>
                        </div>
                    </div>
                    <div className="hc-chat-content position-relative w-100">
                        <InfoModal>
                            <InfoModalHeader title="Chat Info" />
                            <InfoModalBody>
                                <Col xs={12}>
                                    <div className="d-flex align-items-center justify-content-center flex-wrap">
                                        <SeeProfileBadge name="zain" />
                                        <SeeProfileBadge name="zain" />
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className="d-flex align-items-center justify-content-center flex-wrap flex-column">
                                        <BlockButton
                                            className="button-type-2 btn-md body-text-1 text-capitalize m-t-xl"
                                            onClick={() => history.push(APP_ROUTES.SEE_CONNECTIONS)}
                                        >
                                            {props.t("See Connections")}
                                        </BlockButton>
                                        <BlockButton className="button-type-2 btn-md body-text-1 text-capitalize m-t-xl">
                                            {props.t("Report Abuse")}
                                        </BlockButton>
                                        <div className="info-message secondary_blue_hc body-text-10 text-center m-t-xl">
                                            {props.t(
                                                "to give matchmaker the ability to create a new report"
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </InfoModalBody>
                        </InfoModal>
                    </div>
                </div>
            </LayoutWrapper>
        </>
    );
};

export default withNamespaces()(PreviousChats);
