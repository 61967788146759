import CommonConstants from "../constants/CommonConstants";
import { isValidImageSize } from "../utils/commonUtils.js";
import notificationToastr from "../utils/toastUtil";
import { fetchUtil } from "./fetchUtil";

export const mediaService = (url, file) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    let imageFile = new FormData();
    imageFile.append("file", file);
    return fetchUtil({
        image: true,
        body: imageFile,
        method: "POST",
        url: url,
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const validateMedia = (acceptedFiles, t) => {
    if (
        CommonConstants.FILE_FORMATS.includes(acceptedFiles[0].type) &&
        isValidImageSize(acceptedFiles[0].size)
    ) {
        acceptedFiles.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        );
        return acceptedFiles[0];
    } else if (!CommonConstants.FILE_FORMATS.includes(acceptedFiles[0].type)) {
        notificationToastr.error(
            t(
                "Invalid format, only images with the following format can be uploaded jpg, jpeg, gif or png"
            ),
            t("Upload error")
        );
        return undefined;
    } else {
        notificationToastr.error(t("Image should be less than  8MBs"), t("Upload error"));
        return undefined;
    }
};

export const getMediaPath = (media = null) => {
    if (media && media.BasePath && media.Path) {
        return `${media.BasePath}${media.Path}`;
    }
    return "";
};

export const getMediaThumbPath = (mediaObj) => {
    if (mediaObj && Object.keys(mediaObj).length) {
        return mediaObj.ThumbPath;
    }
    return null;
};
