import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import userFemaleImg from "../../assets/images/Profile.png";
import { Switch, Route } from "react-router-dom";
import userImg from "../../assets/images/realuser.png";
import { getReportStatus, getCauseType, getAppealCause } from "../../common/common";
import { scrollToBottom } from "../../utils/domUtils";
import ChatMessageSection from "../Chat/ChatMessageSection";
import { Socket, SocketTypes } from "../../utils/socketUtils";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import CustomCard from "../../components/Card/CustomCard";
import {
    getChatListing,
    addNewChat,
    addMessage,
    updateChatMessageData,
    moveChatToTop,
    updateChatUnreadCount,
    updateChatUnReadMsg
} from "../../store/actions";
import ChatHistory from "../../components/ChatHistory/ChatHistory";
import BasicModal from "../../components/Modal/BasicModal";
import { SkeletonLoader } from "../../components/Loader";
import { ErrorPage } from "../../components/Utility";
import {
    ReportAction,
    ReportStatus,
    CauseType,
    ChatType,
    AppealAction,
    UserStatusCode,
    Roles
} from "../../constants";
import {
    getReportDetail,
    sendReportAction,
    getMessageListing,
    sendAppealAction
} from "../../store/actions/reportDetailActions";
import { getChatMessageSenderName } from "../../utils/commonUtils";
import { useHistory } from "react-router-dom";
import { convertDateTime } from "../../utils/dateFormatUtil";
import notificationToastr from "../../utils/toastUtil";
import ButtonListing from "./AssetsComponent/ButtonListing";
import DetailsTile from "./AssetsComponent/DetailsTile";
import HeadingText from "./AssetsComponent/HeadingText";
import { getChatMessageDate, getChatMessageTimeDifference } from "../../utils/dateTimeUtils";
import modalConstant from "../../constants/ModalConstant";
import "./ReportDetail.scss";
import MessageModal from "../../components/Modal/MessageModal";
import { error } from "toastr";
import reportDetailConstant from "./constant";

const ReportDetail = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const { id } = useParams();
    const [reportDetail, setReportDetail] = useState(null);
    const [reportAction, setReportAction] = useState(null);
    const [userModal, setUserModal] = useState(false);
    const [warningModal, setWarningModal] = useState(false);
    const [suspendModal, setSuspendModal] = useState(false);
    const [confirmSuspendModal, setConfirmSuspendModal] = useState(false);
    const [resolveReport, setResolveReport] = useState(false);
    const [removeModal, setRemoveModal] = useState(false);
    const [removeConfirmationModal, setRemoveConfirmationModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [messageModal, setMessageModal] = useState({ isOpen: false, message: "" });
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [isSidebarActive, setIsSidebarActive] = useState(false);
    const [page, setPage] = useState(1);
    const [messageListing, setMessageListing] = useState([]);
    const [totalMessageCount, setTotalMessageCount] = useState(0);
    const [isMessageLoading, setIsMessageLoading] = useState(false);

    const reportActions = [
        {
            name: props.t("Warning"),
            clickFunc: () => {
                openUserModal(ReportAction.WARNING);
            }
        },
        {
            name: props.t("Suspension"),
            clickFunc: () => {
                openUserModal(ReportAction.SUSPEND);
            }
        },
        {
            name: props.t("Remove"),
            clickFunc: () => {
                openUserModal(ReportAction.REMOVE);
            }
        },
        {
            name: props.t("Resolved"),
            clickFunc: () => {
                setReportAction({ ...reportAction, Type: ReportAction.RESOLVED });
                setResolveReport(true);
            }
        }
    ];

    const remarksOption = Object.keys(CauseType).map((key) => {
        return {
            label: props.t(getCauseType(CauseType[`${key}`])),
            value: CauseType[`${key}`],
            radioName: "radioButton"
        };
    });
    const daysOption = [
        {
            label: props.t("7 Days"),
            value: "7",
            radioName: "daysRadioButton"
        },
        {
            label: props.t("14 Days"),
            value: "14",
            radioName: "daysRadioButton"
        }
    ];
    const getUserModalTitle = () => {
        let title = "";
        switch (reportAction.Type) {
            case ReportAction.WARNING:
                title = "which user do you want to send a warning";
                break;

            case ReportAction.REMOVE:
                title = "which user do you want to remove from platform";
                break;

            case ReportAction.SUSPEND:
                title = "which user do you wish to suspend";
                break;
        }
        return props.t(title);
    };
    const handleChatStarted = (data) => {
        history.push(getRoute(APP_ROUTES.CHAT_LISTING, { id: parseInt(data.ChatId) }), {
            ChatType: ChatType.Individual
        });
    };
    useEffect(() => {
        setIsFetchingData(true);
        dispatch(getReportDetail(id))
            .then((res) => {
                setReportDetail({ ...res.Data });
                setIsFetchingData(false);
                setIsMessageLoading(true);
                dispatch(getMessageListing(res.Data.ChatId, { Page: page }))
                    .then((res) => {
                        setMessageListing(res.Data?.ChatMessages.reverse().concat(messageListing));
                        setTotalMessageCount(res.Data?.Count);
                        scrollToBottom();
                        setIsMessageLoading(false);
                    })
                    .catch((err) => {
                        notificationToastr.error(err.Message);
                        setIsMessageLoading(false);
                    });
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
                setIsFetchingData(false);
            });
        setReportAction({ ...reportAction, ReportId: parseInt(id) });
        return () => {
            Socket.remove(SocketTypes.CHAT_START, handleChatStarted);
        };
    }, []);
    const handleScroll = (e) => {
        e.preventDefault();
        const isTop = e.target.scrollTop < 10;

        if (isTop && totalMessageCount > messageListing.length) {
            setIsMessageLoading(true);
            let container = document.getElementById("chatbox-scroll-div");
            let prevScrollTop = container.scrollTop;
            let prevScrollHeight = container.scrollHeight;
            let currentPage = page + 1;
            dispatch(getMessageListing(reportDetail?.ChatId, { Page: currentPage }))
                .then((res) => {
                    setMessageListing(res.Data?.ChatMessages.reverse().concat(messageListing));
                    setTotalMessageCount(res.Data?.Count);
                    container.scrollTop =
                        container.scrollHeight - (prevScrollHeight - prevScrollTop);
                    setIsMessageLoading(false);
                    setPage(currentPage);
                })
                .catch((error) => {
                    setIsMessageLoading(false);
                });
        }
    };
    const getTimeDifference = (time, index, messageListing) => {
        let previousMessage = messageListing[index - 1];
        let showDate = true;
        if (previousMessage && previousMessage?.SendDate) {
            let isSameMinute = moment(previousMessage.SendDate).isSame(time, "minute");
            if (isSameMinute) {
                showDate = false;
            }
        }
        return showDate;
    };
    const chat = (
        <>
            <h3 className="mb-3 color-black">{props.t("Recorded conversation")}</h3>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <ChatHistory
                        username={
                            reportDetail?.Participants &&
                            reportDetail.Participants.filter((item) => item.Type === Roles.USER)
                                .map((item) => {
                                    return item.Name;
                                })
                                ?.join(" & ")
                        }
                        onScroll={handleScroll}
                        isLoading={isMessageLoading}
                        messages={messageListing.map((item, index) => {
                            return {
                                date:
                                    getTimeDifference(item.SendDate, index, messageListing) &&
                                    item.SendDate,
                                userImg:
                                    item.SenderId === user.Id
                                        ? user?.MediaUrl
                                        : reportDetail.Participants.find(
                                              (users) => users.UserId === item.SenderId
                                          )?.MediaPath,
                                userName:
                                    item.SenderId === user.Id
                                        ? null
                                        : getChatMessageSenderName(item, index, messageListing),
                                message: item.Content,
                                send: item.SenderId === user.Id,
                                days: getChatMessageDate(item, index, messageListing)
                            };
                        })}
                    />
                </div>
            </div>
        </>
    );
    const messageButton = (
        <ButtonListing
            listingButtons={
                reportDetail &&
                reportDetail.Participants?.filter((item) => item.Type === Roles.USER).map(
                    (item) => {
                        return {
                            image: item?.MediaPath,
                            id: item.UserId,
                            name: item.Name,
                            onClick: () => {
                                Socket.chatStart(item.UserId, handleChatStarted);
                            },
                            icon: <i className="dripicons-chevron-right back-icon"></i>
                        };
                    }
                )
            }
            title={props.t("Send message to:")}
        />
    );
    const submitAppealAction = (Data) => {
        setIsLoadingModal(true);
        dispatch(sendAppealAction(Data))
            .then((res) => {
                setIsLoadingModal(false);
                setReportDetail(res.Data);
                switch (Data.Type) {
                    case AppealAction.EndSuspension:
                        messageFunction(props.t("Suspension period ended"));
                        break;
                    case AppealAction.ContinueSuspension:
                        messageFunction(props.t("Suspension period will continue"));
                        break;
                    case AppealAction.ResumeProfile:
                        messageFunction(props.t("User will be reactive"));
                        break;
                    case AppealAction.AppealDecline:
                        messageFunction(props.t("Continue Suspension"));
                        break;
                }

                setReportAction({ ReportId: reportAction.ReportId });
            })
            .catch((err) => {
                setIsLoadingModal(false);
                notificationToastr.error(err.Message);
            });
    };
    const getAppealActions = (type) => {
        let appealAction;
        switch (type) {
            case UserStatusCode.SUSPENDED:
                appealAction = [
                    {
                        name: props.t("End Suspension"),
                        clickFunc: () => {
                            submitAppealAction({
                                ...reportAction,
                                Type: AppealAction.EndSuspension
                            });
                        }
                    },

                    {
                        name: props.t("Continue Suspension"),
                        clickFunc: () => {
                            submitAppealAction({
                                ...reportAction,
                                Type: AppealAction.ContinueSuspension
                            });
                        }
                    }
                ];
                break;
            case UserStatusCode.REMOVED:
                appealAction = [
                    {
                        name: props.t("Resume profile"),
                        clickFunc: () => {
                            submitAppealAction({
                                ...reportAction,
                                Type: AppealAction.ResumeProfile
                            });
                        }
                    },
                    {
                        name: props.t("Appeal decline"),
                        clickFunc: () => {
                            submitAppealAction({
                                ...reportAction,
                                Type: AppealAction.AppealDecline
                            });
                        }
                    }
                ];
                break;
        }

        return appealAction;
    };
    const messageFunction = (message) => {
        setMessageModal({ isOpen: true, message: message });
    };
    const openUserModal = (reportType) => {
        setReportAction({ ...reportAction, Type: reportType });
        setUserModal(true);
    };
    const onConfirmResolveModal = () => {
        submitResolveActionReport({ ...reportAction, ReportedId: reportDetail.ReportedUserId });
    };
    const onCancelResolveModal = () => {
        setResolveReport(false);
    };
    const onUserModalClick = ({ id: data, name }) => {
        setReportAction({
            ...reportAction,
            ReportedName: name,
            ReportedId: parseInt(data)
        });
        if (reportAction.Type === ReportAction.WARNING) {
            setWarningModal(true);
        } else if (reportAction.Type === ReportAction.SUSPEND) {
            setSuspendModal(true);
        } else if (reportAction.Type === ReportAction.REMOVE) {
            setRemoveModal(true);
        }
        setUserModal(false);
    };
    const onWarningModalSubmit = (data) => {
        let detail = {
            ...reportAction,
            Remarks: data.comments,
            CauseId: parseInt(data.optionValue)
        };
        setReportAction(detail);
        submitWarningActionReport(detail);
    };
    const onRemoveModalSubmit = (data) => {
        setReportAction({
            ...reportAction,
            Remarks: data.comments,
            CauseId: parseInt(data.optionValue)
        });
        setRemoveModal(false);
        setRemoveConfirmationModal(true);
    };
    const onMessageModalSubmit = () => {
        setMessageModal({ isOpen: false, message: "" });
    };
    const onSuspendModalSubmit = (data) => {
        setReportAction({
            ...reportAction,
            Remarks: data.comments,
            CauseId: parseInt(data.remarksOption),
            SuspendedDays: data.dayOption
        });

        setSuspendModal(false);
        setConfirmSuspendModal(true);
    };
    const handleConfirmSuspendModal = () => {
        submitSuspendActionReport(reportAction);
    };
    const handleCancelSuspendModal = () => {
        setConfirmSuspendModal(false);
        setSuspendModal(true);
    };
    const onSubmitRemoveConfirmationModal = (data) => {
        let detail = {
            ...reportAction,
            Type: data.option
        };
        setReportAction(detail);
        if (data.option === ReportAction.WARNING) {
            setRemoveConfirmationModal(false);
            setWarningModal(true);
        } else if (data.option === ReportAction.SUSPEND) {
            setRemoveConfirmationModal(false);
            setSuspendModal(true);
        } else if (data.option === ReportAction.REMOVE) {
            submitRemoveActionReport(detail);
        }
    };

    const getActionMenu = () => {
        let actionMenu;

        switch (reportDetail.Status) {
            case ReportStatus.RESOLVED:
                actionMenu = null;
                break;
            case ReportStatus.PENDING_REVIEW:
            case ReportStatus.UNDER_REVIEW:
                actionMenu = reportActions;
                break;
            case ReportStatus.UNDER_APPEAL:
            case ReportStatus.APPEAL_REQUESTED:
                actionMenu = getAppealActions(reportDetail.ReportedUserStatus);
                break;
        }

        return actionMenu;
    };
    const submitRemoveActionReport = (Data) => {
        setIsLoadingModal(true);
        dispatch(sendReportAction(Data))
            .then((res) => {
                setIsLoadingModal(false);
                setRemoveConfirmationModal(false);
                setReportDetail(res.Data);
                messageFunction(
                    `${
                        reportDetail.Participants?.find((item) => item.UserId === Data.ReportedId)
                            ?.Name
                    } has been removed.`
                );
                setReportAction({ ReportId: reportAction.ReportId });
            })
            .catch((err) => {
                setIsLoadingModal(false);
                notificationToastr.error(err.Message);
            });
    };
    const submitSuspendActionReport = (Data) => {
        setIsLoadingModal(true);
        dispatch(sendReportAction(Data))
            .then((res) => {
                setIsLoadingModal(false);
                setReportDetail(res.Data);
                setConfirmSuspendModal(false);
                messageFunction(
                    `${
                        reportDetail.Participants?.find((item) => item.UserId === Data.ReportedId)
                            ?.Name
                    } has been suspended.`
                );
                setReportAction({ ReportId: reportAction.ReportId });
            })
            .catch((err) => {
                setIsLoadingModal(false);
                notificationToastr.error(err.Message);
            });
    };
    const submitWarningActionReport = (Data) => {
        setIsLoadingModal(true);
        dispatch(sendReportAction(Data))
            .then((res) => {
                setIsLoadingModal(false);
                setReportDetail(res.Data);
                setWarningModal(false);
                messageFunction(
                    <>
                        <strong className="fw-500">
                            {
                                reportDetail.Participants?.find(
                                    (item) => item.UserId === Data.ReportedId
                                )?.Name
                            }
                        </strong>
                        {` has been warned for their violation / behaviour.`}
                    </>
                );
                setReportAction({ ReportId: reportAction.ReportId });
            })
            .catch((err) => {
                setIsLoadingModal(false);
                notificationToastr.error(err.Message);
            });
    };
    const submitResolveActionReport = (Data) => {
        setIsLoadingModal(true);
        dispatch(sendReportAction(Data))
            .then((res) => {
                setIsLoadingModal(false);
                setReportDetail(res.Data);
                setResolveReport(false);
                messageFunction(
                    <>
                        <strong className="fw-500">
                            {
                                reportDetail.Participants.find(
                                    (item) => item.UserId == reportDetail.ReportedUserId
                                ).Name
                            }
                        </strong>
                        {props.t("will be notified of your action")}
                    </>
                );
                setReportAction({ ReportId: reportAction.ReportId });
            })
            .catch((err) => {
                setIsLoadingModal(false);
                notificationToastr.error(err.Message);
            });
    };
    return (
        <>
            <LayoutWrapper>
                <div className="report-detail-container">
                    {isFetchingData && (
                        <Row>
                            <Col xs={12} sm={6}>
                                <SkeletonLoader rowCount={5} />
                            </Col>
                        </Row>
                    )}
                    {!isFetchingData && (
                        <>
                            {reportDetail ? (
                                <>
                                    <BasicModal
                                        isOpen={resolveReport}
                                        backClick={() => {
                                            setResolveReport(false);
                                        }}
                                        title={"Are you sure you want to resolve the report?"}
                                        handleConfirm={onConfirmResolveModal}
                                        handleCancel={onCancelResolveModal}
                                        modalType={modalConstant.SuspendConfirmationModal}
                                    />
                                    <BasicModal
                                        isOpen={userModal}
                                        title={getUserModalTitle()}
                                        backClick={() => {
                                            setUserModal(false);
                                        }}
                                        userList={
                                            reportDetail.Participants &&
                                            reportDetail.Participants?.filter(
                                                (item) => item.Type === Roles.USER
                                            ).map((item) => {
                                                return {
                                                    image: item?.MediaPath,
                                                    id: item.UserId,
                                                    username: item.Name,
                                                    icon: true,
                                                    onClick: onUserModalClick
                                                };
                                            })
                                        }
                                        modalType={modalConstant.UserSelectionModal}
                                    />

                                    <BasicModal
                                        isOpen={warningModal}
                                        backClick={() => {
                                            setUserModal(true);
                                            setWarningModal(false);
                                        }}
                                        onClick={onWarningModalSubmit}
                                        modalType={modalConstant.WarningModal}
                                        radioOption={remarksOption}
                                    />
                                    <BasicModal
                                        isOpen={removeModal}
                                        backClick={() => {
                                            setUserModal(true);
                                            setRemoveModal(false);
                                        }}
                                        onClick={onRemoveModalSubmit}
                                        modalType={modalConstant.WarningModal}
                                        radioOption={remarksOption}
                                    />
                                    <BasicModal
                                        containerClasses="msg-violation-modal-max-width"
                                        isOpen={messageModal.isOpen}
                                        onClick={onMessageModalSubmit}
                                        modalType={modalConstant.MessageModal}
                                        content={messageModal.message}
                                        isModalHeader={false}
                                    />
                                    <BasicModal
                                        isOpen={suspendModal}
                                        backClick={() => {
                                            setUserModal(true);
                                            setSuspendModal(false);
                                        }}
                                        onClick={onSuspendModalSubmit}
                                        modalType={"modal-type-4"}
                                        remarksOptionArray={remarksOption}
                                        daysOptionArray={daysOption}
                                    />
                                    <BasicModal
                                        isOpen={confirmSuspendModal}
                                        backClick={() => {
                                            setConfirmSuspendModal(false);
                                            setSuspendModal(true);
                                        }}
                                        title={`Are you sure ${
                                            reportDetail &&
                                            reportDetail.Participants.filter(
                                                (item) => item.UserId === reportAction?.ReportedId
                                            )[0]?.Name
                                        } will put their chats, connections and profile on hold for ${
                                            reportAction?.SuspendedDays
                                        } days`}
                                        handleConfirm={handleConfirmSuspendModal}
                                        handleCancel={handleCancelSuspendModal}
                                        modalType={modalConstant.SuspendConfirmationModal}
                                    />
                                    <BasicModal
                                        isOpen={removeConfirmationModal}
                                        backClick={() => {
                                            setRemoveModal(true);
                                            setRemoveConfirmationModal(false);
                                        }}
                                        modalType={modalConstant.RemovalModal}
                                        onClick={onSubmitRemoveConfirmationModal}
                                        reportAction={reportAction}
                                    />
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                            <DetailsTile
                                                class="mb-5"
                                                isLoading={isLoadingModal}
                                                heading={getCauseType(reportDetail.Cause)}
                                                btnIcon={
                                                    reportDetail.Status !== ReportStatus.RESOLVED &&
                                                    reportDetail.Status !==
                                                        ReportStatus.APPEAL_RESOLVED ? (
                                                        <i className="dripicons-chevron-right back-icon"></i>
                                                    ) : null
                                                }
                                                btnMenus={getActionMenu()}
                                                btnClases="reduce-padding p px-3"
                                                btnText={
                                                    reportDetail.Status === ReportStatus.RESOLVED ||
                                                    reportDetail.Status ===
                                                        ReportStatus.APPEAL_RESOLVED
                                                        ? null
                                                        : reportDetailConstant.Action
                                                }
                                                btnClick={
                                                    reportDetail.Status === ReportStatus.RESOLVED ||
                                                    reportDetail.Status ===
                                                        ReportStatus.APPEAL_RESOLVED
                                                        ? () => {
                                                              //TODO
                                                          }
                                                        : null
                                                }
                                                listing={[
                                                    {
                                                        name: "Reported by:",
                                                        value: reportDetail.ReportedBy?.Name
                                                    },
                                                    {
                                                        name: "Date:",
                                                        value: convertDateTime({
                                                            date: reportDetail.Date,
                                                            dateOnly: true,
                                                            customFormat: "DD-MM-YYYY"
                                                        })
                                                    },
                                                    {
                                                        name: `${props.t("Status")}:`,
                                                        value: getReportStatus(reportDetail.Status)
                                                            .text
                                                    }
                                                ]}
                                            />
                                            <HeadingText
                                                containerClass="mb-4"
                                                title={`${props.t("About the incident")}`}
                                                description={reportDetail.About}
                                            />
                                            <CustomCard>
                                                <h1 className="mb-4 color-black">
                                                    {props.t("Matchmaker’ s remarks")}
                                                </h1>
                                                <p className="color-black">
                                                    {reportDetail.MatchMakerRemarks
                                                        ? reportDetail.MatchMakerRemarks
                                                        : "No remarks"}
                                                </p>
                                            </CustomCard>
                                            {(reportDetail.Status === ReportStatus.UNDER_APPEAL ||
                                                reportDetail.Status ===
                                                    ReportStatus.APPEAL_REQUESTED ||
                                                reportDetail.Status ===
                                                    ReportStatus.APPEAL_RESOLVED) && (
                                                <div className="mb-4">{chat}</div>
                                            )}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pl-5-only-xl">
                                            {reportDetail.Status === ReportStatus.UNDER_APPEAL ||
                                            reportDetail.Status === ReportStatus.APPEAL_REQUESTED ||
                                            reportDetail.Status === ReportStatus.APPEAL_RESOLVED ? (
                                                <>
                                                    <DetailsTile
                                                        containerClass="mb-5"
                                                        heading={props.t("Appeal Report")}
                                                        fullWidth
                                                        listing={[
                                                            {
                                                                name: props.t("Requested by"),
                                                                value: reportDetail.Appeal?.AppealBy
                                                            },
                                                            {
                                                                name: props.t("Appeal Date"),
                                                                value: convertDateTime({
                                                                    date: reportDetail.Appeal?.Date,
                                                                    dateOnly: true,
                                                                    customFormat: "DD-MM-YYYY"
                                                                })
                                                            },
                                                            {
                                                                name: props.t("Appealing Reason"),
                                                                value: getAppealCause(
                                                                    reportDetail.Appeal?.Reason
                                                                )
                                                            }
                                                        ]}
                                                    />
                                                    <div className="mt-3 mb-5">
                                                        <CustomCard>
                                                            <h1 className="mb-4 color-black">
                                                                {props.t("User Remarks")}
                                                            </h1>
                                                            <p className="color-black">
                                                                {reportDetail.Appeal?.Remarks
                                                                    ? reportDetail.Appeal?.Remarks
                                                                    : "No remarks"}
                                                            </p>
                                                        </CustomCard>
                                                        <div className="mt-5">{messageButton}</div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="mb-4">
                                                    {chat}
                                                    {messageButton}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <ErrorPage reason={props.t("Report Not Found")} />
                            )}
                        </>
                    )}
                </div>
            </LayoutWrapper>
        </>
    );
};

export default withNamespaces()(ReportDetail);
