import React from "react";
import { withNamespaces } from "react-i18next";
import { CardBody, CardHeader, Collapse } from "reactstrap";

import "./Accordion.scss";

const Accordion = ({ onCollapse = (e) => { }, containerClass, isOpen = true, headTitle = "Personal", children, cardClass }) => {
    return (
        <div className={`hc-accordion-container ${containerClass}`}>
            <div className="mb-1">
                <CardHeader className="hc-accordion-header" onClick={onCollapse}>
                    <h6 className="m-0 d-flex flex-row justify-content-between">
                        <span className="body-text-5">{headTitle}</span>
                        <span>
                            {
                                isOpen ? <i class="bx bx-chevron-up chevron-up-custom"></i> : <i class="bx bx-chevron-down chevron-up-custom"></i>
                            }
                        </span>
                    </h6>
                </CardHeader>

                <Collapse className="hc-collapse-margin-bottom" isOpen={isOpen}>
                    <div className={`hc-accordion-body ${cardClass}`}>
                        <CardBody>{children}</CardBody>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

export default withNamespaces()(Accordion);
