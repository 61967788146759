import React from "react";
import { withNamespaces } from "react-i18next";

import "./BasicModal.scss";
import BlockButton from "../Button/Button";

const MessageModal = (props) => {
    const doneIcon = require("../../assets/images/done-lg.svg");
    return (
        <div className="hc-message-modal">
            <div className="message-modal-content-wrapper d-flex align-items-center">
                <span>
                    <img src={doneIcon} alt="done-icon" />
                </span>
                <div className="message-modal-content">
                    <h2 className="color-secondary-purple">{props.content}</h2>
                </div>
            </div>
            <BlockButton
                className="button-type-1 btn btn-lg w-100 text-uppercase"
                onClick={props.onClick}
            >
                ok
            </BlockButton>
        </div>
    );
};

export default withNamespaces()(MessageModal);
