import React, { useEffect, useState, Fragment, useMemo } from "react";
import { Card } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import uuidv4 from "uuid/v4";

import ChatMessageInput from "./ChatMessageInput";
import ChatMessageHeader from "./ChatMessageHeader";
import {
    getMessageListing,
    getChatById,
    clearCurrentChat,
    setCurrentChat,
    updateChatUnreadCount,
    getChatUnReadCount,
    updateChatMessageId,
    addMessage,
    updateChatMessageData,
    moveChatToTop,
    updateChatUnReadMsg
} from "../../store/actions";
import { Loader } from "../../components/Loader";
import ChatMessageList from "./ChatMessageList";
import { scrollToBottom } from "../../utils/domUtils";
import { Socket } from "../../utils/socketUtils";
import { escapeHTMLElementsFromMessage } from "../../utils/commonUtils";

import { Roles } from "../../constants";
import ChatNoDataContainer from "./ChatNoDataContainer";

const ChatMessageSection = (props) => {
    const {
        currentChatData,
        fetching,
        dataAvailable,
        messages,
        currentUser,
        chatTab,
        onHamburgerClick,
        isSidebarActive,
        onBackdropClick,
        previousChatInput = true
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [emptyChat, setEmptyChat] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch();

    useMemo(() => {
        setEmptyChat(true);
    }, [chatTab]);

    useEffect(() => {
        setEmptyChat(true);
        if (parseInt(id)) {
            setEmptyChat(false);
            Socket.joinChat(id);
            dispatch(getMessageListing(id, { Page: page }))
                .then((res) => {
                    dispatch(updateChatUnReadMsg(id, null, true));
                    Socket.readMessage(Number(id));
                    dispatch(getChatUnReadCount());
                    setTimeout(() => {
                        scrollToBottom();
                    }, 200);
                })
                .catch((err) => {});
        }
        return () => {
            setPage(1);
            dispatch(clearCurrentChat());
        };
    }, [id, dispatch, chatTab]);

    useEffect(() => {
        if (!currentChatData) {
            setIsLoading(true);
            dispatch(getChatById(id)).finally(() => {
                setIsLoading(false);
            });
        } else {
            dispatch(setCurrentChat(parseInt(id), currentChatData?.Customer?.Id));
        }
    }, [id, currentChatData, dispatch]);

    const handleScroll = (e) => {
        const isTop = e.target.scrollTop < 30;

        if (isTop && !fetching && dataAvailable) {
            let container = document.getElementById("chatbox-scroll-div");
            let prevScrollTop = container.scrollTop;
            let prevScrollHeight = container.scrollHeight;
            let currentPage = page + 1;
            dispatch(getMessageListing(id, { Page: currentPage })).then((res) => {
                container.scrollTop = container.scrollHeight - (prevScrollHeight - prevScrollTop);
            });
            setPage(currentPage);
        }
    };

    const handleMessageSent = (content) => {
        const uniqueId = uuidv4();
        const escapedMessage = escapeHTMLElementsFromMessage(content);

        const socketMsgData = {
            ChatId: currentChatData.ChatId,
            Content: escapedMessage
        };

        const addMsgData = {
            Id: uniqueId,
            CreatedAt: Date.now(),
            UpdatedAt: Date.now(),
            ChatId: currentChatData.ChatId,
            SenderId: currentUser.Id,
            SenderType: Roles.MATCH_MAKER,
            Content: escapedMessage,
            MediaId: null,
            Media: currentUser?.MediaUrl,
            Name: currentUser.FullName,
            SendDate: Date.now()
        };

        Socket.sendMessage(socketMsgData, async (data) => {
            dispatch(updateChatMessageId(uniqueId, data?.ChatEvent?.Id));
            await dispatch(updateChatMessageData(currentChatData.ChatId, data.ChatEvent));
            dispatch(moveChatToTop(currentChatData.ChatId));
            dispatch(getChatUnReadCount());
        });

        Socket.readMessage(currentChatData.ChatId);

        dispatch(updateChatUnReadMsg(currentChatData.ChatId, addMsgData, true));
        dispatch(addMessage(addMsgData));
    };

    return (
        <div className="hc-chat-content position-relative w-100">
            {emptyChat ? (
                <ChatNoDataContainer />
            ) : (
                <Fragment>
                    {!isLoading && currentChatData ? (
                        <>
                            <ChatMessageHeader
                                onHamburgerClick={onHamburgerClick}
                                name={currentChatData?.Users || "-"}
                                showInfoIcon={previousChatInput}
                                chatTab={chatTab}
                                chatId={id}
                                users={currentChatData?.Users}
                            />
                            <div className="chat-message-content d-flex flex-column justify-content-between">
                                <div
                                    className={`hc-backdrop-inner ${
                                        isSidebarActive ? "active" : ""
                                    }`}
                                    onClick={onBackdropClick}
                                ></div>
                                <div className="chat-conversation pt-3 pb-0 px-3 h-100 overflow-hidden">
                                    <ul
                                        className={`list-unstyled h-100 mb-0 ${
                                            !fetching && !messages.length ? "overflow-hidden" : ""
                                        }`}
                                    >
                                        {fetching && (
                                            <Loader
                                                classes={!messages.length ? "h-100" : ""}
                                                {...(messages.length && { size: "sm" })}
                                            />
                                        )}
                                        {!fetching && !messages.length && (
                                            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                <p className="text-muted text-center">
                                                    {props.t("No Chat Messages")}
                                                </p>
                                            </div>
                                        )}
                                        <div
                                            id="chatbox-scroll-div"
                                            className="h-100 fancy-scroll overflow-auto"
                                            onScroll={handleScroll}
                                        >
                                            {!!messages.length && (
                                                <ChatMessageList
                                                    messages={messages}
                                                    currentChatData={currentChatData}
                                                />
                                            )}
                                        </div>
                                    </ul>
                                </div>
                                {previousChatInput && (
                                    <ChatMessageInput onMessageSent={handleMessageSent} />
                                )}
                            </div>
                        </>
                    ) : isLoading ? (
                        <Loader classes="h-100" />
                    ) : null}
                </Fragment>
            )}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const currentChatData = state.entities.chats[ownProps.match.params.id];
    return {
        currentChatData,
        fetching: state.chat.currentChat.fetching,
        messages: state.chat.currentChat.messages,
        dataAvailable: state.chat.currentChat.dataAvailable,
        currentUser: state.auth.user
    };
};

export default withNamespaces()(connect(mapStateToProps, null)(ChatMessageSection));
