import React from "react";
import "./ChatHistoryMessage.scss";
import { convertDateTime } from "../../utils/dateFormatUtil";
import { Loader } from "../../components/Loader";
import { ChatMessageType, GenderType } from "../../constants";
import Chat from "../../pages/Chat/Chat";

const ChatHistoryMessage = ({
    historyMessages,
    outerUsername = false,
    onScroll = (e) => {},
    isLoading = false
}) => {
    const renderMessageAction = (type, sender) => {
        switch (type) {
            case ChatMessageType.GuardianAdded:
                return (
                    <p className="body-text-2 color-black text-center">
                        {sender?.Name} added{" "}
                        {sender?.Gender === GenderType.Male
                            ? "his"
                            : sender?.Gender === GenderType.Female
                            ? "her"
                            : "their"}{" "}
                        Guardian
                    </p>
                );

            case ChatMessageType.GuardianRemoved:
                return (
                    <p className="body-text-2 color-black text-center">
                        {sender?.Name} removed{" "}
                        {sender?.Gender === GenderType.Male
                            ? "his"
                            : sender?.Gender === GenderType.Female
                            ? "her"
                            : "their"}{" "}
                        Guardian
                    </p>
                );

            case ChatMessageType.ChatAccepted:
                return (
                    <p className="body-text-2 color-black text-center">
                        Chat Request Accepted By {sender?.Name}
                    </p>
                );

            default:
                return null;
        }
    };

    return (
        <div className="chat-history-message-container" id="chatbox-scroll-div" onScroll={onScroll}>
            {isLoading && (
                <Loader
                    classes={!historyMessages.length ? "h-100" : ""}
                    {...(historyMessages.length && { size: "sm" })}
                />
            )}
            {historyMessages.map((singleHistory, key) => {
                return (
                    <div key={key}>
                        <span>
                            <p className="body-text-2 color-black text-center">
                                {singleHistory.days && singleHistory.days}
                            </p>
                            {renderMessageAction(singleHistory.type, singleHistory?.sender)}
                        </span>
                        {singleHistory.send ? (
                            <>
                                {outerUsername && (
                                    <p className="body-text-2 color-black username2 pr-2 text-capitalize">
                                        {singleHistory.userName}
                                    </p>
                                )}

                                <div className="d-flex flex-row-reverse mb-4 send-box-container">
                                    <div className="send-img-icon">
                                        {singleHistory.date ? (
                                            <img
                                                src={singleHistory.userImg}
                                                className="chat-msg-icon"
                                            />
                                        ) : (
                                            <div className="chat-msg-icon">&nbsp;</div>
                                        )}
                                    </div>
                                    <div className="w-100 send-text-box">
                                        <div className="chat-box ">
                                            <div className="pr-3">
                                                {singleHistory.date && (
                                                    <p className="body-text-2 color-black text-right no-margin">
                                                        {convertDateTime({
                                                            date: singleHistory.date,
                                                            customFormat: "hh:mm a"
                                                        })}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="chat-box-bgcolor chat-box-send">
                                                {!outerUsername && (
                                                    <p className="body-text-2 username text-capitalize">
                                                        {singleHistory.userName}
                                                    </p>
                                                )}
                                                <p className="body-text-1">
                                                    {singleHistory.message}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {singleHistory.message && (
                                    <>
                                        {outerUsername && (
                                            <p className="body-text-2 color-black username3 text-capitalize">
                                                {singleHistory.userName}
                                            </p>
                                        )}

                                        <div className="d-flex flex-row mb-4">
                                            <div>
                                                <img
                                                    src={singleHistory.userImg}
                                                    className="chat-msg-icon"
                                                />
                                            </div>

                                            <div className="w-100">
                                                <div className="chat-box">
                                                    <div className="pl-3">
                                                        {singleHistory.date && (
                                                            <p className="body-text-2 color-black text-left no-margin">
                                                                {convertDateTime({
                                                                    date: singleHistory.date,
                                                                    customFormat: "hh:mm a"
                                                                })}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="chat-box-bgcolor">
                                                        {!outerUsername && (
                                                            <p className="body-text-2 username text-capitalize">
                                                                {singleHistory.userName}
                                                            </p>
                                                        )}
                                                        <p className="body-text-1">
                                                            {singleHistory.message}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="ml-5">
                                    {singleHistory?.attachments &&
                                        singleHistory.attachments.map((item) => {
                                            return <img className="chat-img " src={item.Path} />;
                                        })}
                                </div>
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default ChatHistoryMessage;
