import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";
import Config from "../../Config";
import { ErrorPage } from "../../components/Utility";

export const getReportDetail = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        url: `/report/details/${data}`,
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const createReport = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        body: JSON.stringify(data),
        method: "POST",
        url: `/report/create`,
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const sendReportAction = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        body: JSON.stringify(data),
        method: "POST",
        url: `/report/action`,
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const sendAppealAction = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        body: JSON.stringify(data),
        method: "POST",
        url: `/report/appeal/action`,
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const getMessageListing = (id, params = {}, abortSignal = null) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: appendQueryParams(`/chat/messages/${id}`, {
            Limit: Config.LIMIT,
            Column: "CreatedAt",
            Direction: "DESC",
            ...params
        }),
        token,
        abortSignal
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
