import React, { Component, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { withNamespaces } from "react-i18next";
import ReactReadMoreReadLess from "react-read-more-less";
import Accordion from "../../components/Accordion/Accordion";
import ManualMatchFilters from "../UserDetail/ManualMatchFilters";

import { SkeletonLoader } from "../../components/Loader";
import {
    getCurrentLivingStatus,
    getEducationLevel,
    getHalalPreference,
    getMaritalStatus,
    getPrayerRoutine,
    getSect,
    getWorkingSituation
} from "../../common/common";
import { useHistory } from "react-router-dom";
import BasicModal from "../../components/Modal/BasicModal";
import modalConstant from "../../constants/ModalConstant";
import { ErrorPage } from "../../components/Utility";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import Pill from "../../components/Pill/Pill";
import { userDetails } from "../../store/actions/userDetailsAction";
import {
    getUsersDetail,
    sendReferral,
    findAnotherMatch
} from "../../store/actions/compareProfileActions";
import { convertInches } from "../../utils/commonUtils";
import { useDispatch, useSelector } from "react-redux";
import notificationToastr from "../../utils/toastUtil";
import BlockButton from "../../components/Button/Button";
import HorizontalTabs from "../../components/HorizontalTabs/HorizontalTabs";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import FemaleImage from "../../assets/images/Profile.png";
import MaleImage from "../../assets/images/realuser_lg.png";
import AccordionInnerListing from "./AccordionInnerListing";
import { ChatType } from "../../constants";
import { Socket, SocketTypes } from "../../utils/socketUtils";
import "./CompareProfile.scss";

const CompareProfile = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const id = props.location?.state?.userId;
    const selectedMatchId = props?.location?.state?.selectedMatchId;
    const [users, setUsers] = useState(
        props.location?.state?.users?.map((item) => {
            return item.UserId;
        })
    );

    const [matches, setMatches] = useState(props.location?.state?.users);

    const filters = props.location?.state?.filters;

    const [isManualModal, setIsManualModal] = useState(false);
    const [userDetail, setUserDetail] = useState({});
    const [usersDetail, setUsersDetail] = useState([]);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isFetchingUsersDetails, setIsFetchingUsersDetails] = useState(false);
    const [openTab, setOpenTab] = useState(null);
    const [suggestionOpenTab, setSuggestionOpenTab] = useState(null);
    const [selectedUserDetails, setSelectedUserDetails] = useState({});
    const [sendReferralModal, setSendReferralModal] = useState(false);
    const [isLoadingSendRef, setIsLoadingSendRef] = useState(false);
    const [sendReferralSuccess, setSendReferralSuccess] = useState(false);
    const [isLoadingFindAnotherMatch, setIsLoadingFindAnotherMatch] = useState(false);
    const [sendRefferalPayload, setSendRefferalPayload] = useState({});
    const tabs = {
        Personal: 1,
        Professional: 2,
        Religious: 3,
        PersonalityTraits: 4,
        Preferences: 5
    };
    useEffect(() => {
        setIsFetchingData(true);

        dispatch(userDetails({ UserId: id }))
            .then((res) => {
                setUserDetail(res.Data);
                setIsFetchingData(false);
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
                setIsFetchingData(false);
            });
    }, []);
    useEffect(() => {
        getUsersDetails();

        return () => {
            Socket.remove(SocketTypes.CHAT_START, handleChatStarted);
        };
    }, [users]);

    const handleChatStarted = (data) => {
        history.push(getRoute(APP_ROUTES.CHAT_LISTING, { id: parseInt(data.ChatId) }), {
            ChatType: ChatType.Individual
        });
    };

    const getUsersDetails = () => {
        let matchToFind;

        setIsFetchingUsersDetails(true);
        if (users.length > 0) {
            dispatch(getUsersDetail({ UsersIds: users }))
                .then((res) => {
                    setUsersDetail(res.Data.Users);
                    matchToFind = res.Data.Users.find((match) => match.UserId === selectedMatchId);

                    if (matchToFind) {
                        setSelectedUserDetails(matchToFind);
                    } else {
                        setSelectedUserDetails(res.Data.Users[0]);
                    }

                    setIsFetchingUsersDetails(false);
                })
                .catch((err) => {
                    notificationToastr.error(err.Message);
                    setIsFetchingUsersDetails(false);
                });
        } else {
            setUsersDetail([]);
            setSelectedUserDetails({});
            setIsFetchingUsersDetails(false);
        }
    };
    const handleFindAnotherMatch = (selectedId) => {
        setIsLoadingFindAnotherMatch(true);
        dispatch(
            findAnotherMatch({
                UserId: id,
                SubstitutedUserId: selectedId,
                Filters: filters,
                OtherUserIds: users,
                MatchPercentage: matches?.find((item) => item.UserId === selectedId)
                    ?.MatchPercentage
            })
        )
            .then((res) => {
                setNewUsersDetails(res, selectedId);
                setIsLoadingFindAnotherMatch(false);
            })
            .catch((err) => {
                setIsLoadingFindAnotherMatch(false);
                notificationToastr.error(err.Message);
            });
    };
    const setNewUsersDetails = (res, selectedId) => {
        let newUsersDetails = matches.filter((item) => item.UserId !== selectedId);
        if (res.Data) {
            newUsersDetails.push(res.Data);
        }
        setUsers(
            newUsersDetails.map((item) => {
                return item.UserId;
            })
        );
        setMatches(newUsersDetails);
    };
    const tabConst = () => {
        return (
            Object.keys(selectedUserDetails).length > 0 && (
                <>
                    <h1 className="text-center color-secondary mt-5">
                        About {selectedUserDetails.Name}
                    </h1>
                    <p className="text-center mb-5 color-secondary-pink mt-3">
                        {
                            matches?.find((item) => item.UserId === selectedUserDetails.UserId)
                                ?.MatchPercentage
                        }
                        % Match
                    </p>
                    <div className="d-flex justify-content-space-evenly mb-4">
                        <div>
                            <BlockButton
                                className="button-type-5 btn-md"
                                onClick={() => {
                                    handleClickSendRef({
                                        UserId: parseInt(id),
                                        ReferenceId: selectedUserDetails.UserId,
                                        MatchPercentage: matches?.find(
                                            (item) => item.UserId === selectedUserDetails.UserId
                                        )?.MatchPercentage,
                                        Filters: filters,
                                        OtherUserIds: users,
                                        Name: selectedUserDetails.Name
                                    });
                                }}
                            >
                                Send Referral
                            </BlockButton>
                        </div>
                        <div>
                            <BlockButton
                                className="button-type-5 btn-md"
                                onClick={() => {
                                    handleFindAnotherMatch(selectedUserDetails.UserId);
                                }}
                                isLoading={isLoadingFindAnotherMatch}
                            >
                                Find another Match
                            </BlockButton>
                        </div>
                    </div>
                    <p className="my-5 color-black px-2">
                        <ReactReadMoreReadLess
                            charLimit={200}
                            readMoreText={"Read more "}
                            readLessText={"Read less "}
                        >
                            {selectedUserDetails.About}
                        </ReactReadMoreReadLess>
                    </p>
                    <Accordion
                        isOpen={suggestionOpenTab === tabs.Personal}
                        onCollapse={(e) => {
                            suggestionOpenTab === tabs.Personal
                                ? setSuggestionOpenTab(null)
                                : setSuggestionOpenTab(tabs.Personal);
                        }}
                        headTitle="Personal"
                        containerClass="my-3"
                        cardClass="hc-accordion-body-no-shadow reduce-card-padding"
                    >
                        <Row>
                            <Col xs={12} sm={12}>
                                <AccordionInnerListing
                                    contents={[
                                        {
                                            title: "Nationality",
                                            content: selectedUserDetails.EthinicOrigin
                                        },
                                        {
                                            title: "Age",
                                            content: selectedUserDetails.Age
                                        },
                                        {
                                            title: "Height",
                                            content: convertInches(
                                                getSelectedUserPersonality("Height")
                                            )
                                        },
                                        {
                                            title: "Current location",
                                            content: `${
                                                getSelectedUserPersonality("Location")?.City
                                            }, ${getSelectedUserPersonality("Location")?.Country}`
                                        },
                                        {
                                            title: "Languages",
                                            content: getSelectedUserPersonality("Languages").join(
                                                ", "
                                            )
                                        },
                                        {
                                            title: "Marital Status",
                                            content: getMaritalStatus(
                                                getSelectedUserPersonality("MaritalStatus")
                                            )
                                        },
                                        {
                                            title: "Children",
                                            content: getSelectedUserPersonality("Children")
                                        },
                                        {
                                            title: "Current living situation",
                                            content: getCurrentLivingStatus(
                                                getSelectedUserPersonality("CurrentLivingSituation")
                                            )
                                        }
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Accordion>
                    <Accordion
                        isOpen={suggestionOpenTab === tabs.Professional}
                        onCollapse={(e) => {
                            suggestionOpenTab === tabs.Professional
                                ? setSuggestionOpenTab(null)
                                : setSuggestionOpenTab(tabs.Professional);
                        }}
                        headTitle="Professional"
                        containerClass="my-3"
                        cardClass="hc-accordion-body-no-shadow reduce-card-padding"
                    >
                        <Row>
                            <Col xs={12} sm={12}>
                                <AccordionInnerListing
                                    contents={[
                                        {
                                            title: "Occupation",
                                            content: getSelectedUserProfessional("Occupation")
                                        },
                                        {
                                            title: "Level of Education",
                                            content: getEducationLevel(
                                                getSelectedUserProfessional("EducationLevel")
                                            )
                                        },
                                        {
                                            title: "Working situation",
                                            content: getWorkingSituation(
                                                getSelectedUserProfessional("WorkingSituation")
                                            )
                                        }
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Accordion>
                    <Accordion
                        isOpen={suggestionOpenTab === tabs.Religious}
                        onCollapse={(e) => {
                            suggestionOpenTab === tabs.Religious
                                ? setSuggestionOpenTab(null)
                                : setSuggestionOpenTab(tabs.Religious);
                        }}
                        headTitle="Religious"
                        containerClass="my-3"
                        cardClass="hc-accordion-body-no-shadow reduce-card-padding"
                    >
                        <Row>
                            <Col xs={12} sm={6}>
                                <AccordionInnerListing
                                    contents={[
                                        {
                                            title: "Sect",
                                            content: getSect(
                                                getSelectedUserReligiousDetails("Sect")
                                            )
                                        },
                                        {
                                            title: "Prayers Routine",
                                            content: getPrayerRoutine(
                                                getSelectedUserReligiousDetails("PrayerRoutine")
                                            )
                                        },
                                        {
                                            title: "Halal Preferences",
                                            content: getHalalPreference(
                                                getSelectedUserReligiousDetails("HalalPreference")
                                            )
                                        }
                                    ]}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <AccordionInnerListing
                                    contents={[
                                        {
                                            title: "Alcohol Consumption",
                                            content: getSelectedUserReligiousDetails(
                                                "AlcoholConsumption"
                                            )
                                                ? "Yes"
                                                : "No"
                                        },
                                        {
                                            title: "Smoking",
                                            content: getSelectedUserReligiousDetails("Smoking")
                                                ? "Yes"
                                                : "No"
                                        }
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Accordion>
                    <Accordion
                        isOpen={suggestionOpenTab === tabs.PersonalityTraits}
                        onCollapse={(e) => {
                            suggestionOpenTab === tabs.PersonalityTraits
                                ? setSuggestionOpenTab(null)
                                : setSuggestionOpenTab(tabs.PersonalityTraits);
                        }}
                        headTitle="Personality Traits"
                        containerClass="my-3"
                        cardClass="hc-accordion-body-no-shadow reduce-card-padding"
                    >
                        <Row>
                            <Col xs={6} sm={6}>
                                <AccordionInnerListing
                                    pills={
                                        selectedUserDetails.Traits
                                            ? selectedUserDetails.Traits.map((trait) => {
                                                  return { name: trait };
                                              })
                                            : null
                                    }
                                />
                            </Col>
                        </Row>
                    </Accordion>
                    <Accordion
                        isOpen={suggestionOpenTab === tabs.Preferences}
                        onCollapse={(e) => {
                            suggestionOpenTab === tabs.Preferences
                                ? setSuggestionOpenTab(null)
                                : setSuggestionOpenTab(tabs.Preferences);
                        }}
                        headTitle="Preferences"
                        containerClass="my-3 mb-5"
                        cardClass="hc-accordion-body-no-shadow reduce-card-padding"
                    >
                        <Row>
                            <Col xs={12} sm={12}>
                                <div className="mb-2">
                                    <AccordionInnerListing
                                        heading="PERSONAL"
                                        titleClasses=""
                                        contents={[
                                            {
                                                title: "Age Range",
                                                content: `${
                                                    getSelectedUserPreferences("Personal")?.AgeStart
                                                } - ${
                                                    getSelectedUserPreferences("Personal")?.AgeStart
                                                }`
                                            },
                                            {
                                                title: "Height Range",
                                                content: `${convertInches(
                                                    getSelectedUserPreferences("Personal")
                                                        ?.HeightStart
                                                )} - ${convertInches(
                                                    getSelectedUserPreferences("Personal")
                                                        ?.HeightStart
                                                )}`
                                            },
                                            {
                                                title: "Previous Marital Status",
                                                content: getSelectedUserPreferences("Personal")
                                                    ?.PreviousMaritalStatus
                                                    ? "Married"
                                                    : "Not Married"
                                            },
                                            {
                                                title: "Family Plans",
                                                content: getSelectedUserPreferences("Personal")
                                                    ?.FamilyPlans
                                                    ? "Open to children"
                                                    : "Not open to children"
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="mb-2 mt-1">
                                    <AccordionInnerListing
                                        heading="Professional"
                                        contents={[
                                            {
                                                title: "Work Preferences",
                                                content: `${
                                                    getSelectedUserPreferences("Professional")
                                                        ?.Working
                                                        ? "Working"
                                                        : "Not working"
                                                } ${
                                                    getSelectedUserPreferences("Professional")
                                                        ?.StayAtHome
                                                        ? "(Staying at home)"
                                                        : ""
                                                }`
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="mb-2 mt-1">
                                    <AccordionInnerListing
                                        heading="Religion"
                                        titleClasses=""
                                        contents={[
                                            {
                                                title: "Sect",
                                                content: getSelectedUserPreferences("Religious")
                                                    ?.Sect
                                                    ? "Open to different sect"
                                                    : getSect(
                                                          selectedUserDetails.ReligiousDetails?.Sect
                                                      )
                                            },
                                            {
                                                title: "Prayers Routine",
                                                content: getSelectedUserPreferences("Religious")
                                                    ?.PrayerRoutine
                                                    ? "Same"
                                                    : ""
                                            },
                                            {
                                                title: "Halal Preferences",
                                                content: getHalalPreference(
                                                    getSelectedUserPreferences("Religious")
                                                        ?.HalalPreference
                                                )
                                            },
                                            {
                                                title: "Alcohol Consumption",
                                                content: getSelectedUserPreferences("Religious")
                                                    ?.AlcoholConsumption
                                                    ? "Yes"
                                                    : "No"
                                            },
                                            {
                                                title: "Smoking",
                                                content: getSelectedUserPreferences("Religious")
                                                    ?.Smoking
                                                    ? "Yes"
                                                    : "No"
                                            }
                                        ]}
                                    />
                                </div>

                                <Row>
                                    <Col xs={12} sm={4}>
                                        <AccordionInnerListing
                                            heading="Personality"
                                            contents={[
                                                {
                                                    title: "Values"
                                                }
                                            ]}
                                            pills={
                                                getSelectedUserPreferences("Personality")?.Values
                                                    ? getSelectedUserPreferences(
                                                          "Personality"
                                                      ).Values.map((value) => {
                                                          return { name: value };
                                                      })
                                                    : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <AccordionInnerListing
                                            heading=""
                                            contents={[
                                                {
                                                    title: "Traits"
                                                }
                                            ]}
                                            pills={
                                                getSelectedUserPreferences("Personality")?.Traits
                                                    ? getSelectedUserPreferences(
                                                          "Personality"
                                                      ).Traits.map((value) => {
                                                          return { name: value };
                                                      })
                                                    : null
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Accordion>
                </>
            )
        );
    };

    const handleSelectTab = (item) => {
        setSelectedUserDetails(usersDetail.find((data) => data.UserId === item.UserId));
        setSuggestionOpenTab(null);
    };
    const handleSendReferral = () => {
        setIsLoadingSendRef(true);
        dispatch(sendReferral(sendRefferalPayload))
            .then((res) => {
                setNewUsersDetails(res, sendRefferalPayload.ReferenceId);
                setSendReferralSuccess(true);
                setSendReferralModal(false);
                setIsLoadingSendRef(false);
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
                setSendReferralModal(false);
                setIsLoadingSendRef(false);
            });
    };
    const handleCancelSendReferral = () => {
        setSendReferralModal(false);
        setSendRefferalPayload({});
    };
    const handleClickSendRef = (data) => {
        setSendRefferalPayload(data);
        setSendReferralModal(true);
    };
    const onOkSendReferral = () => {
        setSendReferralSuccess(false);
        setSendRefferalPayload({});
    };
    const getUserPersonality = (key) => {
        return userDetail?.PersonalDetails[key];
    };
    const getUserProfessional = (key) => {
        return userDetail?.ProfessionalDetials[key];
    };
    const getUserReligiousDetails = (key) => {
        return userDetail?.ReligiousDetails[key];
    };
    const getUserPreferences = (key) => {
        return userDetail.Preferences[key];
    };
    const getSelectedUserPersonality = (key) => {
        return selectedUserDetails?.PersonalDetails[key];
    };
    const getSelectedUserProfessional = (key) => {
        return selectedUserDetails?.ProfessionalDetials[key];
    };
    const getSelectedUserReligiousDetails = (key) => {
        return selectedUserDetails?.ReligiousDetails[key];
    };
    const getSelectedUserPreferences = (key) => {
        return selectedUserDetails?.Preferences[key];
    };

    const getHeightRange = () => {
        if (
            userDetail?.Preferences?.Personal?.HeightStart &&
            userDetail?.Preferences?.Personal?.HeightEnd
        ) {
            let heightStart = convertInches(userDetail.Preferences.Personal.HeightStart);
            let heightEnd = convertInches(userDetail.Preferences.Personal.HeightEnd);

            return `${heightStart} - ${heightEnd}`;
        }

        return "-";
    };

    return (
        <>
            {isFetchingData && (
                <LayoutWrapper breadCrumb={false}>
                    <div className="hc-user-detail-container">
                        <Row>
                            <Col xs={12} lg={6}>
                                <SkeletonLoader isImage={true} rowCount={5} />
                            </Col>
                        </Row>
                    </div>
                </LayoutWrapper>
            )}
            {!isFetchingData &&
                (Object.keys(userDetail).length > 1 ? (
                    <LayoutWrapper breadCrumb={false}>
                        <BasicModal
                            containerClasses="msg-violation-modal-max-width"
                            isOpen={sendReferralSuccess}
                            onClick={onOkSendReferral}
                            modalType={modalConstant.MessageModal}
                            content={`${userDetail.Name} and ${sendRefferalPayload.Name} have been matched `}
                            isModalHeader={false}
                        />
                        <BasicModal
                            title="Are you sure?"
                            isOpen={sendReferralModal}
                            backClick={handleCancelSendReferral}
                            handleConfirm={handleSendReferral}
                            isConfirmLoading={isLoadingSendRef}
                            handleCancel={handleCancelSendReferral}
                            modalType={modalConstant.LogoutModal}
                        />
                        <div className="compare-profile-container">
                            <div className="row main-row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 row-items">
                                    <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row text-xs-center">
                                        <div className="w-25  text-left mr-4 profile-image-container">
                                            <img
                                                src={userDetail.ProfilePictureUrl}
                                                className="avatar-xl rounded-circle"
                                            />
                                        </div>
                                        <div className="text-left pt-4 profile-text-container">
                                            <h1 className="body-text-3 color-typrography-3 mb-3">
                                                {userDetail.Name}
                                            </h1>
                                            <p className="h4  mb-3 color-typrography-3">
                                                {userDetail.EthinicOrigin}
                                            </p>
                                            <p className="h4 color-typrography-3">
                                                {userDetail.Age}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-right aligned-btn-style">
                                            <BlockButton
                                                className="btn btn-lg button-type-2 color-white"
                                                onClick={() =>
                                                    Socket.chatStart(id, handleChatStarted)
                                                }
                                            >
                                                Message
                                            </BlockButton>
                                        </div>
                                    </div>
                                    <h3 className="color-secondary">
                                        {`About ${userDetail.Name}`}
                                    </h3>
                                    <p className="my-4 color-black">
                                        <ReactReadMoreReadLess
                                            charLimit={200}
                                            readMoreText={"Read more "}
                                            readLessText={"Read less "}
                                        >
                                            {userDetail.About}
                                        </ReactReadMoreReadLess>
                                    </p>
                                    <Accordion
                                        isOpen={openTab === tabs.Personal}
                                        onCollapse={(e) => {
                                            openTab === tabs.Personal
                                                ? setOpenTab(null)
                                                : setOpenTab(tabs.Personal);
                                        }}
                                        headTitle="Personal"
                                        containerClass="my-3"
                                    >
                                        <Row>
                                            <Col xs={12} sm={6}>
                                                <AccordionInnerListing
                                                    contents={[
                                                        {
                                                            title: "Height",
                                                            content: convertInches(
                                                                getUserPersonality("Height")
                                                            )
                                                        },
                                                        {
                                                            title: "Current location",
                                                            content: `${props.t(
                                                                getUserPersonality("Location")?.City
                                                            )}, ${props.t(
                                                                getUserPersonality("Location")
                                                                    ?.Country
                                                            )}`
                                                        },
                                                        {
                                                            title: "Languages",
                                                            content: getUserPersonality(
                                                                "Languages"
                                                            ).join(", ")
                                                        }
                                                    ]}
                                                />
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <AccordionInnerListing
                                                    contents={[
                                                        {
                                                            title: "Marital Status",
                                                            content: getMaritalStatus(
                                                                getUserPersonality("MaritalStatus")
                                                            )
                                                        },
                                                        {
                                                            title: "Children",
                                                            content: getUserPersonality("Children")
                                                        },
                                                        {
                                                            title: "Current living situation",
                                                            content: getCurrentLivingStatus(
                                                                getUserPersonality(
                                                                    "CurrentLivingSituation"
                                                                )
                                                            )
                                                        }
                                                    ]}
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion>

                                    <Accordion
                                        headTitle="Professional"
                                        containerClass="my-3"
                                        isOpen={openTab === tabs.Professional}
                                        onCollapse={(e) => {
                                            openTab === tabs.Professional
                                                ? setOpenTab(null)
                                                : setOpenTab(tabs.Professional);
                                        }}
                                    >
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <AccordionInnerListing
                                                    contents={[
                                                        {
                                                            title: "Occupation",
                                                            content: getUserProfessional(
                                                                "Occupation"
                                                            )
                                                        },
                                                        {
                                                            title: "Level of Education",
                                                            content: getUserProfessional(
                                                                "EducationLevel"
                                                            )
                                                        },
                                                        {
                                                            title: "Working situation",
                                                            content: getUserProfessional(
                                                                "WorkingSituation"
                                                            )
                                                        }
                                                    ]}
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion>

                                    <Accordion
                                        isOpen={openTab === tabs.Religious}
                                        onCollapse={(e) => {
                                            openTab === tabs.Religious
                                                ? setOpenTab(null)
                                                : setOpenTab(tabs.Religious);
                                        }}
                                        headTitle="Religious"
                                        containerClass="my-3"
                                    >
                                        <Row>
                                            <Col xs={12} sm={6}>
                                                <AccordionInnerListing
                                                    contents={[
                                                        {
                                                            title: "Sect",
                                                            content: getSect(
                                                                getUserReligiousDetails("Sect")
                                                            )
                                                        },
                                                        {
                                                            title: "Prayers Routine",
                                                            content: getPrayerRoutine(
                                                                getUserReligiousDetails(
                                                                    "PrayerRoutine"
                                                                )
                                                            )
                                                        },
                                                        {
                                                            title: "Halal Preferences",
                                                            content: getHalalPreference(
                                                                getUserReligiousDetails(
                                                                    "HalalPreference"
                                                                )
                                                            )
                                                        }
                                                    ]}
                                                />
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <AccordionInnerListing
                                                    contents={[
                                                        {
                                                            title: "Alcohol Consumption",
                                                            content: getUserReligiousDetails(
                                                                "AlcoholConsumption"
                                                            )
                                                                ? "Yes"
                                                                : "No"
                                                        },
                                                        {
                                                            title: "Smoking",
                                                            content: getUserReligiousDetails(
                                                                "Smoking"
                                                            )
                                                                ? "Yes"
                                                                : "No"
                                                        }
                                                    ]}
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion>

                                    <Accordion
                                        isOpen={openTab === tabs.PersonalityTraits}
                                        onCollapse={(e) => {
                                            openTab === tabs.PersonalityTraits
                                                ? setOpenTab(null)
                                                : setOpenTab(tabs.PersonalityTraits);
                                        }}
                                        headTitle="Personality Traits"
                                        containerClass="my-3"
                                    >
                                        <Row>
                                            <Col xs={6} sm={6}>
                                                <AccordionInnerListing
                                                    pills={
                                                        userDetail.Traits
                                                            ? userDetail.Traits.map((trait) => {
                                                                  return { name: trait };
                                                              })
                                                            : null
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion>

                                    <Accordion
                                        isOpen={openTab === tabs.Preferences}
                                        onCollapse={(e) => {
                                            openTab === tabs.Preferences
                                                ? setOpenTab(null)
                                                : setOpenTab(tabs.Preferences);
                                        }}
                                        headTitle="Preferences"
                                        containerClass="my-3 mb-5"
                                    >
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <div className="mb-2">
                                                    <AccordionInnerListing
                                                        heading="PERSONAL"
                                                        titleClasses=""
                                                        contents={[
                                                            {
                                                                title: "Age Range ",
                                                                content: `${props.t(
                                                                    userDetail.Preferences?.Personal
                                                                        ?.AgeStart
                                                                )} - ${props.t(
                                                                    userDetail.Preferences?.Personal
                                                                        ?.AgeEnd
                                                                )}`
                                                            },
                                                            {
                                                                title: "Height Range ",
                                                                content: getHeightRange()
                                                            },
                                                            {
                                                                title:
                                                                    "Preferred Partner Previous Maritial Status ",
                                                                content: getUserPreferences(
                                                                    "Personal"
                                                                )?.PreviousMaritalStatus
                                                                    ? "Married"
                                                                    : "Not Married"
                                                            },
                                                            {
                                                                title: "Family Plans ",
                                                                content: getUserPreferences(
                                                                    "Personal"
                                                                )?.FamilyPlans
                                                                    ? "Open to children"
                                                                    : "Not open to children"
                                                            },
                                                            {
                                                                title:
                                                                    "Preferred If Partner is Widowed ",
                                                                content: props.t(
                                                                    userDetail.Preferences?.Personal
                                                                        ?.IsWidowed
                                                                        ? "Yes"
                                                                        : "No"
                                                                )
                                                            },
                                                            {
                                                                title:
                                                                    "Preferred If Partner is Divorced ",
                                                                content: props.t(
                                                                    userDetail.Preferences?.Personal
                                                                        ?.IsDivorced
                                                                        ? "Yes"
                                                                        : "No"
                                                                )
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <AccordionInnerListing
                                                        heading="Professional"
                                                        titleClasses=""
                                                        contents={[
                                                            {
                                                                title:
                                                                    "Preferred If Partner Is Working ",
                                                                content: `${props.t(
                                                                    userDetail.Preferences
                                                                        ?.Professional?.Working
                                                                        ? "Yes"
                                                                        : "No"
                                                                )}`
                                                            },
                                                            {
                                                                title:
                                                                    "Preferred If Partner Stays At Home ",
                                                                content: `${
                                                                    userDetail.Preferences
                                                                        ?.Professional?.StayAtHome
                                                                        ? "Yes"
                                                                        : "No"
                                                                }`
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <AccordionInnerListing
                                                        heading="Religion"
                                                        titleClasses=""
                                                        contents={[
                                                            {
                                                                title: "Sect",
                                                                content: getUserPreferences(
                                                                    "Religious"
                                                                )?.Sect
                                                                    ? "Open to different sect"
                                                                    : getSect(
                                                                          userDetail
                                                                              .ReligiousDetails
                                                                              ?.Sect
                                                                      )
                                                            },
                                                            {
                                                                title: "Prayers Routine",
                                                                content: getUserPreferences(
                                                                    "Religious"
                                                                )?.PrayerRoutine
                                                                    ? "Same"
                                                                    : ""
                                                            },
                                                            {
                                                                title: "Halal Preferences",
                                                                content: getHalalPreference(
                                                                    getUserPreferences("Religious")
                                                                        ?.HalalPreference
                                                                )
                                                            },
                                                            {
                                                                title: "Alcohol Consumption",
                                                                content: getUserPreferences(
                                                                    "Religious"
                                                                )?.AlcoholConsumption
                                                                    ? "Yes"
                                                                    : "No"
                                                            },
                                                            {
                                                                title: "Smoking",
                                                                content: getUserPreferences(
                                                                    "Religious"
                                                                )?.Smoking
                                                                    ? "Yes"
                                                                    : "No"
                                                            }
                                                        ]}
                                                    />
                                                </div>

                                                <Row>
                                                    <Col xs={12} sm={4}>
                                                        <AccordionInnerListing
                                                            heading="Personality"
                                                            contents={[
                                                                {
                                                                    title: "Values"
                                                                }
                                                            ]}
                                                            pills={getUserPreferences(
                                                                "Personality"
                                                            )?.Values.map((value) => {
                                                                return { name: value };
                                                            })}
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xs={12} sm={8}>
                                                        <AccordionInnerListing
                                                            heading=""
                                                            contents={[
                                                                {
                                                                    title: "Traits"
                                                                }
                                                            ]}
                                                            pills={getUserPreferences(
                                                                "Personality"
                                                            )?.Traits.map((trait) => {
                                                                return { name: trait };
                                                            })}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Accordion>

                                    <div className="row mt-5 btn-two-aligned">
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <BlockButton
                                                className="button-type-1 btn btn-lg mr-4 w-100"
                                                onClick={() =>
                                                    history.push(
                                                        getRoute(APP_ROUTES.MATCH_LISTING, {
                                                            id: id
                                                        })
                                                    )
                                                }
                                            >
                                                {props.t("Auto Match")}
                                            </BlockButton>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <BlockButton
                                                className="button-type-1 btn btn-lg w-100"
                                                onClick={() => setIsManualModal(true)}
                                            >
                                                {props.t("Find Manually")}
                                            </BlockButton>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 row-items">
                                    <div>
                                        {!isFetchingUsersDetails ? (
                                            Object.keys(usersDetail).length > 0 ? (
                                                <HorizontalTabs
                                                    selectedId={selectedUserDetails.UserId}
                                                    tabs={usersDetail.map((item, index) => {
                                                        return {
                                                            tabId: item.UserId,
                                                            tabTitle: item.Name,
                                                            tabTitleImg: item.ProfilePictureUrl,
                                                            tabContent: tabConst(),
                                                            onClick: (e) => {
                                                                handleSelectTab(item);
                                                            }
                                                        };
                                                    })}
                                                />
                                            ) : (
                                                <span className="color-black">No Match Found</span>
                                            )
                                        ) : (
                                            <SkeletonLoader rowCount={5} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ManualMatchFilters
                            id={id}
                            setIsManualModal={setIsManualModal}
                            isManualModal={isManualModal}
                            traitsFilter={userDetail?.Preferences?.Personality?.Traits}
                        />
                    </LayoutWrapper>
                ) : (
                    <ErrorPage />
                ))}
        </>
    );
};

export default withNamespaces()(CompareProfile);
