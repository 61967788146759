export const IdVerificationTypes = {
    DRIVING_LICENSE: 1,
    PASSPORT: 2,
    ID_CARD: 3
};

export const IdverificationLabels = {
    [IdVerificationTypes.DRIVING_LICENSE]: "Driving License",
    [IdVerificationTypes.PASSPORT]: "Passport",
    [IdVerificationTypes.ID_CARD]: "Id Card"
};
