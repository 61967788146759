import React from 'react';
import PropTypes from 'prop-types';
import './RadioButton.scss';
const RadioButton = ({radioName,label,labelValue,className,difference,labelClass,...props}) => {
    return (
        <div className={`radio-button-custom-container ${className}`}>
            <div className="custom-control custom-radio">
                <input type="radio" id={`${radioName}${difference}`} name={radioName} className="custom-control-input" {...props} />
                <label className="custom-control-label h6" htmlFor={`${radioName}${difference}`}>{label}</label>
            </div>
        </div>
    )
};

RadioButton.propTypes = {
    radioName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};
export default RadioButton;