import {
    MATCH_SUGGESTION_FAIL,
    MATCH_SUGGESTION_SUCCESS,
    MATCH_SUGGESTION_REQUEST,
    MATCH_RESET
} from "../actions";

const initialState = {
    fetching: false,
    count: 0,
    list: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MATCH_SUGGESTION_REQUEST:
            return {
                ...state,
                fetching: true,
                list: []
            };
        case MATCH_SUGGESTION_SUCCESS:
            return {
                ...state,
                fetching: false,
                count: action.count,
                list: action.payload
            };
        case MATCH_SUGGESTION_FAIL:
            return {
                ...state,
                fetching: false
            };

        case MATCH_RESET:
            return {
                fetching: false,
                count: 0,
                list: []
            };
        default:
            return state;
    }
};
