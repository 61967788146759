import React, { Children } from "react";
import { withNamespaces } from "react-i18next";
import { RoundedInput } from "../../components/RoundedInput";
import "./Chats.scss";

const ChatMessageContent = (props) => {
    const { children } = props;
    return (
        <div className="chat-message-content d-flex flex-column justify-content-between">
            {children}
        </div>
    );
};

export default withNamespaces()(ChatMessageContent);
