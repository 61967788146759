import React from "react";
import { withRouter } from 'react-router-dom';
import './BreadCrumb.scss';

const BreadCrumb = ({history, isBackIcon=true,title}) => {
    return (
        <div className="breadcrumb-container position-relative">
            <nav className="breadcrump-main bg-blue">
                <h2> {isBackIcon && (<a onClick={()=> history.goBack() }><i className="dripicons-chevron-left back-icon"></i> </a>) } {title ? title : document.title.replace(' - HalalConnection','')}</h2>
            </nav>
        </div>
    );
};

export default withRouter(BreadCrumb);
