import React from 'react';
import { connect } from 'react-redux';
import { CardTypeOne, CardTypeOneFlexItem, H4, Icon, CardRightImg, RightIconContainer, StepTwoCardContainer, NotificationBage } from './TooltipDesignMain.styles';
import CustomButton from '../../../Button/Button';

const TooltipDesign = ({ title, description, isLeftIcon = true, leftIconPosition = "", RightImage, RightIcon, backProps, primaryProps, middleContentPosition = "", footerLayoutTwo = false, middleContentWidth, childrenCard, stepTwoAlignSelf, isNotificationCount = false, UnreadCount }) => {
    return (
        <CardTypeOne>
            {RightIcon && (
                <RightIconContainer className="d-flex justify-content-between">
                    <div>
                        <h2 className="body-text-3 color-black mb-4">{title}</h2>
                    </div>
                    <div {...backProps}>
                        <Icon className={RightIcon} />
                    </div>
                </RightIconContainer>
            )}
            <div className="d-flex justify-content-between flex-row flex-wrap">
                {isLeftIcon &&
                    (
                        <CardTypeOneFlexItem alignSelf={leftIconPosition} {...backProps}>
                            <Icon className="fas fa-chevron-left" />
                        </CardTypeOneFlexItem>
                    )
                }
                <CardTypeOneFlexItem width={middleContentWidth ? middleContentWidth : '70%'} alignSelf={middleContentPosition}>
                    {!RightIcon && <h2 className="body-text-3 color-black mb-4">{title}</h2>}

                    <H4>{description}</H4>
                </CardTypeOneFlexItem>
                <CardTypeOneFlexItem className="d-flex flex-column justify-content-between" alignSelf={stepTwoAlignSelf}>
                    {RightImage && (
                        <div className="text-right position-relative">
                            <CardRightImg src={RightImage} imgWidth="3.438rem" />
                            {isNotificationCount && (<NotificationBage className="badge badge-danger badge-pill badge-notification-custom position-absolute">
                                {UnreadCount}
                            </NotificationBage>)}
                        </div>
                    )}
                    {
                        !footerLayoutTwo && <CustomButton className="button-type-3 btn btn-sm body-text-17 mt-3" {...primaryProps}>Next</CustomButton>
                    }

                </CardTypeOneFlexItem>
            </div>
            {childrenCard && (
                <div className="d-flex flex-row mt-4 justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-center justify-content-xl-center">
                    <StepTwoCardContainer>
                        {childrenCard}
                    </StepTwoCardContainer>
                </div>
            )}
            {footerLayoutTwo && (
                <div className="text-right">
                    <CustomButton className="button-type-3 btn btn-xs body-text-17 mt-3" {...primaryProps}>Next</CustomButton>
                </div>
            )}
        </CardTypeOne>
    )
};


const mapStateToProps = (state) => {
    return {
        UnreadCount: state.notification.UnreadCount,
    };
};

export default connect(mapStateToProps, null)(TooltipDesign);