import React, { useState } from "react";
import { Modal } from "reactstrap";
import ModalHeader from "./ModalAssets/Header/Header";
import realuser_lg from "../../assets/images/realuser_lg.png";
import Profile from "../../assets/images/Profile.png";
//Modals
import UserSelectionModal from "./UserSelectionModal";
import WarningModal from "./WarningModal";
import RemovalModal from "./RemoveModal";
import SuspendModal from "./SuspendModal";
import SuspendConfirmationModal from "./SuspendConfirmationModal";
import LogoutModal from "./LogoutModal";

import modalConstant from "../../constants/ModalConstant";

import "./BasicModal.scss";
import MessageModal from "./MessageModal";
const BasicModal = (props) => {
    const {
        isOpen,
        toggle,
        modalType,
        userList,
        isModalHeader = true,
        containerClasses = ""
    } = props;

    const getClassName = () => {
        switch (modalType) {
            case modalConstant.MessageModal:
                return `modal-content-no-borders modal-content-increase-radius modal-dialog-increase-max-width ${containerClasses}`;
            case modalConstant.WarningModal:
                return `msg-violation-modal-max-width`;
            case modalConstant.SuspendModal:
                return `msg-violation-modal-max-width`;

            default:
                return "";
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered={true} className={getClassName()}>
            <div className="basicModalContainer">
                {isModalHeader ? <ModalHeader {...props} /> : <div className="mb-5"> </div>}

                {modalType == modalConstant.UserSelectionModal && (
                    <UserSelectionModal listings={userList} {...props} />
                )}

                {modalType == modalConstant.WarningModal && <WarningModal {...props} />}

                {modalType == modalConstant.RemovalModal && <RemovalModal {...props} />}
                {modalType == modalConstant.SuspendModal && <SuspendModal {...props} />}
                {/* Suspend Confirmation Modal */}
                {modalType == modalConstant.SuspendConfirmationModal && (
                    <SuspendConfirmationModal {...props} />
                )}
                {/* Logout Modal */}
                {modalType == modalConstant.LogoutModal && <LogoutModal {...props} />}
                {modalType == modalConstant.MessageModal && <MessageModal {...props} />}
            </div>
        </Modal>
    );
};
export default BasicModal;
