import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    getNotifications,
    getUnreadNotificationCount
} from "../../../store/actions/notificationsActions";
import { convertDateToTimeAgo } from "../../../utils/dateFormatUtil";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
// Components
import { Avatar } from "../../Avatar";
import { Loader } from "../../Loader";
//Svg Images
import notification_image from "../../../assets/images/icons-svg/notification-filled.svg";
//i18n
import { withNamespaces } from "react-i18next";
import "./TopbarDropdown.scss";
import { NotificationTypes } from "../../../constants";

class NotificationDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            page: 1
        };
        this.toggle = this.toggle.bind(this);
    }
    componentDidMount() {
        this.props.getNotifications({
            Page: this.state.page
        });
    }

    toggle() {
        this.setState((prevState) => ({
            menu: !prevState.menu
        }));
    }

    readNotificationCall = () => {
        const { notifications, getUnreadNotificationCount } = this.props;

        const UnreadNotificationArray = notifications
            .filter((singleNotification) => singleNotification.ReadStatus == 0)
            .map((singleNotification2) => {
                return singleNotification2.Id;
            });

        getUnreadNotificationCount(UnreadNotificationArray);
    };

    loadMoreNotification = () => {
        let currentPage = this.state.page + 1;
        this.setState({ page: currentPage }, () => {
            this.props
                .getNotifications({
                    Page: this.state.page
                })
                .then((response) => {
                    this.readNotificationCall();
                });
        });
    };

    handleNotificationClick = (evt, data) => {
        evt.preventDefault();
        const notificationType = Number(data.Type);
        switch (notificationType) {
            case NotificationTypes.NewMessageToMatchMakerIndividual:
                this.props.history.push(`/dashboard/chat/${data.EntityId}`);
                break;
            case NotificationTypes.PrefrencesComplete:
            case NotificationTypes.ReceiveReferals:
            case NotificationTypes.UserUpdatedProfile:
            case NotificationTypes.NewUserJoinsApp:
            case NotificationTypes.MatchAccepted:
            case NotificationTypes.MatchAssignedToUser:
            case NotificationTypes.NewMatchToUser:
            case NotificationTypes.DisplayPictureChanged:
                this.props.history.push(`/dashboard/user-detail/${data.EntityId}`);
                break;

            default:
                break;
        }
        this.toggle();
    };

    render() {
        const { fetching, count, notifications, currentUser, UnreadCount } = this.props;
        return (
            <React.Fragment>
                <Dropdown
                    isOpen={this.state.menu}
                    toggle={this.toggle}
                    className="dropdown d-inline-block"
                    tag="li"
                >
                    <DropdownToggle
                        className="btn header-item noti-icon waves-effect"
                        tag="button"
                        id="page-header-notifications-dropdown"
                        onClick={this.readNotificationCall}
                    >
                        <img src={notification_image} />
                        {UnreadCount !== 0 && (
                            <span className="badge badge-danger badge-pill badge-notification-custom">
                                {UnreadCount}
                            </span>
                        )}
                    </DropdownToggle>

                    <DropdownMenu
                        className="hc-notification-dropdown dropdown-menu dropdown-menu-lg p-0"
                        right
                    >
                        <div className="m-t-xxl m-l-xl m-b-lg">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="text-uppercase color-dark-gray body-text-14 m-0">
                                        {" "}
                                        {this.props.t("Notifications")}{" "}
                                    </h6>
                                </Col>
                            </Row>
                        </div>

                        <SimpleBar
                            style={{
                                maxWidth: "413px"
                            }}
                        >
                            {notifications.map((singleNotification, index) => {
                                return (
                                    <a
                                        href=""
                                        className="text-reset notification-item"
                                        key={index}
                                        onClick={(evt) =>
                                            this.handleNotificationClick(evt, singleNotification)
                                        }
                                    >
                                        <div
                                            className={`media ${
                                                singleNotification.UnreadCount == 0 &&
                                                "media-background"
                                            }`}
                                        >
                                            <Avatar
                                                src={currentUser?.MediaUrl}
                                                alt="avatar"
                                                width="38"
                                                height="38"
                                            />
                                            <div className="media-body m-l-sm">
                                                <div className="d-flex align-items-center mb-1 flex-wrap">
                                                    <div className="body-text-15 color-dark-gray opacity-7">
                                                        {singleNotification.User.Name}
                                                    </div>

                                                    <div className="m-l-sm body-text-16 opacity-7">
                                                        {singleNotification.Title}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <div className="mb-1 body-text-16 opacity-7">
                                                        {singleNotification.Description}
                                                    </div>
                                                    <div className="body-text-16 opacity-7">
                                                        {singleNotification.ReadStatus == 0 &&
                                                            "Unread"}
                                                    </div>
                                                    <div className="body-text-16 opacity-7">
                                                        {convertDateToTimeAgo(
                                                            singleNotification.CreatedAt
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                );
                            })}
                        </SimpleBar>

                        {fetching && <Loader />}

                        <div className="p-2">
                            {count !== notifications.length && !fetching && (
                                <Link
                                    className="btn btn-sm btn-link body-text-2 btn-block text-center"
                                    to="#"
                                    onClick={this.loadMoreNotification}
                                >
                                    {this.props.t("See more")}
                                </Link>
                            )}
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        fetching: state.notification.fetching,
        count: state.notification.count,
        UnreadCount: state.notification.UnreadCount,
        notifications: state.notification.notifications,
        currentUser: state.auth.user
    };
};

const mapDispatchToProps = (dispatch) => ({
    getNotifications: (params) => dispatch(getNotifications(params)),
    getUnreadNotificationCount: (data) => dispatch(getUnreadNotificationCount(data))
});

export default withNamespaces()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationDropdown))
);
