import React from "react";
import { Media } from "reactstrap";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getMediaPath } from "../../utils/mediaService";
import { Avatar } from "../../components/Avatar";
import { getNearestTimeStampStr } from "../../utils/dateTimeUtils";
import { store } from "../../store";
import StatusIndicator from "../../components/StatusIndicator/StatusIndicator";
import ChatListItemAvatar from "./ChatListItemAvatar";

const ChatUserListItem = (props) => {
    const { currentChatData, chatURL } = props;
    const multipleAvatar = currentChatData.Users.length > 0;

    const getUserNames = (users) => {
        const chatUsers = [];
        {
            !!users.length &&
                users.map((user) => {
                    if (user.UserId !== store.getState().auth.user.Id) chatUsers.push(user.Name);
                });
        }

        return chatUsers.join(",");
    };

    return (
        <li className={`chat-nav-list-item p-v-lg ${multipleAvatar ? "multiple-avatar" : ""}`}>
            <Link
                to={`${chatURL ? chatURL : "/dashboard/chat/"}${currentChatData.ChatId}`}
                className="d-flex"
            >
                <ChatListItemAvatar data={currentChatData.Users} isOverlay={false} />
                <div className={`chat-nav-list-item-right overflow-hidden p-h-sm`}>
                    <div className="p chat-name color-black text-truncate text-capitalize">
                        {getUserNames(currentChatData.Users) || "Unknown"}
                    </div>
                    <div className="body-text-11 chat-content-wrapper color-black d-flex">
                        <span className="chat-text text-truncate">
                            {currentChatData?.LastMessage}
                        </span>
                        <span className="chat-time m-l-sm">
                            {currentChatData.LastMessageDate &&
                                getNearestTimeStampStr(currentChatData.LastMessageDate)}
                        </span>
                    </div>
                </div>
                <div className="chat-read-indicator d-flex p-l-sm" style={{ flexBasis: "10%" }}>
                    {!currentChatData?.LastMessageSeen && (
                        <StatusIndicator width="13px" height="13px" customColor={"#F905D5"} />
                    )}
                </div>
            </Link>
        </li>
    );
};

const mapStateToProps = (state, ownProps) => {
    const currentChatData = state.entities.chats[ownProps.chatId];
    return {
        currentChatData
    };
};

export default connect(mapStateToProps, null)(ChatUserListItem);
