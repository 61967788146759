import React from "react";
import { Modal } from "reactstrap";

import "./FilterModal.scss";

const FilterModal = (props) => {
    const { isOpen = true, toggle, children = "" } = props;
    return (
        <Modal isOpen={isOpen} toggle={toggle} centered={true} className="filter-modal-container">
            {children}
        </Modal>
    );
};
export default FilterModal;
