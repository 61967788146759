import React from 'react';
import CustomButton from '../../../Button/Button';
import { CardTypeOne, CardTypeOneFlexItem, H4, CardFooter, CardLeftImg } from './TooltipDesignMain.styles';

const TooltipDesignSecond = ({ title, titleClass = "", description, isImg, primaryProps, isSkipButton = false, isNextButton = true, isCloseButton = false, imgWidth, skipProps }) => {

    return (
        <CardTypeOne>
            <div className="d-flex justify-content-between">
                <CardTypeOneFlexItem className="w-75">
                    <h2 className={`body-text-3 color-black mb-3 ${titleClass}`}>{title}</h2>
                    <H4>{description}</H4>
                </CardTypeOneFlexItem>
                <CardTypeOneFlexItem className="w-25">
                    <CardLeftImg src={isImg} imgWidth={imgWidth} />
                </CardTypeOneFlexItem>
            </div>
            <CardFooter center>
                {isNextButton && <CustomButton className="button-type-2 btn btn-sm body-text-17 mb-3" {...primaryProps}>Start the tour</CustomButton>}
                {isCloseButton && <CustomButton className="button-type-3 btn btn-sm body-text-17 mt-3" {...skipProps} onClick={(e) => {
                    localStorage.setItem('tour', false);
                    skipProps.onClick(e);
                }}>Done</CustomButton>}
                {isSkipButton && <p className="text-underline color-secondary body-text-18 mb-0"  {...skipProps}
                    onClick={(e) => {
                        localStorage.setItem('tour', false);
                        skipProps.onClick(e);
                    }}
                ><u>Skip the tour</u></p>}
            </CardFooter>
        </CardTypeOne>
    )
};

export default TooltipDesignSecond;