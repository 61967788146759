import { NOTIFICATIONS_REQUEST, NOTIFICATIONS_SUCCESS, NOTIFICATIONS_FAIL, NOTIFICATIONS_UNREAD_REQUEST, NOTIFICATIONS_UNREAD_SUCCESS, NOTIFICATION_STATUS } from '../actions';


const initialState = {
    fetching: false,
    count: 0,
    UnreadCount: 0,
    notifications: []
};

export default (state = initialState, action) => {

    switch (action.type) {
        case NOTIFICATIONS_REQUEST:
            return {
                ...state,
                fetching: true,
                notifications: (() => {
                    if (!action.Page || action.Page === 1) {
                        return [];
                    }
                    return state.notifications;
                })()
            };

        case NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                fetching: false,
                count: action.count,
                UnreadCount: action.UnreadCount,
                notifications: [...state.notifications, ...action.payload]
            };
        case NOTIFICATIONS_UNREAD_REQUEST:

            return {
                ...state,
                fetching: true
            };

        case NOTIFICATIONS_UNREAD_SUCCESS:
            return {
                ...state,
                fetching: false,
                UnreadCount: action.UnreadCount,
                notifications: (() => {
                    const UnreadNotificationArray = state.notifications.map(singleNotification => {
                        if (action.unReadNotificationsArray.includes(singleNotification.Id)) {
                            singleNotification.ReadStatus = NOTIFICATION_STATUS.readStatus;
                        }
                        return singleNotification;
                    });
                    return UnreadNotificationArray;
                })()
            };
        case NOTIFICATIONS_FAIL:
            return {
                ...state,
                fetching: false
            };
        default:
            return state;
    }
}