import React from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Row, Col } from "reactstrap";

import "./Chats.scss";
import { CountBadge } from "../../components/CountBadge";

const ChatNavTabsItem = (props) => {
    const { isGroupActive = false, isSingleActive = false, showSingleTab } = props;

    const groupChatIcon = require("../../assets/images/multi-user.svg");
    const groupChatActiveIcon = require("../../assets/images/multi-user-active.svg");
    const singleChatIcon = require("../../assets/images/single-user.svg");
    const singleChatActiveIcon = require("../../assets/images/single-user-active.svg");

    return (
        <Row>
            <Col className={`${showSingleTab && showSingleTab == "single" ? "d-none" : ""}`}>
                <div className="tab-wrapper d-flex align-items-center justify-content-center">
                    <Link to="">
                        <div className="position-relative d-inline-flex flex-column align-items-center">
                            <img
                                className={`${!isGroupActive ? "d-inline-flex" : "d-none"} `}
                                src={groupChatIcon}
                                alt="user"
                            />
                            <img
                                className={`${isGroupActive ? "d-inline-flex" : "d-none"} `}
                                src={groupChatActiveIcon}
                                alt="user"
                            />
                            <span
                                className={`tab-text body-text-12     ${isGroupActive ? "color-secondary-pink" : "color-white"
                                    }`}
                            >
                                {props.t("group chats")}
                            </span>
                            <CountBadge count="2" />
                        </div>
                    </Link>
                </div>
            </Col>
            <Col className={`${showSingleTab && showSingleTab == "group" ? "d-none" : ""}`}>
                <div className="tab-wrapper d-flex align-items-center justify-content-center">
                    <Link to="">
                        <div className="position-relative d-inline-flex flex-column align-items-center">
                            <img
                                className={`${!isSingleActive ? "d-inline-flex" : "d-none"} `}
                                src={singleChatIcon}
                                alt="user"
                            />
                            <img
                                className={`${isSingleActive ? "d-inline-flex" : "d-none"} `}
                                src={singleChatActiveIcon}
                                alt="user"
                            />
                            <span
                                className={`tab-text body-text-12     ${isSingleActive ? "color-secondary-pink" : "color-white"
                                    }`}
                            >
                                {props.t("Single chats")}
                            </span>
                            <CountBadge count="2" />
                        </div>
                    </Link>
                </div>
            </Col>
        </Row>
    );
};

export default withNamespaces()(ChatNavTabsItem);
