import React from "react";
import { Link } from "react-router-dom";

import "./SeeProfileBadge.scss";
const SeeProfileBadge = ({ name, img, id }) => {
    const avatar = require("../../assets/images/realuser_lg.png");

    return (
        <div className="see-profile-badge-wrapper flex-column d-flex align-items-center p-t-xxl">
            <div className="see-profile-badge-img m-b-xxl rounded-circle">
                <img className="rounded-circle" src={img} lt="avatar" />
            </div>
            <div className="h1 m-b-md text-capitalize">{name}</div>
            <div className="p-light m-b-xs text-capitalize">
                <Link to={`/dashboard/user-detail/${id}`}>See profile</Link>
            </div>
        </div>
    );
};

export default SeeProfileBadge;
