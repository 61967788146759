import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";
import { getIdsFromData } from "../../utils/commonUtils";
import { addChatsToEntity, updateChatInEntity } from "./";
import { ChatType } from "../../constants";
import Config from "../../Config";
import { scrollToBottom } from "../../utils/domUtils";

export const CHAT_LISTING_REQUEST = "CHAT_LISTING_REQUEST";
export const CHAT_LISTING_SUCCESS = "CHAT_LISTING_SUCCESS";
export const CHAT_LISTING_FAIL = "CHAT_LISTING_FAIL";

export const MESSAGE_LIST_REQUEST = "MESSAGE_LIST_REQUEST";
export const MESSAGE_LIST_SUCCESS = "MESSAGE_LIST_SUCCESS";
export const MESSAGE_LIST_FAIL = "MESSAGE_LIST_FAIL";
export const MESSAGE_UNREAD_COUNT = "MESSAGE_UNREAD_COUNT";

export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT";
export const CLEAR_CURRENT_CHAT = "CLEAR_CURRENT_CHAT";

export const ADD_NEW_CHAT = "ADD_NEW_CHAT";
export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";

export const MOVE_CHAT_TOP = "MOVE_CHAT_TOP";

export const UPDATE_MESSAGE_ID = "UPDATE_MESSAGE_ID";

export const getChatListing = (params = {}, abortSignal = null) => async (dispatch, getState) => {
    const token = getState().auth.user.Token;
    dispatch({ type: CHAT_LISTING_REQUEST, Page: params.Page });

    return fetchUtil({
        url: appendQueryParams("/chat/list", {
            Limit: Config.LIMIT,
            ...params
        }),
        token,
        abortSignal
    })
        .then(async (res) => {
            if (res && res.Data) {
                await dispatch(addChatsToEntity(res.Data.Chats));
                await dispatch({
                    type: CHAT_LISTING_SUCCESS,
                    payload: getIdsFromData(res.Data.Chats, "ChatId"),
                    count: res.Data.Count
                });
                return Promise.resolve(getIdsFromData(res.Data.Chats, "ChatId"));
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            dispatch({ type: CHAT_LISTING_FAIL });
            return Promise.reject(err);
        });
};

export const getChatById = (id) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: appendQueryParams("/chat/details", { ChatId: parseInt(id) }),
        token
    })
        .then((res) => {
            if (res && res.Data) {
                dispatch(addChatsToEntity([res.Data]));
                return Promise.resolve(res.Data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getMessageListing = (id, params = {}, abortSignal = null) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    dispatch({ type: MESSAGE_LIST_REQUEST, Page: params.Page });

    return fetchUtil({
        url: appendQueryParams(`/chat/messages/${id}`, {
            Limit: Config.LIMIT,
            Column: "CreatedAt",

            Direction: "DESC",
            ...params
        }),
        token,
        abortSignal
    })
        .then((res) => {
            if (res && res.Data) {
                dispatch({
                    type: MESSAGE_LIST_SUCCESS,
                    payload: res.Data.ChatMessages.reverse(),
                    totalMessages: res.Data.Count
                });
                return Promise.resolve(res.Data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            dispatch({ type: MESSAGE_LIST_FAIL });
            return Promise.reject(err);
        });
};

export const clearCurrentChat = () => {
    return {
        type: CLEAR_CURRENT_CHAT
    };
};

export const setCurrentChat = (chatId, customerId) => {
    return {
        type: SET_CURRENT_CHAT,
        chatId,
        customerId
    };
};

export const addNewChat = (data) => (dispatch, getState) => {
    const existingList = getState().chat.list;
    if (data && data.Chat) {
        dispatch(addChatsToEntity([data.Chat]));
        let updatedList = [...new Set([data.Chat.Id, ...existingList])];
        dispatch({ type: ADD_NEW_CHAT, payload: updatedList });
    }
};

export const addMessage = (data) => (dispatch, getState) => {
    dispatch({
        type: ADD_NEW_MESSAGE,
        payload: data
    });

    setTimeout(() => {
        scrollToBottom();
    }, 0);
};

export const updateChatMessageData = (chatId, msgData) => async (dispatch, getState) => {
    const existingChatsData = getState().entities.chats;

    if (!existingChatsData[chatId]) {
        await dispatch(getChatById(chatId));
    } else {
        let updatedData = {
            LastEventId: msgData.Id,
            LastEvent: { ...msgData, CreatedAt: Date.now() }
        };
        dispatch(updateChatInEntity(chatId, updatedData));
    }
};
export const getChatUnReadCount = (abortSignal = null) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: `/chat/unread-chat-count`,
        token,
        abortSignal
    })
        .then((res) => {
            dispatch({
                type: MESSAGE_UNREAD_COUNT,
                payload: {
                    unReadSingleCount: res.Data?.find(
                        (item) => item.ChatType === ChatType.Individual
                    )?.Count,
                    unReadGroupCount: res.Data?.find((item) => item.ChatType === ChatType.Group)
                        ?.Count
                }
            });
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
        });
};
export const moveChatToTop = (chatId) => (dispatch, getState) => {
    const existingList = getState().chat.list;

    let newList = [...existingList];
    if (newList.includes(chatId)) {
        if (newList[0] !== chatId) {
            let currentIndex = newList.findIndex((id) => id === chatId);
            newList.splice(currentIndex, 1);
            newList.unshift(chatId);
            dispatch({ type: MOVE_CHAT_TOP, newList });
        }
    } else {
        let updatedList = [...new Set([chatId, ...newList])];
        dispatch({ type: ADD_NEW_CHAT, payload: updatedList });
    }
};

export const updateChatUnreadCount = (chatId, clear = false) => (dispatch, getState) => {
    let chatListData = getState().entities.chats;
    let data = {};
    if (chatListData[chatId]) {
        let count = !clear ? chatListData[chatId].UnreadCount + 1 : 0;
        data["UnreadCount"] = count;
        dispatch(updateChatInEntity(chatId, data));
    }
};

export const updateChatUnReadMsg = (chatId, data, clear = false) => (dispatch, getState) => {
    let chatListData = getState().entities.chats;
    if (chatListData[chatId]) {
        if (!clear) {
            chatListData[chatId]["LastMessage"] = data?.Content;
            chatListData[chatId]["LastMessageSeen"] = false;
            chatListData[chatId]["LastMessageDate"] = data?.CreatedAt;
        } else {
            chatListData[chatId]["LastMessage"] = data?.Content
                ? data.Content
                : chatListData[chatId]["LastMessage"];
            chatListData[chatId]["LastMessageSeen"] = true;
            chatListData[chatId]["LastMessageDate"] = data?.CreatedAt
                ? data.CreatedAt
                : chatListData[chatId]["LastMessageDate"];
        }
        dispatch(updateChatInEntity(chatId, data));
    }
};

export const updateChatMessageId = (messageUUID, messageId) => {
    return { type: UPDATE_MESSAGE_ID, messageUUID, messageId };
};
