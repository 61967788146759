import React from "react";

import "./InfoModal.scss";

const InfoModal = ({ children = "" }) => {
    return (
        <div className="info-modal-container info-modal-container position-absolute w-100 h-100 bg-transparent overflow-auto">
            <div className="info-modal-wrapper bg-white">{children}</div>
        </div>
    );
};
export default InfoModal;
