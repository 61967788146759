import React from 'react';
import '../../BasicModal.scss';

const Footer = ({children}) => {
    return(
        <div className="modal-footer-container text-center">
            {children}
        </div>
    )
};

export default Footer;