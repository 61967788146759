import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ReactReadMoreReadLess from "react-read-more-less";

import {
    getCurrentLivingStatus,
    getHalalPreference,
    getMaritalStatus,
    getPrayerRoutine,
    getSect
} from "../../common/common";
import Accordion from "../../components/Accordion/Accordion";
import { SkeletonLoader } from "../../components/Loader";
import BlockButton from "../../components/Button/Button";
import Pill from "../../components/Pill/Pill";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { ErrorPage } from "../../components/Utility";
import { userDetails } from "../../store/actions/userDetailsAction";
import { convertInches } from "../../utils/commonUtils";
import DataTile from "../../components/Tiles/DataTile";
import ManualMatchFilters from "./ManualMatchFilters";
import notificationToastr from "../../utils/toastUtil";
import { useHistory } from "react-router-dom";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { Socket, SocketTypes } from "../../utils/socketUtils";
import { ChatType } from "../../constants";
import "./UserDetail.scss";
const avatar = require("../.././assets/images/profile.jpg");

const UserDetail = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isManualModal, setIsManualModal] = useState(false);
    const [userDetail, setUserDetail] = useState({});
    const [openTab, setOpenTab] = useState(null);

    const tabs = {
        Personal: 1,
        Professional: 2,
        Religious: 3,
        PersonalityTraits: 4,
        Preferences: 5
    };
    const handleChatStarted = (data) => {
        history.push(getRoute(APP_ROUTES.CHAT_LISTING, { id: parseInt(data.ChatId) }), {
            ChatType: ChatType.Individual
        });
    };
    useEffect(() => {
        setIsFetchingData(true);
        dispatch(userDetails({ UserId: id }))
            .then((res) => {
                setUserDetail(res.Data);
                setIsFetchingData(false);
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
                setIsFetchingData(false);
            });
        return () => {
            Socket.remove(SocketTypes.CHAT_START, handleChatStarted);
        };
    }, [id]);

    const getHeightRange = () => {
        if (
            userDetail?.Preferences?.Personal?.HeightStart &&
            userDetail?.Preferences?.Personal?.HeightEnd
        ) {
            let heightStart = convertInches(userDetail.Preferences.Personal.HeightStart);
            let heightEnd = convertInches(userDetail.Preferences.Personal.HeightEnd);

            return `${heightStart} - ${heightEnd}`;
        }

        return "-";
    };

    const getCurrentLocation = () => {
        if (
            userDetail?.PersonalDetails?.Location?.City &&
            userDetail?.PersonalDetails?.Location?.Country
        ) {
            return `${userDetail.PersonalDetails.Location.City} , ${userDetail.PersonalDetails.Location.Country}`;
        }

        return "N/A";
    };

    const getAlcoholConsumption = () => {
        if (userDetail.ReligiousDetails?.hasOwnProperty("AlcoholConsumption")) {
            return userDetail.ReligiousDetails?.AlcoholConsumption ? "Yes" : "No";
        }
        return "N/A";
    };

    const hasPropertyExist = (property) => {
        return userDetail.Preferences?.Personal?.hasOwnProperty(property);
    };

    const hasProfessionalPropertyExist = (property) => {
        return userDetail.Preferences?.Professional?.hasOwnProperty(property);
    };

    return (
        <>
            {isFetchingData && (
                <LayoutWrapper breadCrumb={false}>
                    <div className="hc-user-detail-container">
                        <Row>
                            <Col xs={12} lg={6}>
                                <SkeletonLoader isImage={true} rowCount={5} />
                            </Col>
                        </Row>
                    </div>
                </LayoutWrapper>
            )}
            {!isFetchingData &&
                (Object.keys(userDetail).length > 1 ? (
                    <LayoutWrapper breadCrumb={false}>
                        <div className="hc-user-detail-container">
                            <Row>
                                <Col xs={12} lg={6} className="mb-4">
                                    <div>
                                        <div className="user-detail-avatar d-flex justify-content-center rounded-circle text-center mb-5">
                                            <img
                                                className="avatar-xl rounded-circle"
                                                src={
                                                    userDetail.ProfilePictureUrl
                                                        ? userDetail.ProfilePictureUrl
                                                        : avatar
                                                }
                                                alt="userIcon"
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <div>
                                                <div className="h1 fw-600">
                                                    {props.t(userDetail.Name)}
                                                </div>
                                                <h4 className="mt-3">
                                                    {props.t(userDetail.EthinicOrigin)}
                                                </h4>

                                                <h4 className="mt-3">{props.t(userDetail.Age)}</h4>
                                            </div>
                                            <div>
                                                <BlockButton
                                                    className="btn button-type-2 btn-lg"
                                                    type="button"
                                                    onClick={() => {
                                                        Socket.chatStart(id, handleChatStarted);
                                                    }}
                                                >
                                                    {props.t("Message")}
                                                </BlockButton>
                                            </div>
                                        </div>
                                        <div className="hc-user-profile-about-wrapper">
                                            <h3 className="hc-user-profile-about color-secondary mt-5">
                                                {props.t(`About ${userDetail.Name || "User"}`)}
                                            </h3>
                                            <div className="hc-user-profile-about-desc body-text-4 mt-3">
                                                <ReactReadMoreReadLess
                                                    charLimit={200}
                                                    readMoreText={"Read more "}
                                                    readLessText={"Read less "}
                                                >
                                                    {props.t(userDetail.About) || "Not Available"}
                                                </ReactReadMoreReadLess>
                                            </div>
                                        </div>
                                        <div className="hc-user-profile-match-btn-wrapper d-flex justify-content-between flex-row flex-wrap mt-5">
                                            <div className="hc-user-profile-auto-match-btn w-50">
                                                <BlockButton
                                                    className="button-type-1 btn-match body-text-8 w-100"
                                                    onClick={() =>
                                                        history.push(
                                                            getRoute(APP_ROUTES.MATCH_LISTING, {
                                                                id: id
                                                            })
                                                        )
                                                    }
                                                >
                                                    {props.t("Auto Match")}
                                                </BlockButton>
                                            </div>
                                            <div className="hc-user-profile-manual-match-btn-wrapper w-50 p-l-xl">
                                                <BlockButton
                                                    className="button-type-1 btn-match body-text-8 w-100"
                                                    onClick={() => setIsManualModal(true)}
                                                >
                                                    {props.t("Find Manually")}
                                                </BlockButton>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Accordion
                                        containerClass="mb-3"
                                        isOpen={openTab === tabs.Personal}
                                        onCollapse={(e) => {
                                            openTab === tabs.Personal
                                                ? setOpenTab(null)
                                                : setOpenTab(tabs.Personal);
                                        }}
                                    >
                                        <Row>
                                            <Col xs={12} sm={6}>
                                                <DataTile
                                                    title={props.t("Height")}
                                                    data={props.t(
                                                        userDetail?.PersonalDetails?.Height
                                                            ? convertInches(
                                                                  userDetail?.PersonalDetails
                                                                      ?.Height
                                                              )
                                                            : "N/A"
                                                    )}
                                                />
                                                <DataTile
                                                    title={props.t("Current location")}
                                                    data={getCurrentLocation()}
                                                />
                                                <DataTile
                                                    title={props.t("Languages")}
                                                    data={props.t(
                                                        userDetail.PersonalDetails?.Languages
                                                            ?.length
                                                            ? userDetail.PersonalDetails?.Languages.join(
                                                                  ", "
                                                              )
                                                            : "N/A"
                                                    )}
                                                />
                                            </Col>
                                            <Col xs={12} sm="6">
                                                <DataTile
                                                    title={props.t("Marital Status")}
                                                    data={props.t(
                                                        getMaritalStatus(
                                                            userDetail.PersonalDetails
                                                                ?.MaritalStatus
                                                        )
                                                    )}
                                                />
                                                <DataTile
                                                    title={props.t("Children")}
                                                    data={props.t(
                                                        userDetail.PersonalDetails.hasOwnProperty(
                                                            "Children"
                                                        )
                                                            ? userDetail.PersonalDetails?.Children >
                                                              2
                                                                ? "More than 2"
                                                                : userDetail.PersonalDetails
                                                                      ?.Children
                                                            : "N/A"
                                                    )}
                                                />
                                                <DataTile
                                                    title={props.t("Current living situation")}
                                                    data={props.t(
                                                        getCurrentLivingStatus(
                                                            userDetail.PersonalDetails
                                                                ?.CurrentLivingSituation
                                                        )
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion>
                                    <Accordion
                                        containerClass="mb-3"
                                        isOpen={openTab === tabs.Professional}
                                        headTitle={props.t("Professional")}
                                        onCollapse={(e) => {
                                            openTab === tabs.Professional
                                                ? setOpenTab(null)
                                                : setOpenTab(tabs.Professional);
                                        }}
                                    >
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <DataTile
                                                    title={props.t("Occupation")}
                                                    data={props.t(
                                                        userDetail.ProfessionalDetials?.Occupation
                                                            ? userDetail.ProfessionalDetials
                                                                  ?.Occupation
                                                            : "N/A"
                                                    )}
                                                />
                                                <DataTile
                                                    title={props.t("Level of Education")}
                                                    data={props.t(
                                                        userDetail.ProfessionalDetials
                                                            ?.EducationLevel
                                                            ? userDetail.ProfessionalDetials
                                                                  ?.EducationLevel
                                                            : "N/A"
                                                    )}
                                                />
                                                <DataTile
                                                    title={props.t("Working situation")}
                                                    data={props.t(
                                                        userDetail.ProfessionalDetials
                                                            ?.WorkingSituation
                                                            ? userDetail.ProfessionalDetials
                                                                  ?.WorkingSituation
                                                            : "N/A"
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion>
                                    <Accordion
                                        containerClass="mb-3"
                                        isOpen={openTab === tabs.Religious}
                                        headTitle={props.t("Religious")}
                                        onCollapse={(e) => {
                                            openTab === tabs.Religious
                                                ? setOpenTab(null)
                                                : setOpenTab(tabs.Religious);
                                        }}
                                    >
                                        <Row>
                                            <Col xs={12} sm={6}>
                                                <DataTile
                                                    title={props.t("Sect")}
                                                    data={props.t(
                                                        getSect(userDetail.ReligiousDetails?.Sect)
                                                    )}
                                                />
                                                <DataTile
                                                    title={props.t("Prayers Routine")}
                                                    data={props.t(
                                                        getPrayerRoutine(
                                                            userDetail.ReligiousDetails
                                                                ?.PrayerRoutine
                                                        )
                                                    )}
                                                />
                                                <DataTile
                                                    title={props.t("Halal Preference")}
                                                    data={props.t(
                                                        getHalalPreference(
                                                            userDetail.ReligiousDetails
                                                                ?.HalalPreference
                                                        )
                                                    )}
                                                />
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <DataTile
                                                    title={props.t("Alcohol Consumption")}
                                                    data={props.t(getAlcoholConsumption())}
                                                />

                                                <DataTile
                                                    title={props.t("Smoking")}
                                                    data={props.t(
                                                        userDetail.ReligiousDetails.hasOwnProperty(
                                                            "Smoking"
                                                        )
                                                            ? userDetail.ReligiousDetails?.Smoking
                                                                ? "Yes"
                                                                : "No"
                                                            : "N/A"
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Accordion>
                                    <Accordion
                                        containerClass="mb-3"
                                        isOpen={openTab === tabs.PersonalityTraits}
                                        headTitle={props.t("Personality Traits")}
                                        onCollapse={(e) => {
                                            openTab === tabs.PersonalityTraits
                                                ? setOpenTab(null)
                                                : setOpenTab(tabs.PersonalityTraits);
                                        }}
                                    >
                                        <Row>
                                            <Col xs={12} sm={6}>
                                                {userDetail?.Traits?.length ? (
                                                    userDetail.Traits.map((trait) => {
                                                        return <Pill>{props.t(trait)}</Pill>;
                                                    })
                                                ) : (
                                                    <span className="hc-user-detail-info-desc body-text-6">
                                                        Not Available
                                                    </span>
                                                )}
                                            </Col>
                                        </Row>
                                    </Accordion>
                                    <Accordion
                                        containerClass="mb-3"
                                        isOpen={openTab === tabs.Preferences}
                                        headTitle={props.t("Preferences")}
                                        onCollapse={(e) => {
                                            openTab === tabs.Preferences
                                                ? setOpenTab(null)
                                                : setOpenTab(tabs.Preferences);
                                        }}
                                    >
                                        <Row>
                                            <Col xs={12}>
                                                <div className="hc-user-detail-category-head body-text-7 mb-2">
                                                    Personal
                                                </div>
                                                <DataTile
                                                    title={props.t("Age Range")}
                                                    containerClasses="mb-0"
                                                    titleClasses="mb-0"
                                                    data={`${props.t(
                                                        userDetail.Preferences?.Personal?.AgeStart
                                                    )} - ${props.t(
                                                        userDetail.Preferences?.Personal?.AgeEnd
                                                    )}`}
                                                />
                                                <DataTile
                                                    title={props.t("Height Range")}
                                                    data={getHeightRange()}
                                                    containerClasses="mb-0"
                                                    titleClasses="mb-0"
                                                />
                                                <DataTile
                                                    title={props.t(
                                                        "Preferred If Partner Has Children From Previous Relationship"
                                                    )}
                                                    data={props.t(
                                                        hasPropertyExist("PreviousChildren")
                                                            ? userDetail.Preferences?.Personal
                                                                  .PreviousChildren
                                                                ? "Yes"
                                                                : "No"
                                                            : "N/A"
                                                    )}
                                                    containerClasses="mb-0"
                                                    titleClasses="mb-0"
                                                />
                                                <DataTile
                                                    title={props.t(
                                                        "Preferred Partner Previous Maritial Status"
                                                    )}
                                                    data={props.t(
                                                        hasPropertyExist("PreviousMaritalStatus")
                                                            ? userDetail.Preferences?.Personal
                                                                  .PreviousChildren
                                                                ? "Married"
                                                                : "Not Married"
                                                            : "N/A"
                                                    )}
                                                    containerClasses="mb-0"
                                                    titleClasses="mb-0"
                                                />
                                                <DataTile
                                                    title={props.t("Family Plans")}
                                                    data={props.t(
                                                        hasPropertyExist("FamilyPlans")
                                                            ? userDetail.Preferences?.Personal
                                                                  ?.FamilyPlans
                                                                ? "Open to children"
                                                                : "Not open to children"
                                                            : "N/A"
                                                    )}
                                                    containerClasses="mb-0"
                                                    titleClasses="mb-0"
                                                />

                                                <DataTile
                                                    title={props.t(
                                                        "Preferred If Partner is Widowed "
                                                    )}
                                                    data={props.t(
                                                        hasPropertyExist("IsWidowed")
                                                            ? userDetail.Preferences?.Personal
                                                                  ?.IsWidowed
                                                                ? "Yes"
                                                                : "No"
                                                            : "N/A"
                                                    )}
                                                    containerClasses="mb-0"
                                                    titleClasses="mb-0"
                                                />

                                                <DataTile
                                                    title={props.t(
                                                        "Preferred If Partner is Divorced"
                                                    )}
                                                    data={props.t(
                                                        hasPropertyExist("IsDivorced")
                                                            ? userDetail.Preferences?.Personal
                                                                  ?.IsDivorced
                                                                ? "Yes"
                                                                : "No"
                                                            : "N/A"
                                                    )}
                                                />

                                                <div className="hc-user-detail-category-head body-text-7 mb-2">
                                                    Professional
                                                </div>
                                                <DataTile
                                                    title={props.t(
                                                        "Preferred If Partner Is Working "
                                                    )}
                                                    data={`${props.t(
                                                        hasProfessionalPropertyExist("Working")
                                                            ? userDetail.Preferences?.Professional
                                                                  ?.Working
                                                                ? "Yes"
                                                                : "No"
                                                            : "N/A"
                                                    )}`}
                                                    containerClasses="mb-0"
                                                    titleClasses="mb-0"
                                                />

                                                <DataTile
                                                    title={props.t(
                                                        "Preferred If Partner Stays At Home "
                                                    )}
                                                    data={`${
                                                        hasProfessionalPropertyExist("StayAtHome")
                                                            ? userDetail.Preferences?.Professional
                                                                  ?.StayAtHome
                                                                ? "Yes"
                                                                : "No"
                                                            : "N/A"
                                                    }`}
                                                    containerClasses="mb-1"
                                                    titleClasses="mb-1"
                                                />

                                                <div className="hc-user-detail-category-head body-text-7 mb-2">
                                                    Religion
                                                </div>
                                                <DataTile
                                                    title={props.t("Sect")}
                                                    data={props.t(
                                                        userDetail.Preferences?.Religious?.Sect
                                                            ? "Open to different sect"
                                                            : getSect(
                                                                  userDetail.ReligiousDetails?.Sect
                                                              )
                                                    )}
                                                    containerClasses="mb-0"
                                                    titleClasses="mb-0"
                                                />

                                                <DataTile
                                                    title={props.t("Prayers Routine")}
                                                    data={props.t(
                                                        userDetail.Preferences?.Religious.hasOwnProperty(
                                                            "PrayerRoutine"
                                                        )
                                                            ? userDetail.Preferences?.Religious
                                                                  ?.PrayerRoutine
                                                                ? "Yes"
                                                                : "No"
                                                            : "N/A"
                                                    )}
                                                    containerClasses="mb-0"
                                                    titleClasses="mb-0"
                                                />

                                                <DataTile
                                                    title={props.t("Halal Preference")}
                                                    data={props.t(
                                                        getHalalPreference(
                                                            userDetail.Preferences?.Religious
                                                                ?.HalalPreference
                                                        )
                                                    )}
                                                    containerClasses="mb-0"
                                                    titleClasses="mb-0"
                                                />
                                                <DataTile
                                                    title={props.t("Alcohol Consumption")}
                                                    data={props.t(
                                                        userDetail.Preferences?.Religious.hasOwnProperty(
                                                            "AlcoholConsumption"
                                                        )
                                                            ? userDetail.Preferences?.Religious
                                                                  ?.AlcoholConsumption
                                                                ? "Yes"
                                                                : "No"
                                                            : "N/A"
                                                    )}
                                                    containerClasses="mb-0"
                                                    titleClasses="mb-0"
                                                />
                                                <DataTile
                                                    title={props.t("Smoking")}
                                                    data={props.t(
                                                        userDetail.Preferences?.Religious.hasOwnProperty(
                                                            "Smoking"
                                                        )
                                                            ? userDetail.Preferences?.Religious
                                                                  ?.Smoking
                                                                ? "Yes"
                                                                : "No"
                                                            : "N/A"
                                                    )}
                                                />

                                                <div className="hc-user-detail-category-head body-text-7 mb-2">
                                                    Personality
                                                </div>
                                                <Row>
                                                    <Col xs={12} sm={12}>
                                                        <div className="hc-user-detail-pill-head body-text-7 mb-2">
                                                            Values:
                                                        </div>
                                                        {userDetail.Preferences?.Personality?.Values
                                                            ?.length ? (
                                                            userDetail.Preferences?.Personality.Values.map(
                                                                (value) => {
                                                                    return (
                                                                        <Pill>
                                                                            {props.t(value)}
                                                                        </Pill>
                                                                    );
                                                                }
                                                            )
                                                        ) : (
                                                            <span className="hc-user-detail-info-desc body-text-6">
                                                                Not Available
                                                            </span>
                                                        )}
                                                    </Col>
                                                    <Col xs={12} sm={12}>
                                                        <div className="hc-user-detail-pill-head body-text-7 mb-2">
                                                            Traits:
                                                        </div>
                                                        {userDetail.Preferences?.Personality?.Traits
                                                            ?.length ? (
                                                            userDetail.Preferences?.Personality.Traits.map(
                                                                (trait) => {
                                                                    return (
                                                                        <Pill>
                                                                            {props.t(trait)}
                                                                        </Pill>
                                                                    );
                                                                }
                                                            )
                                                        ) : (
                                                            <span className="hc-user-detail-info-desc body-text-6">
                                                                Not Available
                                                            </span>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Accordion>
                                </Col>
                            </Row>
                        </div>
                        <ManualMatchFilters
                            id={id}
                            setIsManualModal={setIsManualModal}
                            isManualModal={isManualModal}
                            traitsFilter={userDetail?.Preferences?.Personality?.Traits}
                        />
                    </LayoutWrapper>
                ) : (
                    <ErrorPage />
                ))}
        </>
    );
};

export default withNamespaces()(UserDetail);
