import {
    UserStatusCode,
    ReportStatus,
    Sect,
    PrayerRoutine,
    HalalPreference,
    CurrentLivingStatus,
    EducationLevel,
    WorkingSituation,
    MaritalStatus,
    CauseType,
    AppealCause
} from "../constants";

export const getUserStatus = (code) => {
    let status = {};
    switch (code) {
        case UserStatusCode.MATCH_READY:
            status.color = "success";
            status.text = "Match Ready";
            break;
        case UserStatusCode.COMPLETING_PROFILE:
            status.color = "warning";
            status.text = "Completing Profile";
            break;
        case UserStatusCode.SELECTING_PREFERENCES:
            status.color = "warning";
            status.text = "Selecting Preferences";
        case UserStatusCode.MANUAL_DELETED:
            status.color = "danger";
            status.text = "Admin Deleted";
            break;
        case UserStatusCode.VERIFIED:
            status.color = "success";
            status.text = "Verified";
            break;
        case UserStatusCode.UNVERIFIED:
            status.color = "warning";
            status.text = "Unverified";
            break;
        case UserStatusCode.SUSPENDED:
            status.color = "danger";
            status.text = "Suspended";
            break;
        case UserStatusCode.REMOVED:
            status.color = "danger";
            status.text = "Removed";
            break;
        case UserStatusCode.ID_VERIFICATION_PENDING:
            status.color = "warning";
            status.text = "ID Verification Pending";
            break;

        case UserStatusCode.DELETED:
            status.color = "danger";
            status.text = "Deleted";
            break;
    }
    return status;
};
export const getAppealCause = (code) => {
    let status = "";
    switch (code) {
        case AppealCause.FalseClaim:
            status = "False Claim";
            break;
        case AppealCause.WrongfulRemoval:
            status = "Worngful Removal";
            break;
        case AppealCause.WrongfulSuspension:
            status = "Worngful Suspension";
            break;
    }
    return status;
};
export const getReportStatus = (code) => {
    let status = {};
    switch (code) {
        case ReportStatus.PENDING_REVIEW:
            status.color = "warning";
            status.text = "Pending Review";
            break;
        case ReportStatus.UNDER_REVIEW:
            status.color = "purple";
            status.text = "Under Review";
            break;
        case ReportStatus.RESOLVED:
            status.color = "success";
            status.text = "Resolved";
            break;
        case ReportStatus.APPEAL_REQUESTED:
            status.color = "warning";
            status.text = "Appeal Requested";
            break;
        case ReportStatus.UNDER_APPEAL:
            status.color = "purple";
            status.text = "Under Appeal";
            break;
        case ReportStatus.APPEAL_RESOLVED:
            status.color = "success";
            status.text = "Appeal Resolved";
            break;
    }
    return status;
};

export const getMaritalStatus = (code) => {
    let status = "N/A";
    switch (code) {
        case MaritalStatus.Single:
            status = "Single";
            break;
        case MaritalStatus.Widowed:
            status = "Widowed";
            break;
        case MaritalStatus.Divorced:
            status = "Divorced";
            break;
        case MaritalStatus.Separated:
            status = "Separated";
            break;
    }
    return status;
};

export const getCurrentLivingStatus = (code) => {
    let status = "N/A";
    switch (code) {
        case CurrentLivingStatus.Alone:
            status = "Alone";
            break;
        case CurrentLivingStatus.SharedAccommodation:
            status = "Shared accommodation";
            break;
        case CurrentLivingStatus.WithFamily:
            status = "With family";
            break;
    }
    return status;
};

export const getEducationLevel = (code) => {
    let status = "N/A";
    switch (code) {
        case EducationLevel.HighSchool:
            status = "High school";
            break;
        case EducationLevel.Postgraduate:
            status = "Postgraduate";
            break;
        case EducationLevel.Undergraduate:
            status = "Undergraduate";
            break;
    }
    return status;
};

export const getWorkingSituation = (code) => {
    let status = "";
    switch (code) {
        case WorkingSituation.FullTime:
            status = "Full Time";
            break;
        case WorkingSituation.PartTime:
            status = "Part Time";
            break;
        case WorkingSituation.Unemployed:
            status = "Unemployed";
            break;
    }
    return status;
};
export const getSect = (code) => {
    let status = "N/A";
    switch (code) {
        case Sect.Shiah:
            status = "Shiah";
            break;
        case Sect.Sunni:
            status = "Sunni";
            break;
    }
    return status;
};
export const getPrayerRoutine = (code) => {
    let status = "N/A";
    switch (code) {
        case PrayerRoutine.Moderate:
            status = "Moderate";
            break;
        case PrayerRoutine.Rarely:
            status = "Rarely";
            break;
        case PrayerRoutine.Regular:
            status = "Regular";
            break;
    }
    return status;
};

export const getHalalPreference = (code) => {
    let status = "N/A";
    switch (code) {
        case HalalPreference.Liberal:
            status = "Liberal";
            break;
        case HalalPreference.Moderate:
            status = "Moderate";
            break;
        case HalalPreference.Strict:
            status = "Strict";
            break;
    }
    return status;
};

export const getCauseType = (code) => {
    let status = "";
    switch (code) {
        case CauseType.BreakingPrivacyPolicy:
            status = "Breaking Privacy Policy";
            break;
        case CauseType.Bullying:
            status = "Bullying";
            break;
        case CauseType.Discrimination:
            status = "Discrimination (Racism/ Xenophobia )";
            break;
        case CauseType.Harassment:
            status = "Harassment";
            break;
        case CauseType.ImpersonatingToBeSomeone:
            status = "Impersonating To Be Someone";
            break;
        case CauseType.NoPolygamy:
            status = "No Polygamy";
            break;
        case CauseType.SexualContent:
            status = "Sexual Content";
            break;
        case CauseType.UseOfFoulLanguage:
            status = "Inappropriate Language";
            break;
        case CauseType.Violence:
            status = "Violence";
            break;
    }
    return status;
};
