import moment from "moment";

export const dateTimeFormat = {
    timeFormat: "LT",
    dateWithTimeFormat: "MMM  Do, h:mm A",
    dateWithYearTimeFormat: "MMM  Do YYYY, h:mm A"
};

export const getNearestTimeStampStr = (date) => {
    let isDaySame = moment().isSame(date, "day");
    let isYearSame = moment().isSame(date, "year");
    if (isDaySame) {
        return moment(date).format(dateTimeFormat.timeFormat);
    } else if (!isDaySame && isYearSame) {
        return moment(date).format(dateTimeFormat.dateWithTimeFormat);
    } else return moment(date).format(dateTimeFormat.dateWithYearTimeFormat);
};

export const getChatMessageDate = (msg, index, messages) => {
    let previousMessage = messages[index - 1];
    let date;

    if (!previousMessage) {
        date = moment(msg.SendDate).format("LL");
    } else {
        let isPreviousDay = moment(previousMessage.SendDate).isBefore(msg.SendDate, "day");
        if (isPreviousDay) {
            date = moment(msg.SendDate).format("LL");
        }
    }

    return date;
};
export const getChatMessageTimeDifference = (time, index, messages) => {
    let previousMessage = messages[index - 1];
    let showDate = true;
    if (previousMessage && previousMessage?.SendDate) {
        let isSameMinute = moment(previousMessage.SendDate).isSame(time, "minute");
        if (isSameMinute) {
            showDate = false;
        }
    }
    return showDate;
};
