import React, { useEffect, useState, useRef } from "react";
import { Input } from "reactstrap";
import { useDispatch, connect } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ChatUserListItem from "./ChatUserListItem";
import {
    getChatListing,
    addNewChat,
    addMessage,
    updateChatMessageData,
    moveChatToTop,
    getChatUnReadCount,
    updateChatUnreadCount,
    updateChatUnReadMsg
} from "../../store/actions";
import { Loader } from "../../components/Loader";
import { Socket, SocketTypes } from "../../utils/socketUtils";
import SubHeader from "../../components/SubHeader/SubHeader";
import { ChatListType, ChatType, ChatMessageType } from "../../constants";
import { getRandomNumber } from "../../utils/commonUtils";

const ChatUserList = (props) => {
    const history = useHistory();
    const chatAbortController = useRef(null);

    const { data, dataAvailable, fetching, chatTab, chatURL, chatListingType } = props;
    const [page, setPage] = useState(1);
    const [chatType, setChatType] = useState(chatTab);
    const [isFetchingInitial, setIsFetchingInitial] = useState(true);
    const [chatListType, setChatListType] = useState(
        chatListingType ? chatListingType : ChatListType.Connected
    );
    const [searchText, setSearchText] = useState("");
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        function handleChatStarted(data) {
            dispatch(addNewChat(data));
        }

        function handleGuardianAddedOrRemoved(data, type) {
            if (data.ChatId === parseInt(id)) {
                let addMsgData = {
                    ChatId: data.ChatId,
                    Id: getRandomNumber(),
                    Type: type,
                    SenderId: data.UserId
                };

                dispatch(addMessage(addMsgData));
                // dispatch(updateChatMessageData(addMsgData));
            }
        }

        function handleGuardianAdded(data) {
            handleGuardianAddedOrRemoved(data, ChatMessageType.GuardianAdded);
        }
        function handleGuardianRemoved(data) {
            handleGuardianAddedOrRemoved(data, ChatMessageType.GuardianRemoved);
        }

        async function handleMessageReceived(data) {
            const selectedChatId = parseInt(id);

            if (data && data.ChatId && data.ChatId === selectedChatId && data.ChatEvent) {
                dispatch(addMessage(data.ChatEvent));
                Socket.readMessage(data.ChatEvent.ChatId);
            } else {
                dispatch(getChatUnReadCount());
            }

            await dispatch(updateChatMessageData(data.ChatId, data.ChatEvent));
            if (data.ChatType === chatTab) dispatch(moveChatToTop(data.ChatId));
            if (!selectedChatId || selectedChatId !== data.ChatId) {
                dispatch(updateChatUnReadMsg(data.ChatId, data.ChatEvent));
            }
        }

        if (chatTab === ChatType.Individual) Socket.onChatStarted(handleChatStarted);
        Socket.onMessageRecieved(handleMessageReceived);

        Socket.onGuardianAdded(handleGuardianAdded);
        Socket.onGuardianRemoved(handleGuardianRemoved);

        return () => {
            Socket.remove(SocketTypes.CHAT_START, handleChatStarted);
            Socket.remove(SocketTypes.MESSAGE, handleMessageReceived);
            Socket.remove(SocketTypes.GUARDIAN_ADDED, handleGuardianAdded);
            Socket.remove(SocketTypes.GUARDIAN_REMOVED, handleGuardianRemoved);
        };
    }, [id, dispatch]);

    useEffect(() => {
        chatAbortController.current = new AbortController();
        if (chatTab !== chatType || isFetchingInitial) {
            setIsFetchingInitial(false);
            setChatType(chatTab);
            setSearchText("");
            setPage(1);
            dispatch(
                getChatListing(
                    {
                        Page: 1,
                        ChatType: chatTab,
                        ChatListType: chatListType
                    },
                    chatAbortController.current.signal
                )
            )
                .then((res) => {
                    if (res) {
                        history.push(
                            `${chatURL ? chatURL : "/dashboard/chat/"}${
                                parseInt(id) && chatTab === chatType ? parseInt(id) : res[0]
                                // res.includes(parseInt(id)) ? parseInt(id) : res[0] ? res[0] : `:id`
                            }`
                        );
                    }
                })
                .catch((err) => {});
        } else {
            // if (data.length > 0 && !data.includes(parseInt(id))) {
            //     history.push(`${chatURL ? chatURL : "/dashboard/chat/"}${data[0]}`);
            // }
        }

        return () => {
            if (chatAbortController.current) {
                chatAbortController.current.abort();
            }
        };
    }, [dispatch, chatTab, id]);

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;

        if (bottom && dataAvailable && !fetching) {
            let currentPage = page + 1;
            dispatch(
                getChatListing({
                    Page: currentPage,
                    ChatType: chatType,
                    ChatListType: chatListType,
                    SearchText: searchText
                })
            );
            setPage(currentPage);
        }
    };

    const handleSearchChange = (e) => {
        const searchText = e.target.value;
        dispatch(
            getChatListing({
                Page: 1,
                ChatType: chatType,
                ChatListType: chatListType,
                ...(searchText && { SearchText: searchText })
            })
        );
        setSearchText(searchText);
        setPage(1);
    };

    return (
        <>
            <div className="chat-nav-search-wrapper">
                <div className="chat-nav-search-input-wrapper w-100">
                    <DebounceInput
                        className="p-v-sm p-h-md bg-white w-100 h5 "
                        element={Input}
                        debounceTimeout={500}
                        placeholder="Search..."
                        value={searchText}
                        onChange={handleSearchChange}
                        type="text"
                    />
                </div>
            </div>
            <div className="chat-nav-list-wrapper d-flex flex-column justify-content-between">
                <ul
                    className="chat-nav-list h-100 pl-0 mb-0 auto-scroll fancy-scroll"
                    onScroll={handleScroll}
                >
                    {!fetching && !data.length && (
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                            <p className="text-muted text-center">No Chats Available</p>
                        </div>
                    )}
                    <div className={`${!!data.length && "h-100"} loader-width-set`}>
                        {!!data.length &&
                            data.map((chatId) => {
                                return (
                                    <ChatUserListItem
                                        chatId={chatId}
                                        key={chatId}
                                        chatURL={chatURL}
                                    />
                                );
                            })}
                        {fetching && (
                            <div className="p-v-lg multiple-avatar loader-width-set">
                                <Loader
                                    classes={!data.length ? "h-100" : ""}
                                    {...(data.length && { size: "sm" })}
                                />
                            </div>
                        )}
                    </div>
                </ul>
                {/* {chat-tabs-wrapper here} */}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.chat.list,
        dataAvailable: state.chat.dataAvailable,
        fetching: state.chat.fetching
    };
};

export default connect(mapStateToProps, null)(ChatUserList);
