import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";

export const userDetails = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        method: "GET",
        url: appendQueryParams("/user/details", { ...data }),
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
