import { ME_REQUEST, ME_SUCCESS, ME_ERROR, CHANGE_SOCKET_CONNECTIVITY_STATUS } from "../actions";

const initialState = {
    tokenVerified: false,
    isLoading: true,
    socketConnected: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ME_REQUEST:
            return { ...state, isLoading: true };
        case ME_SUCCESS:
            return { ...state, tokenVerified: true };
        case ME_ERROR:
            return { ...state, isLoading: false };
        case CHANGE_SOCKET_CONNECTIVITY_STATUS:
            return {
                ...state,
                socketConnected: action.payload
            };
        default:
            return state;
    }
};
