import React, { useState, Component } from "react";
import { Row, Col, Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../helpers/routeHelpers";
// Redux
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation";
// actions
// import { loginUser, apiError } from "../../store/actions";
import "./authentication.scss";
import { TextInput } from "../../components/TextInput";
import BlockButton from "../../components/Button/Button";
import { Notificaion } from "../../utils/notificationUtils";
// import images
import loveBubble from "../../assets/images/love-bubble.svg";
import { login } from "../../store/actions";
import { withRouter, Link, Redirect } from "react-router-dom";
import notificationToastr from "../../utils/toastUtil";
import { UserStatusCode, Roles } from "../../constants";

const Login = (props) => {
    const { t, isAuthenticated, isVerified } = props;
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();

    const handleValidSubmit = (event, values) => {
        let notification;
        setLoading(true);
        props
            .login({ ...values, Type: Roles.MATCH_MAKER })
            .then((res) => {
                setLoading(false);

                if (res.User.Status === UserStatusCode.VERIFIED)
                    history.push(APP_ROUTES.USER_LISTING);
                else history.push("/create-profile");
            })
            .catch((err) => {
                setLoading(false);
                notificationToastr.error(props.t(err.Message));
            });
    };

    return (
        <>
            {isAuthenticated && isVerified && <Redirect to={APP_ROUTES.USER_LISTING} />}
            <div className="hc-login-container account-pages d-flex align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={6} lg={4} xl={4}>
                            <div className="bg-transparent">
                                <div className="p-2">
                                    <div className="d-flex justify-content-center">
                                        <span>
                                            <img
                                                src={loveBubble}
                                                alt=""
                                                className="rounded-circle"
                                                width="200"
                                            />
                                        </span>
                                    </div>
                                    <AvForm
                                        className="form-horizontal text-center"
                                        onValidSubmit={handleValidSubmit}
                                    >
                                        <div className="form-group">
                                            <TextInput
                                                name="Email"
                                                classes="body-text-5"
                                                placeholder={t("Email Address")}
                                                validate={{
                                                    required: {
                                                        errorMessage: props.t(
                                                            "Please fill in the required fields"
                                                        )
                                                    },
                                                    email: {
                                                        errorMessage: t(
                                                            "Please enter a valid email ID"
                                                        )
                                                    }
                                                }}
                                                type={"email"}
                                            />
                                        </div>

                                        <div className="form-group m-b-xxl">
                                            <TextInput
                                                name="Password"
                                                classes="body-text-5"
                                                placeholder={props.t("Password")}
                                                validate={{
                                                    required: {
                                                        errorMessage: t(
                                                            "Please fill in the required fields"
                                                        )
                                                    }
                                                }}
                                                type={"password"}
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <BlockButton
                                                className="btn button-type-1 btn-md uppercase body-text-8"
                                                type="submit"
                                                isLoading={isLoading}
                                            >
                                                {t("Login")}
                                            </BlockButton>
                                        </div>
                                    </AvForm>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const mapStatetoProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isVerified: state.auth.isVerified
    };
};

const mapDispatchToProps = {
    login
};

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(withNamespaces()(Login)));
