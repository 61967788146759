import React from "react";
import { ToggleSwitch } from "../ToggleSwitch";
import "./Toggle.scss";
const DataToggle = (props) => {
    return (
        <li
            className={`d-flex flex-row align-items-center justify-content-between m-t-xxxl ${
                props.className && props.className
            }`}
        >
            {props.name && <div className="body-text-3">{props.name}</div>}
            <ToggleSwitch
                checked={props.value}
                id={props.settingId}
                name={props.name}
                onClick={() => {
                    props.onClick();
                }}
            />
        </li>
    );
};

export default DataToggle;
