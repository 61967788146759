import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import sidebarConfig from "../../sidebarConfig";
import notificationToastr from "../../utils/toastUtil";
import { Socket } from "../../utils/socketUtils";
import { logout } from "../../store/actions";
import BasicModal from "../../components/Modal/BasicModal";
import modalConstant from "../../constants/ModalConstant";

class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.state = { logoutModal: false, isLogoutLoading: false, routeName: '' };
    }

    componentDidMount() {
        this.initMenu();
        this.setState({ routeName: this.props.location.pathname });
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ routeName: this.props.location.pathname });
        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = (item) => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };
    setLogoutState = (value) => {
        this.setState({ logoutModal: value });
    };

    confirmLogout = () => {
        this.setState({ isLogoutLoading: true });
        this.props.logout();
        this.setState({ isLogoutLoading: false });
        this.setLogoutState(false);
        Socket.disconnect();
    };
    checkRouteActive(item) {
        let isRouterActive = false;
        let paramId = this.props.match.params.id;
        if (this.state.routeName == item.url.replace(':id?', paramId).replace(':id', paramId)) {
            isRouterActive = true;
        }
        if (item.childrenUrl) {
            const childrenUrlFiltered = item.childrenUrl.map(singleChildren => {
                return singleChildren.replace(':id', paramId).replace(':id?', paramId)
            })
            if (childrenUrlFiltered.includes(this.state.routeName)) {
                isRouterActive = true;
            }
        }
        return isRouterActive;
    }
    render() {
        return (
            <React.Fragment>
                <BasicModal
                    isOpen={this.state.logoutModal}
                    backClick={() => {
                        this.setLogoutState(false);
                    }}
                    handleConfirm={this.confirmLogout}
                    isConfirmLoading={this.state.isLogoutLoading}
                    handleCancel={() => {
                        this.setLogoutState(false);
                    }}
                    modalType={modalConstant.LogoutModal}
                />
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {sidebarConfig.map((item, index) => {
                            const hasChildrens =
                                item.hasOwnProperty("children") && Array.isArray(item.children);
                            return item.type && item.type === "title" ? (
                                <li key={index} className="menu-title">
                                    {this.props.t(item.title)}
                                </li>
                            ) : (
                                    <li key={index} className={item.class}>
                                        {item.url !== "logout" ? (
                                            <Link
                                                to={item.url}
                                                className={`waves-effect ${hasChildrens ? "has-arrow" : ""
                                                    }`}
                                            >
                                                {item.svgIcon ? this.checkRouteActive(item) ? <img src={item.svgIconActive} alt="" /> : <img src={item.svgIcon} alt="" /> : (
                                                    <i className={`${item.icon}`}></i>
                                                )}

                                                <span>{this.props.t(item.title)}</span>
                                            </Link>
                                        ) : (
                                                <div
                                                    className={`waves-effect ${hasChildrens ? "has-arrow" : ""
                                                        }`}
                                                    onClick={() => this.setLogoutState(true)}
                                                >
                                                    {item.svgIcon ? (
                                                        <img src={item.svgIcon} alt="" />
                                                    ) : (
                                                            <i className={`${item.icon}`}></i>
                                                        )}

                                                    <span>{this.props.t(item.title)}</span>
                                                </div>
                                            )}
                                        {hasChildrens && (
                                            <ul className="sub-menu" aria-expanded="false">
                                                {item.children.map((childItem, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Link to={childItem.url}>
                                                                {this.props.t(childItem.title)}
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </li>
                                );
                        })}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout());
        }
    };
};

export default withRouter(
    connect(mapStatetoProps, mapDispatchToProps)(withNamespaces()(SidebarContent))
);
