import AvForm from "availity-reactstrap-validation/lib/AvForm";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import BlockButton from "../../components/Button/Button";
import EditableContent from "../../components/EditableContent/EditableContent";
import ProfileUpload from "../../components/ProfileUpload/ProfileUpload";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { TextInput } from "../../components/TextInput";
import { changePassword, updateProfile } from "../../store/actions/accountSettingAction";
import { validatePhone } from "../../utils/commonUtils.js";
import { mediaService, validateMedia } from "../../utils/mediaService.js";
import notificationToastr from "../../utils/toastUtil";
import "./Settings.scss";

const AccountSettings = (props) => {
    const editIcon = require("../../assets/images/edit.svg");
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();
    const [phoneNumber, setPhoneNumber] = useState();
    const [isValidPhone, setisValidPhone] = useState(true);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);
    const [isChangePhoneLoading, setIsChangePhoneLoading] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showChangePhoneNumber, setShowChangePhoneNumber] = useState(false);
    const [loadingEditable, setLoadingEditable] = useState(false);

    const handleChangeAccountDetails = (data, onSuccess = () => { }, onError = () => { }) => {
        dispatch(updateProfile(data))
            .then((res) => {
                notificationToastr.success(res.Message);
                onSuccess();
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
                onError();
            });
    };
    const handleAcceptedFiles = (acceptedFiles) => {
        setIsImageLoading(true);
        let image = validateMedia(acceptedFiles, props.t);
        if (image) {
            dispatch(mediaService("/media/upload", image))
                .then((res) => {
                    handleChangeAccountDetails(
                        { MediaId: res.Data.Id, MediaUrl: res.Data.Url },
                        () => {
                            setIsImageLoading(false);
                        },
                        () => {
                            setIsImageLoading(false);
                        }
                    );
                })
                .catch((err) => {
                    notificationToastr.error(err.Message);
                    setIsImageLoading(false);
                });
        } else {
            setIsImageLoading(false);
        }
    };
    const handleChangePhoneNumber = () => {
        if (validatePhone(phoneNumber)) {
            setIsChangePhoneLoading(true);
            handleChangeAccountDetails(
                {
                    Phone: { Number: phoneNumber.value, Extension: phoneNumber.countryCode }
                },
                () => {
                    setIsChangePhoneLoading(false);
                    setShowChangePhoneNumber(false);
                },
                () => {
                    setIsChangePhoneLoading(false);
                }
            );
        }
    };

    const handleChangeName = (name) => {
        setLoadingEditable(true);
        handleChangeAccountDetails(
            {
                FullName: name
            },
            () => {
                setLoadingEditable(false);
            }
        );
    };

    const handleChangeAccountPassword = (event, data) => {
        setIsChangePasswordLoading(true);
        let payload = {
            OldPassword: data.currentPassword,
            NewPassword: data.newPassword,
            ConfirmPassword: data.confirmPassword
        };
        dispatch(changePassword(payload))
            .then((res) => {
                notificationToastr.success(res.Message);
                setShowChangePassword(false);
                setIsChangePasswordLoading(false);
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
                setIsChangePasswordLoading(false);
            });
    };

    return (
        <>
            <LayoutWrapper>
                <div className=" d-flex flex-column">
                    <Row>
                        <Col xs="12">
                            <div className="as-edit-profile-wrapper d-flex align-items-center justify-content-center flex-column">
                                <ProfileUpload
                                    avatarSize="xxl"
                                    uploadedImg={auth.user.MediaUrl}
                                    handleAcceptedFiles={handleAcceptedFiles}
                                    isLoading={isImageLoading}
                                />
                                <div className="form-group ">
                                    <EditableContent
                                        content={auth?.user?.FullName}
                                        onContentSubmit={(value) => handleChangeName(value)}
                                        isLoading={loadingEditable}
                                        isShowEditIcon={true}
                                        contentClasses="h3 hc-editable-Title"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center pt-4">
                        <Col xs={12} md={4} className="d-flex justify-content-start flex-wrap">
                            <AvForm
                                className="form-horizontal text-center width-80"
                                onValidSubmit={handleChangeAccountPassword}
                            >
                                <div className="form-group">
                                    <TextInput
                                        classes="width-80"
                                        name="currentPassword"
                                        placeholder={props.t("Current Password")}
                                        labelText={props.t("Current Password")}
                                        required={showChangePassword}
                                        type={"password"}
                                    />
                                </div>

                                {showChangePassword && (
                                    <>
                                        <div className="form-group">
                                            <TextInput
                                                classes="width-80"
                                                name="newPassword"
                                                placeholder={props.t("New Password")}
                                                labelText={props.t("New Password")}
                                                type={"password"}
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: props.t(
                                                            "Please fill in the required fields"
                                                        )
                                                    },
                                                    pattern: {
                                                        value: "^.{6,138}$",
                                                        errorMessage: props.t(
                                                            "Password must be between 6 to 128 characters"
                                                        )
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <TextInput
                                                classes="width-80"
                                                name="confirmPassword"
                                                placeholder={props.t("Confirm Password")}
                                                labelText={props.t("Confirm Password")}
                                                type={"password"}
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: props.t(
                                                            "Please fill in the required fields"
                                                        )
                                                    },
                                                    match: {
                                                        value: "newPassword",
                                                        errorMessage: props.t(
                                                            "Password and Confirm Password must be same"
                                                        )
                                                    }
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                                <div className="mt-3 text-left form-group">
                                    {showChangePassword &&
                                        <BlockButton
                                            className="btn button-type-3 btn-sm reduce-radius p reduce-padding"
                                            type="submit"
                                            isLoading={isChangePasswordLoading}
                                            disabled={isChangePasswordLoading}
                                        >
                                            {props.t("Save Changes")}
                                        </BlockButton>
                                    }
                                </div>
                            </AvForm>
                            {!showChangePassword &&
                                <BlockButton
                                    className="btn button-type-3 btn-sm reduce-radius p reduce-padding"
                                    onClick={() => {
                                        setShowChangePassword(true);
                                    }}
                                >{props.t("Change Password")}
                                </BlockButton>
                            }
                        </Col>
                        <Col xs={12} md={3} className="d-flex justify-content-end">
                            <AvForm className="form-horizontal text-center width-100">
                                <div className="form-group">
                                    <p className="color-black text-left h3 mb-3">
                                        {props.t("Change Phone Number")}
                                    </p>
                                    <PhoneInput
                                        name="PhoneDisplay"
                                        country={auth.user.Phone?.Extension}
                                        labelText={props.t("Current Phone Number")}
                                        autoFormat={true}
                                        disableSearchIcon={true}
                                        enableSearch={true}
                                        countryCodeEditable={false}
                                        searchStyle={{
                                            fontFamily: "inherit"
                                        }}
                                        inputStyle={{
                                            width: "80%",
                                            margin: 0,
                                            display: "inline-block",
                                            right: "-25px",
                                            paddingLeft: "10px"
                                        }}
                                        dropdownStyle={{
                                            borderRadius: "6px",
                                            textAlign: "left",
                                            maxHeight: "180px"
                                        }}
                                        buttonStyle={{
                                            borderRadius: "6px"
                                        }}
                                        value={auth.user.Phone?.Number}
                                        disabled={true}
                                    />
                                </div>
                                {showChangePhoneNumber && (
                                    <>
                                        <div className="form-group ">
                                            <PhoneInput
                                                inputClass={!isValidPhone && "custom-inp-error"}
                                                name="PhoneInput"
                                                country={auth.user.Phone?.Extension}
                                                labelText={props.t("New Phone Number")}
                                                autoFormat={true}
                                                onBlur={() => {
                                                    setisValidPhone(validatePhone(phoneNumber));
                                                }}
                                                disableSearchIcon={true}
                                                enableSearch={true}
                                                countryCodeEditable={false}
                                                searchStyle={{
                                                    fontFamily: "inherit"
                                                }}
                                                inputStyle={{
                                                    width: "80%",
                                                    margin: 0,
                                                    display: "inline-block",
                                                    right: "-25px",
                                                    paddingLeft: "10px"
                                                }}
                                                dropdownStyle={{
                                                    borderRadius: "6px",
                                                    textAlign: "left"
                                                }}
                                                buttonStyle={{
                                                    borderRadius: "6px"
                                                }}
                                                onChange={(value, data) => {
                                                    setPhoneNumber({ value: value, ...data });
                                                }}
                                            />
                                        </div>
                                        {!isValidPhone && (
                                            <div className="custom-inp-error-text">
                                                This field is invalid
                                            </div>
                                        )}
                                    </>
                                )}

                                <div className="mt-3 text-left">
                                    <BlockButton
                                        className="btn button-type-3 btn-sm reduce-radius p reduce-padding"
                                        type="submit"
                                        isLoading={showChangePhoneNumber && isChangePhoneLoading}
                                        disabled={isChangePhoneLoading}
                                        onClick={() => {
                                            if (!showChangePhoneNumber) {
                                                setShowChangePhoneNumber(true);
                                            } else {
                                                setisValidPhone(validatePhone(phoneNumber));
                                                handleChangePhoneNumber();
                                            }
                                        }}
                                    >
                                        {showChangePhoneNumber
                                            ? props.t("Save Changes")
                                            : props.t("Change Phone No")}
                                    </BlockButton>
                                </div>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </LayoutWrapper>
        </>
    );
};

export default withNamespaces()(AccountSettings);
