import React, { Component } from "react";
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import "./assets/scss/theme.scss";
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import { DefaultLayout } from "./containers";
import Login from "./pages/Authentication/Login";
import ForgetPassword from "./pages/Authentication/ForgetPassword";
import CreateProfile from "./pages/Authentication/CreateProfile";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/login" name="Login" component={Login} />
                    <Route
                        path="/forgot-password"
                        name="ForgotPassword"
                        component={ForgetPassword}
                    />
                    <Route path="/create-profile" name="CreateProfile" component={CreateProfile} />
                    <Route
                        path="/dashboard"
                        name="Dashboard"
                        render={(props) => <DefaultLayout {...props} Layout={this.getLayout()} />}
                    />
                    <Redirect to="/login" />
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        layout: state.Layout
    };
};

export default connect(mapStateToProps, null)(App);
