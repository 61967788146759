import React from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import StatusIndicator from "../../components/StatusIndicator/StatusIndicator";
import "./Chats.scss";
import ChatNavListItemAvatar from "./ChatNavListItemAvatar";

const ChatNavListItem = (props) => {
    const {
        name = "Zain",
        message = "hello this is test",
        time = "1h",
        unread = true,
        multipleAvatar = true
    } = props;
    return (
        <li
            className={`chat-nav-list-item p-v-lg ${multipleAvatar ? "multiple-avatar" : ""} ${unread ? "unread" : ""
                }`}
        >
            <Link to="" className="d-flex">
                <ChatNavListItemAvatar multiple={multipleAvatar} />
                <div
                    className={`chat-nav-list-item-right overflow-hidden p-h-sm ${!unread ? "p-r-lg" : ""
                        }`}
                >
                    <div className="p chat-name color-black text-truncate text-capitalize">{name}</div>
                    <div className="body-text-11 chat-content-wrapper color-black d-flex">
                        <span className="chat-text text-truncate">{message}</span>
                        <span className="chat-time m-l-sm">{time}</span>
                    </div>
                </div>
                <div className="chat-read-indicator d-flex p-l-sm" style={{ flexBasis: "10%" }}>
                    {unread && (
                        <StatusIndicator width="13px" height="13px" customColor={"#F905D5"} />
                    )}
                </div>
            </Link>
        </li>
    );
};

export default withNamespaces()(ChatNavListItem);
