import React from "react";
import CustomCard from "../Card/CustomCard";
import ChatHistoryMessage from "../ChatHistoryMessage/ChatHistoryMessage";
import "./ChatHistory.scss";

const ChatHistory = ({ username, messages, onScroll, isLoading }) => {
    return (
        <span className="chat-history-container">
            <CustomCard>
                <div>
                    <div className="d-flex flex-row justify-content-between">
                        <div>
                            <p className="body-text-7">{username}</p>
                        </div>
                        <div className="align-self-start chat-header-right">
                            <i className="fas fa-minus  cus-icon-minus"></i>
                            <i className="mdi mdi-window-close ml-5  cus-icon-cross"></i>
                        </div>
                    </div>
                    {messages.length > 0 ? (
                        <div className="pl-4">
                            <ChatHistoryMessage
                                historyMessages={messages}
                                onScroll={onScroll}
                                isLoading={isLoading}
                            />
                        </div>
                    ) : (
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <p className="text-truncate mb-0 text-muted font-weight-bold">
                                No Chat Messages
                            </p>
                        </div>
                    )}
                </div>
            </CustomCard>
        </span>
    );
};

export default ChatHistory;
