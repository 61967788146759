import React from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
  } from 'reactstrap';

import './CustomCard.scss';

const CustomCard = ({
    className,
    children,
    title,
    img,
    ...rest
}) =>{
    return(
        <span className="custom-card-container" {...rest}>
            <Card className={className}>
                { img && <CardImg top width="100%" src={img} alt="Card image cap" /> }
                <CardBody>
                <CardTitle tag="h5">{title}</CardTitle>
                    {children}
                </CardBody>
            </Card>
        </span>
    )
};

export default CustomCard;