import React, { useState, useEffect } from "react";
import { SkeletonLoader } from "../../components/Loader";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Card } from "reactstrap";
import classnames from "classnames";

import "./HorizontalTabs.scss";

const HorizontalTabs = ({ tabs, containerClass = "", isLoading = false, selectedId }) => {
    const [activeTab, setActiveTab] = useState(0);

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        let index = tabs.findIndex((item) => item.tabId == selectedId);

        setActiveTab(index);
    }, []);

    return (
        <div className={`HorizontalTabsContainer ${containerClass}`}>
            <Nav tabs>
                {!isLoading &&
                    tabs.map((singleTabsContentArray, key) => {
                        return (
                            <NavItem key={key}>
                                <NavLink
                                    className={classnames({ active: activeTab === key })}
                                    onClick={() => {
                                        toggle(key);
                                        singleTabsContentArray.onClick();
                                    }}
                                >
                                    <img
                                        src={singleTabsContentArray.tabTitleImg}
                                        className="tab-image rounded-circle avatar-sm"
                                    />
                                    <a className="body-text-1">{singleTabsContentArray.tabTitle}</a>
                                </NavLink>
                            </NavItem>
                        );
                    })}
            </Nav>

            <TabContent activeTab={activeTab}>
                {tabs.map((singleTab, key) => {
                    return (
                        <TabPane tabId={key} key={key}>
                            <Card>
                                <Row>
                                    <Col sm="12">
                                        <h4>{singleTab.tabContent}</h4>
                                    </Col>
                                </Row>
                            </Card>
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
};

export default HorizontalTabs;
