import React from "react";

const ChatNoDataContainer = (props) => {
    return (
        <div className="h-100 d-flex justify-content-center align-items-center">
            <p className="text-truncate mb-0 text-muted font-weight-bold">No Chat Selected</p>
        </div>
    );
};

export default ChatNoDataContainer;
