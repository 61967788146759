import React from "react";
import Dropzone from "react-dropzone";
import { Loader } from "../../components/Loader";
import "./ProfileUpload.scss";

const ProfileUpload = ({
    avatarSize = "xl",
    isLoading = false,
    loaderSize = "md",
    uploadedImg = require("../../assets/images/realuser2.png"),
    handleAcceptedFiles
}) => {
    const cameraIcon = require("../../assets/images/camera.svg");
    return (
        <div className="profile-upload-container">
            <div className="hc-upload-img d-flex justify-content-center mb-5">
                <span
                    className={`avatar-${avatarSize} rounded-circle position-relative bg-light align-items-center justify-content-center d-flex`}
                >
                    {uploadedImg && (
                        <img
                            src={uploadedImg}
                            className={`avatar-${avatarSize} rounded-circle position-relative bg-light align-items-center justify-content-center d-flex`}
                            alt=""
                        />
                    )}
                    <div className={`hc-upload-img-btn ${isLoading && "widthForLoader"}`}>
                        <Dropzone
                            multiple={false}
                            onDrop={(acceptedFiles) => {
                                handleAcceptedFiles(acceptedFiles);
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div>
                                    {!isLoading ? (
                                        <img
                                            src={cameraIcon}
                                            alt=""
                                            {...getRootProps()}
                                            className="cameraIcon"
                                        />
                                    ) : (
                                            <Loader
                                                size={loaderSize}
                                                classes="loader-center cameraIcon ml-1"
                                            />
                                        )}
                                    <input className="hc-upload-input-file" {...getInputProps()} />
                                </div>
                            )}
                        </Dropzone>
                    </div>
                </span>
            </div>
        </div>
    );
};

export default ProfileUpload;
