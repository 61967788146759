import { fetchUtil } from "../../utils/fetchUtil";
import { useDispatch, useSelector } from "/";

export const UPDATE_USER = "UPDATE_USER";
export const changePassword = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        body: JSON.stringify(data),
        method: "POST",
        url: "/user/matchmaker/change-password",
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const updateProfile = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    let payload = { ...data };
    delete payload.MediaUrl;
    return fetchUtil({
        body: JSON.stringify(payload),
        method: "PATCH",
        url: "/user/matchmaker/update-profile",
        token: token
    })
        .then((res) => {
            dispatch({ type: UPDATE_USER, payload: { User: data } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
