import React from "react";
import { withNamespaces } from "react-i18next";

import ModalGroup from "./ModalAssets/Groups/Groups";
import ModalFooter from "./ModalAssets/Footer/Footer";
import RadioButton from "../../components/RadioButton/RadioButton";
import CustomButton from "../../components/Button/Button";

import "./BasicModal.scss";

const LogoutModal = (props) => {
    const { isConfirmLoading, title } = props;
    return (
        <>
            <ModalGroup
                title={props.t(`${title ? title : `Are you sure you want to log out?`}`)}
                titleClassDiv="m-b-xxl m-t-xxl"
            />

            <ModalFooter>
                <div className="d-flex justify-content-around flex-row">
                    <div>
                        <CustomButton
                            className="button-type-3 reduce-radius btn-md"
                            isLoading={isConfirmLoading}
                            onClick={() => {
                                props.handleConfirm();
                            }}
                        >
                            <span className="h6 color-white">{props.t("Yes")}</span>
                        </CustomButton>
                    </div>
                    <div>
                        <CustomButton
                            className="button-type-3 reduce-radius btn-md"
                            onClick={props.handleCancel}
                        >
                            <span className="h6 color-white">{props.t("No")}</span>
                        </CustomButton>
                    </div>
                </div>
            </ModalFooter>
        </>
    );
};

export default withNamespaces()(LogoutModal);
