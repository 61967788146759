import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Switch, Route } from "react-router-dom";
import "./Chats.scss";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ChatUserList from "./ChatUserList";
import ChatMessageSection from "./ChatMessageSection";
import ChatNoDataContainer from "./ChatNoDataContainer";
import ChatNavTabs from "../Chats/ChatNavTabs";
import ChatNavTabsItem from "../Chats/ChatNavTabsItem";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { ChatType, ChatListType } from "../../constants";
import { useDispatch, connect } from "react-redux";
import { CountBadge } from "../../components/CountBadge";
import { objectToArray } from "../../utils/commonUtils";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";

const groupChatIcon = require("../../assets/images/multi-user.svg");
const groupChatIconActive = require("../../assets/images/multi-user-active.svg");
const singleChatIcon = require("../../assets/images/single-user.svg");

const PreviousChat = (props) => {
    const { chats, fetching } = props;
    const [chatType, setChatType] = useState(ChatType.Group);
    const [isSidebarActive, setIsSidebarActive] = useState(false);

    const handleGroupChat = () => {
        setChatType(ChatType.Group);
    };

    return (
        <LayoutWrapper isBackIcon={false} chatLayout={true}>
            <div className="hc-chat-container">
                <div className="hc-chat-layout h-100 d-flex flex-row">
                    <div
                        className={`chat-nav-container h-100 bg-white ${isSidebarActive ? "active" : ""
                            }`}
                    >
                        <Switch>
                            <Route
                                path="/dashboard/previous-chat/:id?"
                                render={(props) => (
                                    <ChatUserList
                                        {...props}
                                        chatTab={chatType}
                                        chatURL="/dashboard/previous-chat/"
                                        chatListingType={ChatListType.Ended}
                                    />
                                )}
                            />
                        </Switch>
                        <div className="chat-tabs-wrapper p-v-xs">
                            <Row>
                                <Col>
                                    <div className="tab-wrapper d-flex align-items-center justify-content-center">
                                        <div className="position-relative d-inline-flex flex-column align-items-center">
                                            <img
                                                src={groupChatIconActive}
                                                alt="group"
                                                onClick={handleGroupChat}
                                            />
                                            <span className={`tab-text body-text-12 tab-text-active`}>
                                                {props.t("group chats")}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <Switch>
                        <Route
                            path="/dashboard/previous-chat/:id"
                            render={(props) => (
                                <ChatMessageSection
                                    onHamburgerClick={() => setIsSidebarActive(!isSidebarActive)}
                                    onBackdropClick={() => setIsSidebarActive(!isSidebarActive)}
                                    isSidebarActive={isSidebarActive}
                                    {...props}
                                    chatTab={chatType}
                                    previousChatInput={false}
                                />
                            )}
                        />
                        <Route component={ChatNoDataContainer} />
                    </Switch>
                </div>
            </div>
        </LayoutWrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        fetching: state.chat.fetching,
        chats: state.entities.chats
    };
};

export default connect(mapStateToProps, null)(PreviousChat);
