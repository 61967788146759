import React from "react";

import "./InfoModal.scss";
const InfoModalHeader = ({ title = "Chat Info", onClose }) => {
    const crossIcon = require("../../../assets/images/modal-cross.svg");
    return (
        <div className="info-modal-header position-relative d-flex align-items-center justify-content-center">
            <h1 className="m-0">{title}</h1>
            <span className="position-absolute" onClick={onClose}>
                <img src={crossIcon} alt="cross" />
            </span>
        </div>
    );
};
export default InfoModalHeader;
