import React, { Component } from "react";
import { Container } from "reactstrap";
import { withNamespaces } from "react-i18next";

import Breadcrumbs from "../../components/Common/Breadcrumb";

class Dashboard extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.props.t("Dashboard")}
                            breadcrumbItem={this.props.t("Dashboard")}
                        />
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Dashboard);
