import io from "socket.io-client";
import Config from "../Config";
import { store } from "../store";
import { logger } from "../utils/loggerUtils";
import { changeSocketConnectivityStatus } from "../store/actions";

export const SocketTypes = {
    CHAT_START: "chat_start",
    CHAT_JOIN: "chat_join",
    MESSAGE: "message",
    READ_MESSAGE: "read_message",
    GUARDIAN_ADDED: "guardian_added",
    GUARDIAN_REMOVED: "guardian_removed",
    MATCH_STATUS_CHANGED: "match_status_changed",
    NEW_USER_ID_VERIFICATION: "new_user_id_verification"
};

export class Socket {
    static socket = null;
    static getSocket = () => {
        if (Socket.socket === null) {
            const userId = store.getState().auth.user.Id;
            const authToken = store.getState().auth.user.Token;
            Socket.socket = io(`${Config.env().API_URL}?authorization=${authToken}`, {
                transports: ["websocket"],
                upgrade: false,
                reconnection: true,
                reconnectionAttempts: Infinity,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000
            });
        }
        return Socket.socket;
    };

    static init = () => {
        Socket.getSocket().on("connect", () => {
            logger("socket connected!");
            store.dispatch(changeSocketConnectivityStatus(true));
        });

        Socket.getSocket().on("connect-error", (err) => {
            logger("socket connection error", err);
        });

        Socket.getSocket().on("error", (error) => {
            logger("socket error", error);
            // if (error.type === 401) {
            //     toaster({ title: i18n("SessionExpired"), type: "danger" });
            //     localStorage.clear();
            //     store.dispatch({ type: AUTH_FAIL });
            //     Socket.socket = null;
            // }
        });

        Socket.getSocket().on("disconnect", (reason) => {
            logger("socket disconnected", reason);
            store.dispatch(changeSocketConnectivityStatus(false));
        });
    };

    static onNewUserJoined = (cb) => {
        Socket.getSocket().on(SocketTypes.NEW_USER_ID_VERIFICATION, cb);
    };

    static onMessageRecieved = (cb) => {
        Socket.getSocket().on(SocketTypes.MESSAGE, cb);
    };

    static onChatStarted = (cb) => {
        Socket.getSocket().on(SocketTypes.CHAT_START, cb);
    };

    static onGuardianAdded = (cb) => {
        Socket.getSocket().on(SocketTypes.GUARDIAN_ADDED, cb);
    };

    static onGuardianRemoved = (cb) => {
        Socket.getSocket().on(SocketTypes.GUARDIAN_REMOVED, cb);
    };

    static chatStart = (userId, cb) => {
        Socket.getSocket().emit(
            SocketTypes.CHAT_START,
            {
                UserId: userId
            },
            cb
        );
    };
    static joinChat = (chatId) => {
        Socket.getSocket().emit(SocketTypes.CHAT_JOIN, {
            ChatId: chatId
        });
    };

    static readMessage = (chatId) => {
        Socket.getSocket().emit(SocketTypes.READ_MESSAGE, {
            ChatId: chatId
        });
    };

    static sendMessage = (data, cb) => {
        Socket.getSocket().emit(
            SocketTypes.MESSAGE,
            {
                ...data
            },
            cb
        );
    };

    static disconnect = () => {
        Socket.getSocket().disconnect();
        Socket.socket = null;
    };

    static remove = (name, listener = null) => {
        if (Socket.socket) {
            if (listener) {
                Socket.getSocket().removeListener(name, listener);
            } else {
                Socket.getSocket().removeAllListeners(name);
            }
        }
    };
}
