import React from "react";

const DataTile = (props) => {
    const { title, data, titleClasses = "mb-2", containerClasses = "mb-2" } = props;

    return (
        <div className={"hc-user-detail-info d-flex " + containerClasses}>
            <span className={"hc-user-detail-pill-head body-text-6 " + titleClasses}>{title}</span>
            <span className="ml-1 mr-1">:</span>
            <span className="hc-user-detail-info-desc body-text-6">{data}</span>
        </div>
    );
};

export default DataTile;
