import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";

export const getConnectedUsers = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        url: appendQueryParams("/user/user-connection-list", { ...data }),
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
