import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { Switch, Route } from "react-router-dom";
import "./Chats.scss";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ChatUserList from "./ChatUserList";
import ChatMessageSection from "./ChatMessageSection";
import ChatNoDataContainer from "./ChatNoDataContainer";
import ChatNavTabs from "../Chats/ChatNavTabs";
import ChatNavTabsItem from "../Chats/ChatNavTabsItem";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { ChatType } from "../../constants";
import { useSelector } from "react-redux";
import { useDispatch, connect } from "react-redux";
import { CountBadge } from "../../components/CountBadge";
import { getChatUnReadCount } from "../../store/actions/chatActions";
import { objectToArray } from "../../utils/commonUtils";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";

const groupChatIcon = require("../../assets/images/multi-user.svg");
const groupChatIconActive = require("../../assets/images/multi-user-active.svg");
const singleChatIcon = require("../../assets/images/single-user.svg");
const singleChatIconActive = require("../../assets/images/single-user-active.svg");

const Chat = (props) => {
    const { chats, fetching } = props;
    const dispatch = useDispatch();
    const chat = useSelector((state) => state.chat);
    const [chatType, setChatType] = useState(
        props.location?.state?.ChatType ? props.location?.state?.ChatType : ChatType.Group
    );
    const [isSidebarActive, setIsSidebarActive] = useState(false);

    const chatAbortController = useRef(null);

    const handleGroupChat = () => {
        setChatType(ChatType.Group);
    };

    const handleIndividualChat = () => {
        setChatType(ChatType.Individual);
    };

    useEffect(() => {
        chatAbortController.current = new AbortController();
        dispatch(getChatUnReadCount(chatAbortController.current.signal));

        return () => {
            if (chatAbortController.current) {
                chatAbortController.current.abort();
            }
        };
    }, []);

    return (
        <LayoutWrapper isBackIcon={false} chatLayout={true}>
            <div className="hc-chat-container">
                <div className="hc-chat-layout h-100 d-flex flex-row">
                    <div
                        className={`chat-nav-container h-100 bg-white ${
                            isSidebarActive ? "active" : ""
                        }`}
                    >
                        <Switch>
                            <Route
                                path="/dashboard/chat/:id?"
                                render={(props) => <ChatUserList {...props} chatTab={chatType} />}
                            />
                        </Switch>
                        <div className="chat-tabs-wrapper p-v-xs">
                            <Row>
                                <Col>
                                    <div className="tab-wrapper d-flex align-items-center justify-content-center">
                                        <div className="position-relative d-inline-flex flex-column align-items-center">
                                            <img
                                                src={
                                                    chatType === ChatType.Group
                                                        ? groupChatIconActive
                                                        : groupChatIcon
                                                }
                                                alt="group"
                                                onClick={handleGroupChat}
                                            />
                                            <span
                                                className={`tab-text body-text-12 ${
                                                    chatType === ChatType.Group && "tab-text-active"
                                                }`}
                                            >
                                                {props.t("group chats")}
                                            </span>
                                            {chat.unReadGroupCount > 0 && (
                                                <CountBadge count={chat.unReadGroupCount} />
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="tab-wrapper d-flex align-items-center justify-content-center">
                                        <div className="position-relative d-inline-flex flex-column align-items-center">
                                            <img
                                                src={
                                                    chatType === ChatType.Individual
                                                        ? singleChatIconActive
                                                        : singleChatIcon
                                                }
                                                alt="user"
                                                onClick={handleIndividualChat}
                                            />
                                            <span
                                                className={`tab-text body-text-12 ${
                                                    chatType === ChatType.Individual &&
                                                    "tab-text-active"
                                                }`}
                                            >
                                                {props.t("Single chats")}
                                            </span>
                                            {chat.unReadSingleCount > 0 && (
                                                <CountBadge count={chat.unReadSingleCount} />
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <Switch>
                        <Route
                            path="/dashboard/chat/:id"
                            render={(props) => (
                                <ChatMessageSection
                                    onHamburgerClick={() => setIsSidebarActive(!isSidebarActive)}
                                    onBackdropClick={() => setIsSidebarActive(!isSidebarActive)}
                                    isSidebarActive={isSidebarActive}
                                    {...props}
                                    chatTab={chatType}
                                />
                            )}
                        />
                        <Route component={ChatNoDataContainer} />
                    </Switch>
                </div>
            </div>
        </LayoutWrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        fetching: state.chat.fetching,
        chats: state.entities.chats
    };
};

export default connect(mapStateToProps, null)(Chat);
