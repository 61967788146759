import React, { useState } from "react";
import { withNamespaces } from "react-i18next";

import ModalGroup from "./ModalAssets/Groups/Groups";
import CustomButton from "../../components/Button/Button";
import RadioButton from "../../components/RadioButton/RadioButton";

import "./BasicModal.scss";

const SuspendModal = (props) => {
    const [data, setData] = useState(null);
    const [isRemarksOption, setIsRemarksOption] = useState(true);
    const [isDaysOption, setIsDaysOption] = useState(true);
    const { remarksOptionArray, daysOptionArray } = props;
    const onChangeRemarks = (event) => {
        setData({
            ...data,
            remarksOption: event.currentTarget.value
        });
        setIsRemarksOption(true);
    };
    const onChangeDays = (event) => {
        setData({
            ...data,
            dayOption: event.currentTarget.value
        });
        setIsDaysOption(true);
    };
    const onChangeComments = (event) => {
        if (event.currentTarget.value) {
            setData({ ...data, comments: event.currentTarget.value });
        }
    };

    return (
        <span>
            <ModalGroup title={props.t("Please select between remarks")}>
                <div className="radio-button-container">
                    <div className="d-flex flex-row justify-content-between radio-button-row flex-wrap">
                        {remarksOptionArray.map((singleRadio, key) => {
                            return (
                                <div className="radio-items w-50" key={key}>
                                    <RadioButton
                                        radioName={singleRadio.radioName}
                                        label={singleRadio.label}
                                        value={singleRadio.value}
                                        difference={key}
                                        onChange={onChangeRemarks}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {!isRemarksOption && (
                        <div
                            className={
                                !isRemarksOption &&
                                "custom-inp-error-text justify-content-between radio-button-row radio-button-row-reduce-padding"
                            }
                        >
                            {props.t("Please select remarks")}
                        </div>
                    )}
                </div>
            </ModalGroup>
            <ModalGroup title={props.t("What will be the duration of this suspention?")}>
                <div className="radio-button-container">
                    <div className="d-flex flex-row justify-content-between radio-button-row flex-wrap">
                        {daysOptionArray.map((singleRadio, key) => {
                            return (
                                <div className="radio-items w-50" key={key}>
                                    <RadioButton
                                        radioName={singleRadio.radioName}
                                        label={singleRadio.label}
                                        value={singleRadio.value}
                                        difference={key}
                                        onChange={onChangeDays}
                                    />
                                </div>
                            );
                        })}
                        <div className="h6 color-secondary m-t-lg m-b-sm">
                            {props.t("Warning Remarks Message")}
                        </div>
                    </div>
                    {!isDaysOption && (
                        <div
                            className={
                                !isDaysOption &&
                                "custom-inp-error-text justify-content-between radio-button-row radio-button-row-reduce-padding"
                            }
                        >
                            {props.t("Please select days")}
                        </div>
                    )}
                </div>
            </ModalGroup>
            <ModalGroup title={props.t("Please type your remarks below:")}>
                <div className="radio-button-container text-center">
                    <div className="d-flex flex-column justify-content-between radio-button-row flex-wrap radio-button-row-reduce-padding">
                        <div className="form-group">
                            <textarea
                                className="form-control"
                                name=""
                                id=""
                                rows="3"
                                maxlength="1000"
                                onChange={onChangeComments}
                            ></textarea>
                        </div>
                        <CustomButton
                            className="btn btn-xs button-type-3 reduce-radius "
                            onClick={() => {
                                if (data == null || !data.remarksOption || !data.dayOption) {
                                    if (data != null) {
                                        if (!data.remarksOption) {
                                            setIsRemarksOption(false);
                                        }
                                        if (!data.dayOption) {
                                            setIsDaysOption(false);
                                        }
                                    } else {
                                        setIsRemarksOption(false);
                                        setIsDaysOption(false);
                                    }
                                } else {
                                    props.onClick(data);
                                }
                            }}
                        >
                            {props.t("Send")}
                        </CustomButton>
                    </div>
                </div>
            </ModalGroup>
        </span>
    );
};

export default withNamespaces()(SuspendModal);
