import { parsePhoneNumberFromString } from "libphonenumber-js";
import notificationToastr from "./toastUtil";

const EMAIL_MAXIMUM_LENGTH = 254;

export const validateEmail = email => {
    let re = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
    if (re.test(email) && email.length < EMAIL_MAXIMUM_LENGTH) {
        return true;
    }
    notificationToastr.error("InvalidEmail");
    return false;
};

export const validatePhone = phone => {
    let parsedNumber = parsePhoneNumberFromString(phone);

    // let pattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    // let pattern = /^\d+$/g;
    if (parsedNumber && parsedNumber.isValid()) {
        return true;
    } else {
        notificationToastr.error("InvalidPhone");
        return false;
    }
};

export const isNumber = value => {
    let re = /^\+?[0-9\b]*$/g;
    return re.test(value);
};
