import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Loader from "../Loader/Loader";
import './VerticalTabs.scss';

const VerticalTabs = ({ tabs, footerImg, isDivider = true, isTabIcon = true, isUpdating, isNotification = false, tabTitleSpace = false }) => {
    return (
        <Tabs className={`vertical-tabs-container ${isNotification && 'is-notification'}`}>
            {isUpdating && <Loader fullPageLoader={true} />}
            <div className="row mr-0">
                <div className={`col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 `}>
                    <TabList>
                        {tabs.map((singleTab, key) => {
                            return (
                                <Tab className={`tabs-global ${!isDivider ? 'no-tabs-borders' : 'react-tabs__tab'} ${key == 0 ? tabTitleSpace && 'pt-5' : null}`}>
                                    <div className="tab-title">
                                        <div className="tab-title-content">
                                            <p>{singleTab.tabTitle}</p>
                                        </div>
                                        <div className="tabs-icon">
                                            {isTabIcon && <i className="dripicons-chevron-right"></i>}
                                        </div>
                                    </div>
                                </Tab>
                            );
                        })}
                    </TabList>
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 div-scroll">
                    {tabs.map((singleTab) => {
                        return (
                            <TabPanel>
                                <div className="panel-content d-flex flex-column justify-content-between">
                                    {singleTab.tabContent}
                                    <div className="panel-footer">
                                        <div className="panel-footer-img">
                                            <img src={footerImg} />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        );
                    })}
                </div>
            </div>
        </Tabs>
    )
};

export default VerticalTabs;