import React from "react";
import { withNamespaces } from "react-i18next";
import "./Chats.scss";

const ChatNavListing = (props) => {
    const { children } = props;
    return <ul className="chat-nav-list pl-0 mb-0 ">{children}</ul>;
};

export default withNamespaces()(ChatNavListing);
