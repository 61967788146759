import React from 'react';
import { TooltipBody, TooltipContent } from './TooltipDesignMain.styles';
import TooltipDesignSecond from './TooltipDesignSecond';
import TooltipDesign from './TooltipDesign';
import ABOUT_US_IMAGE_2 from "../../../../assets/images/about-us/image-2.png";
import TooltipCustomCard from './TooltipCustomCard';
import toolTipConstant from './constant';
const message_chat = require("../../../../assets/images/icons-svg/message-circle.svg");
const users = require("../../../../assets/images/icons-svg/users.svg");
const alert_triangle = require("../../../../assets/images/icons-svg/alert-triangle.svg");
const chat_arrow = require("../../../../assets/images/icons-svg/chat-arrow.svg");
const settings = require("../../../../assets/images/icons-svg/settings.svg");
const log_in = require("../../../../assets/images/icons-svg/log-in.svg");
const notification_image = require("../../../../assets/images/icons-svg/notification-filled.svg");

const TooltipDesignMain = ({ backProps, primaryProps, tooltipProps, index, continuous, cardMaxWidth = "", designtype, cardPadding, cardBlur, skipProps }) => {
    return (
        <TooltipBody {...tooltipProps} maxWidth={cardMaxWidth} cardBlur={cardBlur}>
            <TooltipContent cardPadding={cardPadding}>
                {designtype == toolTipConstant.step1 && (
                    <TooltipDesignSecond
                        title="Welcome to Halal Connections!"
                        description="Let us explain all the features we have designed for you."
                        isImg={ABOUT_US_IMAGE_2}
                        imgWidth="7.625rem"
                        primaryProps={primaryProps}
                        skipProps={skipProps}
                        isSkipButton={true}
                    />
                )}
                {designtype == toolTipConstant.step2 && (
                    <TooltipDesign
                        title="Users"
                        description="In this area you can find the profileof the “Individuals” you are assisting in a layout like this one:
                        Copy"
                        childrenCard={<TooltipCustomCard />}
                        RightImage={users}
                        primaryProps={primaryProps}
                        backProps={backProps}
                        footerLayoutTwo={true}
                        middleContentWidth="85%"
                        stepTwoAlignSelf="center"
                    />
                )}
                {designtype == toolTipConstant.step3 && (
                    <TooltipDesign
                        title="Active Chats"
                        description="In this area you can find all the chats you are part of."
                        RightImage={message_chat}
                        primaryProps={primaryProps}
                        backProps={backProps}
                    />
                )}
                {designtype == toolTipConstant.step4 && (
                    <TooltipDesign
                        title="Reports"
                        description="In this area you can notify us if you are expreriencing any abuse or wrong behaviour."
                        RightImage={alert_triangle}
                        primaryProps={primaryProps}
                        backProps={backProps}
                    />
                )}
                {designtype == toolTipConstant.step5 && (
                    <TooltipDesign
                        title="Previous Chats "
                        description="In this area you can find all the chats you were part of."
                        RightImage={chat_arrow}
                        primaryProps={primaryProps}
                        backProps={backProps}
                    />
                )}
                {designtype == toolTipConstant.step6 && (
                    <TooltipDesign
                        title="Settings"
                        description="In this area you will find, Account, Notifications, Help and About Us"
                        RightImage={settings}
                        leftIconPosition="flex-end"
                        primaryProps={primaryProps}
                        backProps={backProps}
                    />
                )}
                {designtype == toolTipConstant.step7 && (
                    <TooltipDesign
                        title="Log out"
                        description="An easy way to leave the platform"
                        RightImage={log_in}
                        leftIconPosition="flex-end"
                        primaryProps={primaryProps}
                        middleContentPosition="flex-end"
                        backProps={backProps}
                    />
                )}
                {designtype == toolTipConstant.step8 && (
                    <TooltipDesign
                        title="Notifications"
                        description="You will see a pink dot next to the bell 
                        everytime you have a new alert"
                        RightImage={notification_image}
                        RightIcon="fas fa-chevron-up"
                        isLeftIcon={false}
                        primaryProps={primaryProps}
                        backProps={backProps}
                        isNotificationCount
                    />
                )}
                {designtype == toolTipConstant.step9 && (
                    <TooltipDesign
                        title="Users"
                        description={(<span>In this space, you can see all the candidates current connections, and current referrals, each candidate has the right to have no more than <strong className="body-text-3">3</strong> connections at the same time.</span>)}
                        RightIcon="fas fa-chevron-up"
                        isLeftIcon={false}
                        primaryProps={primaryProps}
                        footerLayoutTwo={true}
                        middleContentWidth="100%"
                        backProps={backProps}
                    />
                )}
                {designtype == toolTipConstant.step10 && (
                    <TooltipDesignSecond
                        title="You are ready to go!"
                        description="Now is time to create real connection for real people, good luck!"
                        isImg={ABOUT_US_IMAGE_2}
                        imgWidth="11.625rem"
                        primaryProps={primaryProps}
                        skipProps={skipProps}
                        isSkipButton={false}
                        isNextButton={false}
                        isCloseButton={true}
                        titleClass="text-center"
                    />
                )}
            </TooltipContent>
        </TooltipBody>
    )
};

export default TooltipDesignMain;