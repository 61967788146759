import React from "react";
import BlockButton from "../../../components/Button/Button";
import { Loader } from "../../../components/Loader";
import "../ReportDetail.scss";

const InappropriateLanguage = ({
    containerClass,
    heading,
    listing,
    btnClick,
    btnText,
    btnIcon,
    btnClases,
    fullWidth,
    loaderSize = "sm",
    btnMenus,
    isLoading
}) => {
    return (
        <div className={`${containerClass}  mb-4 mb-sm-0 mb-md-0 mb-lg-0 `}>
            <h1 className="mb-5 color-black">{heading}</h1>
            <div>
                <div className="row">
                    <div
                        className={
                            fullWidth
                                ? `col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12`
                                : `col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6`
                        }
                    >
                        {listing &&
                            listing.map((singleListing, key) => {
                                return (
                                    <h5 key={key} className="color-black mb-2">
                                        {singleListing.name}{" "}
                                        <p className="d-inline-block">{singleListing.value}</p>
                                    </h5>
                                );
                            })}
                    </div>
                    {btnText && (
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <BlockButton
                                className={`button-type-5 btn-xs reduce-radius ${btnClases}`}
                                onClick={btnClick}
                                btnIcon={btnIcon}
                                btnMenus={btnMenus}
                                isLoading={isLoading}
                            >
                                {btnText}
                            </BlockButton>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InappropriateLanguage;
