import React from "react";

import "./ToggleSwitch.scss";
const ToggleSwitch = ({
    onClick,
    checked = true,
    labelText,
    id = "1",
    name = "",
    containerClass = ""
}) => {
    return (
        <div
            className={`hc-toggle-switch-container custom-control custom-switch ${containerClass}`}
        >
            <input
                className="hc-toggle-switch custom-control-input"
                name={name}
                type="checkbox"
                id={id}
                onClick={onClick}
                checked={checked}
            />
            <label htmlFor={id} className="hc-toggle-switch-label custom-control-label ">
                {labelText && <span className="ml-3">{labelText}</span>}
            </label>
        </div>
    );
};

export default ToggleSwitch;
