import { fetchUtil } from "../../utils/fetchUtil";

export const createProfile = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        body: JSON.stringify(data),
        method: "POST",
        url: "/user/matchmaker/create-profile",
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
