import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import ABOUT_US_IMAGE_1 from "../../assets/images/about-us/image-1.png";
import ABOUT_US_IMAGE_2 from "../../assets/images/about-us/image-2.png";
import ABOUT_US_IMAGE_3 from "../../assets/images/about-us/image-3.png";
import "./AboutUs.scss";

class AboutUs extends Component {
    render() {
        return (
            <LayoutWrapper className="about-us-page">
                <button className="btn"></button>
                <div className="about-us-container">
                    <div className="d-flex justify-content-between flex-row flex-edited-1">
                        <div>
                            <h1 className="about-us-heading">
                                {this.props.t("Welcome to Halal Connections!")}
                            </h1>
                            <h6>{this.props.t("About us Halal Connection First Intro")}</h6>
                        </div>
                        <div>
                            <img src={ABOUT_US_IMAGE_1} />
                        </div>
                    </div>
                    <div className="divider"> ;</div>
                    <div className="d-flex justify-content-between flex-row flex-edited-2">
                        <div>
                            <img src={ABOUT_US_IMAGE_2} />
                        </div>
                        <div>
                            <h1 className="about-us-heading">{this.props.t("Our Vision")}</h1>
                            <h6>{this.props.t("About us Halal Connection Vision")}</h6>
                        </div>
                    </div>
                    <div className="divider"> ;</div>
                    <div className="d-flex justify-content-between flex-row flex-edited-1">
                        <div>
                            <h1 className="about-us-heading">
                                {this.props.t("Executive Summary")}
                            </h1>
                            <h6>{this.props.t("About us Halal Connection Executive Summary")}</h6>
                        </div>
                        <div>
                            <img src={ABOUT_US_IMAGE_3} />
                        </div>
                    </div>
                </div>
            </LayoutWrapper>
        );
    }
}

export default withNamespaces()(AboutUs);
