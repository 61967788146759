import React, { Component } from "react";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import Tour from "../Tour/Tour";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import { APP_ROUTES } from "../../helpers/routeHelpers";

import megamenuImg from "../../assets/images/megamenu-img.png";
// import logo from "../../assets/images/logo.svg";
import logo from "../../assets/images/logo-for-headers.png";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
// import logoDark from "../../assets/images/logo-dark.png";
import logoDark from "../../assets/images/logo-for-headers.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

//i18n
import { withNamespaces } from "react-i18next";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            isShowtour: null
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
    }
    /**
     * Toggle sidebar
     */
    toggleMenu() {
        this.props.toggleMenuCallback();
    }

    toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    componentDidMount() {
        const tourLocalStorage = localStorage.getItem("tour") == null ? true : false;
        this.setState({
            isShowtour: tourLocalStorage
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isShowtour && <Tour run={true} />}
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <Link to={APP_ROUTES.USER_LISTING} className="logo logo-dark">
                                    <span>
                                        <img src={logo} alt="" />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span>
                                        <img src={logoLightSvg} alt="" />
                                    </span>
                                </Link>
                            </div>
                            <div>
                                <Link to={APP_ROUTES.USER_LISTING}>
                                    <h2 className="logo-text">
                                        {this.props.t("HALAL CONNECTIONS")}
                                        <span className="logo-subtext">
                                            {this.props.t("REAL CONNECTIONS FOR REAL PEOPLE")}
                                        </span>
                                    </h2>
                                </Link>
                            </div>
                        </div>
                        <div className="d-flex">
                            <NotificationDropdown />
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, null)(withNamespaces()(Header));
