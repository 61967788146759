import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import "./DataTable.scss";

const FilterCell = (props) => {
    const { options } = props;
    const { title, filterValue, filterConstants, onFilterSelect } = options;
    const [open, setOpen] = useState(false);

    const handleMenuToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleItemSelect = (value) => {
        onFilterSelect(value);
    };

    return (
        <Dropdown isOpen={open} toggle={handleMenuToggle}>
            <DropdownToggle tag="div" className="text-muted data-table-head sortable">
                {title}
                {filterValue && (
                    <span className="filter-value">
                        ({filterConstants.getDisplayTextKey(filterValue)})
                    </span>
                )}
                <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => handleItemSelect(null)} active={!filterValue}>
                    All
                </DropdownItem>
                {Object.keys(filterConstants).map((key, index) => {
                    return (
                        <DropdownItem
                            key={index}
                            onClick={() => handleItemSelect(filterConstants[key])}
                            active={filterValue === filterConstants[key]}
                        >
                            {filterConstants.getDisplayTextKey(filterConstants[key])}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    );
};

export default FilterCell;
