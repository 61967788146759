import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import ReactReadMoreReadLess from "react-read-more-less";
import Accordion from "../../components/Accordion/Accordion";
import { Row, Col } from "reactstrap";
import BlockButton from "../../components/Button/Button";
import HorizontalTabs from "../../components/HorizontalTabs/HorizontalTabs";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { useDispatch } from "react-redux";
import notificationToastr from "../../utils/toastUtil";
import { SkeletonLoader } from "../../components/Loader";
import {
    getCurrentLivingStatus,
    getEducationLevel,
    getHalalPreference,
    getMaritalStatus,
    getPrayerRoutine,
    getSect,
    getWorkingSituation
} from "../../common/common";
import { useParams } from "react-router-dom";
import { convertInches } from "../../utils/commonUtils";
import { getConnectedUsers } from "../../store/actions";
import AccordionInnerListing from "../CompareProfile/AccordionInnerListing";
import "./Chats.scss";

const SeeConnections = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [usersDetail, setUsersDetail] = useState([]);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [suggestionOpenTab, setSuggestionOpenTab] = useState(null);
    const [selectedUserDetails, setSelectedUserDetails] = useState({});
    const tabs = {
        Personal: 1,
        Professional: 2,
        Religious: 3,
        PersonalityTraits: 4,
        Preferences: 5
    };

    useEffect(() => {
        setIsFetchingData(true);

        dispatch(getConnectedUsers({ UserId: id }))
            .then((res) => {
                setUsersDetail(res.Data.Users);
                setSelectedUserDetails(res.Data.Users[0]);
                setIsFetchingData(false);
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
                setIsFetchingData(false);
            });
    }, []);

    const tabConst = () => {
        return (
            <>
                <h1 className="text-center color-secondary mt-5">
                    About {selectedUserDetails.Name}
                </h1>
                <p className="my-5 color-black px-2">
                    <ReactReadMoreReadLess
                        charLimit={200}
                        readMoreText={"Read more "}
                        readLessText={"Read less "}
                    >
                        {selectedUserDetails.About}
                    </ReactReadMoreReadLess>
                </p>
                <Accordion
                    isOpen={suggestionOpenTab === tabs.Personal}
                    onCollapse={(e) => {
                        suggestionOpenTab === tabs.Personal
                            ? setSuggestionOpenTab(null)
                            : setSuggestionOpenTab(tabs.Personal);
                    }}
                    headTitle="Personal"
                    containerClass="my-3"
                    cardClass="hc-accordion-body-no-shadow reduce-card-padding"
                >
                    <Row>
                        <Col xs={12} sm={12}>
                            <AccordionInnerListing
                                contents={[
                                    {
                                        title: "Nationality",
                                        content: selectedUserDetails.EthinicOrigin
                                    },
                                    {
                                        title: "Age",
                                        content: selectedUserDetails.Age
                                    },
                                    {
                                        title: "Height",
                                        content: convertInches(getSelectedUserPersonality("Height"))
                                    },
                                    {
                                        title: "Current location",
                                        content: `${getSelectedUserPersonality("Location")?.City
                                            }, ${getSelectedUserPersonality("Location")?.Country}`
                                    },
                                    {
                                        title: "Languages",
                                        content: getSelectedUserPersonality("Languages").join(", ")
                                    },
                                    {
                                        title: "Marital Status",
                                        content: getMaritalStatus(
                                            getSelectedUserPersonality("MaritalStatus")
                                        )
                                    },
                                    {
                                        title: "Children",
                                        content: getSelectedUserPersonality("Children")
                                    },
                                    {
                                        title: "Current living situation",
                                        content: getCurrentLivingStatus(
                                            getSelectedUserPersonality("CurrentLivingSituation")
                                        )
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                </Accordion>
                <Accordion
                    isOpen={suggestionOpenTab === tabs.Professional}
                    onCollapse={(e) => {
                        suggestionOpenTab === tabs.Professional
                            ? setSuggestionOpenTab(null)
                            : setSuggestionOpenTab(tabs.Professional);
                    }}
                    headTitle="Professional"
                    containerClass="my-3"
                    cardClass="hc-accordion-body-no-shadow reduce-card-padding"
                >
                    <Row>
                        <Col xs={12} sm={12}>
                            <AccordionInnerListing
                                contents={[
                                    {
                                        title: "Occupation",
                                        content: getSelectedUserProfessional("Occupation")
                                    },
                                    {
                                        title: "Level of Education",
                                        content: getEducationLevel(
                                            getSelectedUserProfessional("EducationLevel")
                                        )
                                    },
                                    {
                                        title: "Working situation",
                                        content: getWorkingSituation(
                                            getSelectedUserProfessional("WorkingSituation")
                                        )
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                </Accordion>
                <Accordion
                    isOpen={suggestionOpenTab === tabs.Religious}
                    onCollapse={(e) => {
                        suggestionOpenTab === tabs.Religious
                            ? setSuggestionOpenTab(null)
                            : setSuggestionOpenTab(tabs.Religious);
                    }}
                    headTitle="Religious"
                    containerClass="my-3"
                    cardClass="hc-accordion-body-no-shadow reduce-card-padding"
                >
                    <Row>
                        <Col xs={12} sm={6}>
                            <AccordionInnerListing
                                contents={[
                                    {
                                        title: "Sect",
                                        content: getSect(getSelectedUserReligiousDetails("Sect"))
                                    },
                                    {
                                        title: "Prayers Routine",
                                        content: getPrayerRoutine(
                                            getSelectedUserReligiousDetails("PrayerRoutine")
                                        )
                                    },
                                    {
                                        title: "Halal Preferences",
                                        content: getHalalPreference(
                                            getSelectedUserReligiousDetails("HalalPreference")
                                        )
                                    }
                                ]}
                            />
                        </Col>
                        <Col xs={12} sm={6}>
                            <AccordionInnerListing
                                contents={[
                                    {
                                        title: "Alcohol Consumption",
                                        content: getSelectedUserReligiousDetails(
                                            "AlcoholConsumption"
                                        )
                                            ? "Yes"
                                            : "No"
                                    },
                                    {
                                        title: "Smoking",
                                        content: getSelectedUserReligiousDetails("Smoking")
                                            ? "Yes"
                                            : "No"
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                </Accordion>
                <Accordion
                    isOpen={suggestionOpenTab === tabs.PersonalityTraits}
                    onCollapse={(e) => {
                        suggestionOpenTab === tabs.PersonalityTraits
                            ? setSuggestionOpenTab(null)
                            : setSuggestionOpenTab(tabs.PersonalityTraits);
                    }}
                    headTitle="Personality Traits"
                    containerClass="my-3"
                    cardClass="hc-accordion-body-no-shadow reduce-card-padding"
                >
                    <Row>
                        <Col xs={6} sm={6}>
                            <AccordionInnerListing
                                pills={
                                    selectedUserDetails.Traits
                                        ? selectedUserDetails.Traits.map((trait) => {
                                            return { name: trait };
                                        })
                                        : null
                                }
                            />
                        </Col>
                    </Row>
                </Accordion>
                <Accordion
                    isOpen={suggestionOpenTab === tabs.Preferences}
                    onCollapse={(e) => {
                        suggestionOpenTab === tabs.Preferences
                            ? setSuggestionOpenTab(null)
                            : setSuggestionOpenTab(tabs.Preferences);
                    }}
                    headTitle="Preferences"
                    containerClass="my-3 mb-5"
                    cardClass="hc-accordion-body-no-shadow reduce-card-padding"
                >
                    <Row>
                        <Col xs={12} sm={12}>
                            <div className="mb-2">
                                <AccordionInnerListing
                                    heading="PERSONAL"
                                    contents={[
                                        {
                                            title: "Age Range",
                                            content: `${getSelectedUserPreferences("Personal")?.AgeStart
                                                } - ${getSelectedUserPreferences("Personal")?.AgeStart}`
                                        },
                                        {
                                            title: "Height Range",
                                            content: `${convertInches(
                                                getSelectedUserPreferences("Personal")?.HeightStart
                                            )} - ${convertInches(
                                                getSelectedUserPreferences("Personal")?.HeightStart
                                            )}`
                                        },
                                        {
                                            title: "Previous Marital Status",
                                            content: getSelectedUserPreferences("Personal")
                                                ?.PreviousMaritalStatus
                                                ? "Married"
                                                : "Not Married"
                                        },
                                        {
                                            title: "Family Plans",
                                            content: getSelectedUserPreferences("Personal")
                                                ?.FamilyPlans
                                                ? "Open to children"
                                                : "Not open to children"
                                        }
                                    ]}
                                />
                            </div>
                            <div className="mb-2">
                                <AccordionInnerListing
                                    heading="Professional"
                                    contents={[
                                        {
                                            title: "Work Preferences",
                                            content: `${getSelectedUserPreferences("Professional")?.Working
                                                ? "Working"
                                                : "Not working"
                                                } ${getSelectedUserPreferences("Professional")
                                                    ?.StayAtHome
                                                    ? "(Staying at home)"
                                                    : ""
                                                }`
                                        }
                                    ]}
                                />
                            </div>
                            <div className="mb-2">
                                <AccordionInnerListing
                                    heading="Religion"
                                    contents={[
                                        {
                                            title: "Sect",
                                            content: getSelectedUserPreferences("Religious")?.Sect
                                                ? "Open to different sect"
                                                : getSect(
                                                    selectedUserDetails.ReligiousDetails?.Sect
                                                )
                                        },
                                        {
                                            title: "Prayers Routine",
                                            content: getSelectedUserPreferences("Religious")
                                                ?.PrayerRoutine
                                                ? "Same"
                                                : ""
                                        },
                                        {
                                            title: "Halal Preferences",
                                            content: getHalalPreference(
                                                getSelectedUserPreferences("Religious")
                                                    ?.HalalPreference
                                            )
                                        },
                                        {
                                            title: "Alcohol Consumption",
                                            content: getSelectedUserPreferences("Religious")
                                                ?.AlcoholConsumption
                                                ? "Yes"
                                                : "No"
                                        },
                                        {
                                            title: "Smoking",
                                            content: getSelectedUserPreferences("Religious")
                                                ?.Smoking
                                                ? "Yes"
                                                : "No"
                                        }
                                    ]}
                                />
                            </div>

                            <Row>
                                <Col xs={12} sm={4}>
                                    <AccordionInnerListing
                                        heading="Personality"
                                        contents={[
                                            {
                                                title: "Values"
                                            }
                                        ]}
                                        pills={
                                            getSelectedUserPreferences("Personality")?.Values
                                                ? getSelectedUserPreferences(
                                                    "Personality"
                                                ).Values.map((value) => {
                                                    return { name: value };
                                                })
                                                : null
                                        }
                                    />
                                </Col>
                                <Col xs={12} sm={8}>
                                    <AccordionInnerListing
                                        heading=""
                                        contents={[
                                            {
                                                title: "Traits"
                                            }
                                        ]}
                                        pills={
                                            getSelectedUserPreferences("Personality")?.Traits
                                                ? getSelectedUserPreferences(
                                                    "Personality"
                                                ).Traits.map((value) => {
                                                    return { name: value };
                                                })
                                                : null
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Accordion>
            </>
        );
    };
    const getSelectedUserPersonality = (key) => {
        return selectedUserDetails.PersonalDetails[key];
    };
    const getSelectedUserProfessional = (key) => {
        return selectedUserDetails.ProfessionalDetials[key];
    };
    const getSelectedUserReligiousDetails = (key) => {
        return selectedUserDetails.ReligiousDetails[key];
    };
    const getSelectedUserPreferences = (key) => {
        return selectedUserDetails.Preferences[key];
    };

    const handleSelectTab = (item) => {
        setSelectedUserDetails(usersDetail.find((data) => data.Name === item.Name));
        setSuggestionOpenTab(null);
    };
    return (
        <>
            {isFetchingData && (
                <LayoutWrapper breadCrumb={false}>
                    <div className="hc-user-detail-container">
                        <Row>
                            <Col xs={12} lg={6}>
                                <SkeletonLoader isImage={true} rowCount={5} />
                            </Col>
                        </Row>
                    </div>
                </LayoutWrapper>
            )}
            {!isFetchingData && (
                <LayoutWrapper breadCrumb={false}>
                    <div className="see-connections-container">
                        <div className="row main-row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div>
                                    {usersDetail.length > 0 ? (
                                        <HorizontalTabs
                                            tabs={usersDetail.map((item, indux) => {
                                                return {
                                                    tabTitle: item.Name,
                                                    tabTitleImg: item.ProfilePictureUrl,
                                                    tabContent: tabConst(),
                                                    onClick: (e) => {
                                                        handleSelectTab(item);
                                                    }
                                                };
                                            })}
                                        />
                                    ) : (
                                            <span className="color-black no-connection-found">No connection found</span>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </LayoutWrapper>
            )}
        </>
    );
};

export default withNamespaces()(SeeConnections);
