import React from "react";
import { AvField } from "availity-reactstrap-validation";

import "./TextInput.scss";

const TextInput = (props) => {
    const {
        classes = "",
        value = "",
        placeholder = "",
        required = true,
        name,
        type = "text",
        inputIcon,
        validate,
        labelText,
        onChange
    } = props;
    return (
        <div className="position-relative">
            {labelText && <p className="color-black text-left h3 mb-3">{labelText}</p>}
            <AvField
                name={name}
                value={value}
                type={type}
                className={`hc-text-input form-control ${inputIcon && "pl-40"} ${classes}`}
                placeholder={placeholder}
                required={required}
                validate={validate}
                onChange={onChange}
            />
            {inputIcon && (
                <div className="input-icon">
                    <img src={inputIcon} alt="input-icon" />
                </div>
            )}
        </div>
    );
};

export default TextInput;
