import { APP_ROUTES } from "./helpers/routeHelpers";
import { withTitle } from "./hoc";
import { PageTitles } from "./constants";
import Dashboard from "./pages/Dashboard/Dashboard";
import UserListing from "./pages/UserListing/UserListing";
import ReportListing from "./pages/ReportListing/ReportListing";
import ReportDetail from "./pages/ReportDetail/ReportDetail";
import AboutUs from "./pages/AboutUs/AboutUs";
import HelpFaqs from "./pages/HelpFaqs/HelpFaqs";
import Settings from "./pages/Settings/Settings";
import AccountSettings from "./pages/Settings/AccountSettings";
import UserDetail from "./pages/UserDetail/UserDetail";
import CompareProfile from "./pages/CompareProfile/CompareProfile";
import { NotificationSettings } from "./pages/Settings";
import ActiveChats from "./pages/Chats/ActiveChats";
import SeeConnections from "./pages/Chats/SeeConnections";
import PreviousChats from "./pages/Chats/PreviousChats";
import PreviousChat from "./pages/Chat/PreviourChat";
import Chat from "./pages/Chat/Chat";
import AutoMatchListing from "./pages/AutoMatchListing/AutoMatchListing";
import MatchSuggestionListing from "./pages/MatchSuggestionListing/MatchSuggestionListing";
import Verification from "./pages/Verification/Verification";
const routes = [
    {
        path: APP_ROUTES.DASHBOARD,
        name: "Dashboard",
        exact: true,
        component: withTitle({ component: Dashboard, title: PageTitles.DASHBOARD })
    },
    {
        path: APP_ROUTES.USER_LISTING,
        name: "User Listing",
        exact: true,
        component: withTitle({ component: UserListing, title: PageTitles.USER_LISTING })
    },
    {
        path: APP_ROUTES.REPORT_DETAIL,
        name: "Report Detail",
        exact: true,
        component: withTitle({ component: ReportDetail, title: PageTitles.REPORT_DETAIL })
    },
    {
        path: APP_ROUTES.REPORT_LISTING,
        name: "Report Listing",
        exact: true,
        component: withTitle({ component: ReportListing, title: PageTitles.REPORT_LISTING })
    },
    {
        path: APP_ROUTES.ABOUT_US,
        name: "About Us",
        exact: true,
        component: withTitle({ component: AboutUs, title: PageTitles.ABOUT_US })
    },

    {
        path: APP_ROUTES.SETTINGS,
        name: "Settings",
        exact: true,
        component: withTitle({ component: Settings, title: PageTitles.SETTINGS })
    },
    {
        path: APP_ROUTES.ACCOUNT_SETTINGS,
        name: "Account Settings",
        exact: true,
        component: withTitle({ component: AccountSettings, title: PageTitles.ACCOUUNT_SETTINGS })
    },
    {
        path: APP_ROUTES.USER_DETAIL,
        name: "User Detail",
        exact: true,
        component: withTitle({ component: UserDetail, title: PageTitles.USER_DETAIL })
    },
    {
        path: APP_ROUTES.COMPARE_PROFILE,
        name: "Compare Profile",
        exact: true,
        component: withTitle({ component: CompareProfile, title: PageTitles.COMPARE_PROFILE })
    },
    {
        path: APP_ROUTES.NOTIFICATION_SETTINGS,
        name: "Notification Settings",
        exact: true,
        component: withTitle({
            component: NotificationSettings,
            title: PageTitles.NOTIFICATION_SETTINGS
        })
    },
    {
        path: APP_ROUTES.ACTIVE_CHATS,
        name: "Active Chats",
        exact: true,
        component: withTitle({ component: ActiveChats, title: PageTitles.USER_LISTING })
    },
    {
        path: APP_ROUTES.CHAT_LISTING,
        name: "Chat",
        exact: true,
        component: withTitle({ component: Chat, title: PageTitles.CHAT })
    },
    {
        path: APP_ROUTES.PREVIOUS_CHATS,
        name: "Previous Chats",
        exact: true,
        component: withTitle({ component: PreviousChats, title: PageTitles.PREVIOUS_CHATS })
    },
    {
        path: APP_ROUTES.HELPFAQS,
        name: "Help & Faqs",
        exact: true,
        component: withTitle({ component: HelpFaqs, title: PageTitles.HELPFAQS })
    },
    {
        path: APP_ROUTES.SEE_CONNECTIONS,
        name: "See Connections",
        exact: true,
        component: withTitle({ component: SeeConnections, title: PageTitles.SEE_CONNECTIONS })
    },
    {
        path: APP_ROUTES.AUTO_MATCH_LISTING,
        name: "Auto Match Listing",
        exact: true,
        component: withTitle({ component: AutoMatchListing, title: PageTitles.AUTO_MATCH_LISTING })
    },
    {
        path: APP_ROUTES.MATCH_LISTING,
        name: "Match Listing",
        exact: true,
        component: withTitle({ component: MatchSuggestionListing, title: PageTitles.MATCH_LISTING })
    },
    {
        path: APP_ROUTES.PREVIOUS_CHAT,
        name: "Previous Chat",
        exact: true,
        component: withTitle({ component: PreviousChat, title: PageTitles.PREVIOUS_CHAT })
    },
    {
        path: APP_ROUTES.ID_VERIFICATION,
        name: "Id Verification",
        exact: true,
        component: withTitle({ component: Verification, title: PageTitles.ID_VERIFICATION })
    }
];

export default routes;
