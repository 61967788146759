import React from "react";
import { withNamespaces } from "react-i18next";
import "./Chats.scss";

const ChatNavListItemAvatar = (props) => {
    const { multiple = false, classes } = props;
    const user = require("../../assets/images/realuser.png");
    return (
        <div
            className={`chat-nav-list-item-left  d-flex align-items-center justify-content-center position-relative ${
                multiple ? "p-r-xxl multiple" : "p-h-lg"
            } ${classes}`}
        >
            <img src={user} alt="user" />
            {multiple && (
                <img
                    className={`averlay-img  ${multiple && "position-absolute"}`}
                    src={user}
                    alt="user"
                />
            )}
        </div>
    );
};

export default withNamespaces()(ChatNavListItemAvatar);
