import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";
import { getIdsFromData } from "../../utils/commonUtils";
import Config from "../../Config";

export const MATCH_SUGGESTION_REQUEST = "MATCH_SUGGESTION_REQUEST";
export const MATCH_SUGGESTION_SUCCESS = "MATCH_SUGGESTION_SUCCESS";
export const MATCH_SUGGESTION_FAIL = "MATCH_SUGGESTION_FAIL";
export const MATCH_RESET = "MATCH_RESET";

export const getMatchListing = (params = {}, abortSignal = null) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    dispatch({ type: MATCH_SUGGESTION_REQUEST });

    return fetchUtil({
        url: appendQueryParams("/match/auto-match", { Limit: Config.LIMIT, ...params }),
        token,
        abortSignal
    })
        .then((res) => {
            if (res && res.Data) {
                dispatch({
                    type: MATCH_SUGGESTION_SUCCESS,
                    payload: res.Data.Matches,
                    count: res.Data.Count
                });
                return Promise.resolve(res.Data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            dispatch({ type: MATCH_SUGGESTION_FAIL });
            return Promise.reject(err);
        });
};

export const getFilteredMatchListing = (data, abortSignal = null) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    dispatch({ type: MATCH_SUGGESTION_REQUEST });
    return fetchUtil({
        body: JSON.stringify({ ...data, Pagination: { ...data.Pagination, Limit: Config.LIMIT } }),
        method: "POST",
        url: "/match/matches",
        token: token,
        abortSignal: abortSignal
    })
        .then((res) => {
            if (res && res.Data) {
                dispatch({
                    type: MATCH_SUGGESTION_SUCCESS,
                    payload: res.Data.Matches,
                    count: res.Data.Count
                });
                return Promise.resolve(res.Data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            dispatch({ type: MATCH_SUGGESTION_FAIL });
            return Promise.reject(err);
        });
};
export const resetMatchListing = () => (dispatch, getState) => {
    dispatch({
        type: MATCH_RESET
    });
};
