import React from "react";

import { Loader } from "../Loader";

const ThemeButton = (props) => {
    const { onClick, isLoading, disabled = false } = props;

    return (
        <button onClick={onClick} className={`btn btn-primary ` + props.class} disabled={disabled}>
            {isLoading ? <Loader /> : props.title}
        </button>
    );
};
export default ThemeButton;
