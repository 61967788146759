import phoneNumberValidation from "libphonenumber-js";
import { CommonConstants } from "../constants";

export const getCommaSeparatedNumber = (amount) => {
    return new Intl.NumberFormat("en-US").format(amount);
};

export const getFormattedAmountWithCurrency = (amount, currency) => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency || "USD",
        minimumFractionDigits: 0
    });
    return formatter.format(amount);
};
export const getChatMessageSenderName = (msg, index, messages) => {
    let previousMessage = messages[index - 1];
    let name;

    if (!previousMessage) {
        name = msg.SenderName;
    } else {
        if (msg.SenderId !== previousMessage.SenderId) {
            name = msg.SenderName;
        }
    }

    return name;
};
export const escapeHTMLElementsFromMessage = (message) => {
    //todo places in util helper
    var htmlEscapes = {
        "<": "&lt;",
        ">": "&gt;"
    };
    // Regex containing the keys listed immediately above.
    var htmlEscaper = /[<>]/g;
    // Escape a string for HTML interpolation.
    return message.replace(htmlEscaper, function (match) {
        return htmlEscapes[match];
    });
};

export const getObjectByKeys = (arr, key = "id", deleteKey = null) => {
    let obj = {};
    arr.forEach((val) => {
        obj[val[key]] = val;
        if (deleteKey) {
            delete obj[val[key]][deleteKey];
        }
    });
    return obj;
};

export const getIdsFromData = (data, key = "id") => {
    return data.map((item) => item[key]);
};
export const validatePhone = (phoneNumber) => {
    if (phoneNumber) {
        let parsPhoneNumber = phoneNumberValidation(
            `Phone:+${phoneNumber.value}`,
            phoneNumber.countryCode
        );
        if (parsPhoneNumber) {
            return parsPhoneNumber.isValid();
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const isValidImageSize = (fileSize) => {
    return fileSize < CommonConstants.MAX_IMAGE_SIZE;
};
export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
export const convertInches = (inches) => {
    if (inches) {
        let feetFromInches = Math.floor(inches / 12);
        let inchesRemainder = inches % 12;

        return feetFromInches + "ft " + inchesRemainder + '"';
    }
    return "";
};
export const camalToPascalCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export const objectToArray = (obj) => {
    let sol = [];
    for (let key in obj) {
        sol.push([key, obj[key]]);
    }
    return sol;
};

export const getRandomNumber = () => {
    return new Date().getTime().toString() + Math.floor(Math.random() * 1000000);
};
