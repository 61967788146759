import React from "react";

const Avatar = (props) => {
    const { height = "50", width = "50" } = props;
    return (
        <div className={`${props.className ? props.className : ""}`}>
            {props.src ? (
                <img
                    className="rounded-circle"
                    src={props.src}
                    alt={props.name}
                    width={width}
                    height={height}
                />
            ) : (
                <span
                    className="avatar-title rounded-circle"
                    style={{ width: `${width}px`, height: `${height}px` }}
                >
                    {props.name?.charAt(0)?.toUpperCase()}
                </span>
            )}
        </div>
    );
};

export default Avatar;
