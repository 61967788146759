export const APP_ROUTES = {
    DASHBOARD: "/dashboard",
    USER_LISTING: "/dashboard/user-listing",
    REPORT_LISTING: "/dashboard/report-listing",
    REPORT_DETAIL: "/dashboard/report-detail/:id",
    HELPFAQS: "/dashboard/settings/help-faqs",
    ABOUT_US: "/dashboard/settings/about-us",
    USER_DETAIL: "/dashboard/user-detail/:id",
    COMPARE_PROFILE: "/dashboard/compare-profile",
    SETTINGS: "/dashboard/settings",
    ACCOUNT_SETTINGS: "/dashboard/settings/account-settings",
    NOTIFICATION_SETTINGS: "/dashboard/settings/notification-settings",
    ACTIVE_CHATS: "/dashboard/active-chats",
    CHAT_LISTING: "/dashboard/chat/:id?",
    PREVIOUS_CHATS: "/dashboard/previous-chats",
    SEE_CONNECTIONS: "/dashboard/see-connections/:id",
    AUTO_MATCH_LISTING: "/dashboard/auto-match-listing",
    MATCH_LISTING: "/dashboard/match-listing/:id",
    PREVIOUS_CHAT: "/dashboard/previous-chat/:id?",
    ID_VERIFICATION: "/dashboard/id-verification"
};

export const getRoute = (route, obj = {}) => {
    if (Object.keys(obj).length) {
        let objectKeys = Object.keys(obj);
        objectKeys.forEach((item) => {
            route = route.replace(new RegExp(/:([\d\w])+/, "i"), (match) => {
                let formattedMatchedValue = match.slice(1);
                return obj[formattedMatchedValue];
            });
        });
        return route;
    }
    return route;
};
