// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation";
import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { withNamespaces } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ArrowRightIcon from "../../assets/images/arrow-right.svg";
import CameraImg from "../../assets/images/camera.svg";
import DoneIcon from "../../assets/images/done.svg";
import lockIcon from "../../assets/images/lock.svg";
import loveBubble from "../../assets/images/love-bubble.svg";
// import images
import personIcon from "../../assets/images/person.svg";
import BlockButton from "../../components/Button/Button";
// action
// import { registerUser, apiError, registerUserFailed } from "../../store/actions";
import { TextInput } from "../../components/TextInput";
import { createProfile } from "../../store/actions/createProfileAction";
import { UserStatusCode } from "../../constants";
import { validatePhone } from "../../utils/commonUtils.js";
import { mediaService, validateMedia } from "../../utils/mediaService.js";
import notificationToastr from "../../utils/toastUtil";
import "./authentication.scss";
import { connect, useDispatch } from "react-redux";
import { userVerified } from "../../store/actions";
import { APP_ROUTES } from "../../helpers/routeHelpers";

const CreateProfile = (props) => {
    const auth = useSelector((state) => state.auth);
    const history = useHistory();
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState();
    const [isValidPhone, setisValidPhone] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isProfileCreated, setIsProfileCreate] = useState(false);

    useEffect(() => {
        if (!auth.user) {
            history.push(APP_ROUTES.USER_LISTING);
        } else if (auth.user.Status !== UserStatusCode.UNVERIFIED) {
            history.push(APP_ROUTES.USER_LISTING);
        }
    }, []);
    const handleValidSubmit = (event, values) => {
        if (selectedImage && isValidPhone) {
            setIsLoading(true);
            dispatch(mediaService("/media/upload", selectedImage))
                .then((res) => {
                    handleSubmitCreateProfile({
                        FullName: values["hc-login-input-name"],
                        PreviousPassword: values["hc-login-input-old-password"],
                        NewPassword: values["hc-login-input-password"],
                        MediaId: res.Data.Id,
                        Phone: { Number: phoneNumber.value, Extension: phoneNumber.countryCode }
                    });
                })
                .catch((err) => {
                    notificationToastr.error(err.Message);
                    setIsLoading(false);
                });
        } else {
            if (isValidPhone) {
                notificationToastr.error(
                    props.t("Profile picture is required"),
                    props.t("Picture not selected")
                );
            }
        }
    };

    const handleSubmitCreateProfile = (data) => {
        dispatch(createProfile(data))
            .then((res) => {
                setIsProfileCreate(true);
                setIsLoading(false);
            })
            .catch((err) => {
                notificationToastr.error(err.Message);
                setIsLoading(false);
            });
    };

    const handleAcceptedFiles = (acceptedFiles) => {
        setSelectedImage(validateMedia(acceptedFiles, props.t));
    };

    const handleVerifiedUser = () => {
        dispatch(userVerified());
        history.push("/dashboard");
    };

    return (
        <>
            <div className="hc-login-container account-pages d-flex align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        {isProfileCreated ? (
                            <Col md={6} lg={4} xl={3}>
                                <div className="bg-transparent">
                                    <div className="p-2">
                                        <div className="d-flex justify-content-center">
                                            <span>
                                                <img
                                                    src={loveBubble}
                                                    alt=""
                                                    className="rounded-circle"
                                                    width="200"
                                                />
                                            </span>
                                        </div>
                                        <div className="profile-success-card">
                                            <div className="profile-success-card-body">
                                                <p className="color-black m-0 mb-1">
                                                    {props.t("Profile completed!")}
                                                </p>
                                                <span>
                                                    <img src={DoneIcon} alt="done" width="30" />
                                                </span>
                                            </div>
                                            <div className="profile-success-card-footer">
                                                <BlockButton
                                                    className="btn button-type-1 btn-primary btn-block no-radius"
                                                    type="button"
                                                    onClick={handleVerifiedUser}
                                                >
                                                    {props.t("Access Your Dashboard")}
                                                    <span className="access-dashboard-btn-icon">
                                                        <img
                                                            src={ArrowRightIcon}
                                                            alt="arrow-right"
                                                            width="16"
                                                        />
                                                    </span>
                                                </BlockButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ) : (
                            <Col md={6} lg={5} xl={4}>
                                <div className="bg-transparent">
                                    <div className="p-2">
                                        <div className="hc-create-profile-upload-img d-flex justify-content-center mb-5">
                                            <span className="avatar-xl rounded-circle position-relative bg-light align-items-center justify-content-center d-flex">
                                                {selectedImage && (
                                                    <img
                                                        className="avatar-xl rounded-circle position-relative bg-light align-items-center justify-content-center d-flex"
                                                        src={selectedImage.preview}
                                                    />
                                                )}

                                                <div className="hc-upload-img-btn">
                                                    <Dropzone
                                                        multiple={false}
                                                        onDrop={(acceptedFiles) => {
                                                            handleAcceptedFiles(acceptedFiles);
                                                        }}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div>
                                                                <img
                                                                    src={CameraImg}
                                                                    alt=""
                                                                    {...getRootProps()}
                                                                />
                                                                <input {...getInputProps()} />
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </span>
                                        </div>
                                        <AvForm
                                            className="form-horizontal text-center"
                                            onValidSubmit={handleValidSubmit}
                                        >
                                            <div className="form-group">
                                                <TextInput
                                                    name="hc-login-input-name"
                                                    classes="body-text-5"
                                                    placeholder={props.t("Name")}
                                                    required={true}
                                                    type={"text"}
                                                    inputIcon={personIcon}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: props.t(
                                                                "Please fill in the required fields"
                                                            )
                                                        },
                                                        pattern: {
                                                            value: "^[a-zA-Z0-9 ]{6,55}$",
                                                            errorMessage: props.t(
                                                                `Please enter a valid name, e.g John Smith (min 6 character, no special characters e.g, . ,  ;  ! $ / etc.) (max length 55)`
                                                            )
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group phone-input-wrapper">
                                                <PhoneInput
                                                    inputClass={!isValidPhone && "custom-inp-error"}
                                                    name="PhoneInput"
                                                    country={"pk"}
                                                    autoFormat={true}
                                                    onBlur={() => {
                                                        setisValidPhone(validatePhone(phoneNumber));
                                                    }}
                                                    disableSearchIcon={true}
                                                    enableSearch={true}
                                                    countryCodeEditable={false}
                                                    searchStyle={{
                                                        fontFamily: "inherit"
                                                    }}
                                                    inputClass="hc-text-input text-center pl-40 body-text-5"
                                                    inputStyle={{
                                                        width: "80%",
                                                        margin: 0,
                                                        display: "inline-block",
                                                        right: "-25px",
                                                        paddingLeft: "10px"
                                                    }}
                                                    dropdownStyle={{
                                                        borderRadius: "6px",
                                                        textAlign: "left",
                                                        maxHeight: "180px"
                                                    }}
                                                    buttonStyle={{
                                                        borderRadius: "6px"
                                                    }}
                                                    value={"pakistan"}
                                                    onChange={(value, data) => {
                                                        setPhoneNumber({ value: value, ...data });
                                                    }}
                                                />
                                                {!isValidPhone && (
                                                    <div
                                                        className={
                                                            !isValidPhone && "custom-inp-error-text"
                                                        }
                                                    >
                                                        This field is invalid
                                                    </div>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <TextInput
                                                    name="hc-login-input-old-password"
                                                    classes="body-text-5"
                                                    placeholder={props.t("Old password")}
                                                    required={true}
                                                    type={"password"}
                                                    inputIcon={lockIcon}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: props.t(
                                                                "Please fill in the required fields"
                                                            )
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    name="hc-login-input-password"
                                                    classes="body-text-5"
                                                    placeholder={props.t("New password")}
                                                    required={true}
                                                    type={"password"}
                                                    inputIcon={lockIcon}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: props.t(
                                                                "Please fill in the required fields"
                                                            )
                                                        },
                                                        pattern: {
                                                            value: "^.{6,138}$",
                                                            errorMessage: props.t(
                                                                "Password must be between 6 to 128 characters"
                                                            )
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <BlockButton
                                                    className="btn button-type-1 btn-md uppercase body-text-8 n w-100 m-t-sm"
                                                    type="submit"
                                                    isLoading={isLoading}
                                                    onClick={() => {
                                                        setisValidPhone(validatePhone(phoneNumber));
                                                    }}
                                                >
                                                    {props.t("Submit")}
                                                </BlockButton>
                                            </div>
                                        </AvForm>
                                    </div>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default withNamespaces()(CreateProfile);
