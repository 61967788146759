export default {
    //to user
    AcceptChatReminder: 1,
    NewMatchToUser: 2,
    ReportSent: 3,
    ReportResolved: 4,
    BeingReported: 5,
    BeingSuspended: 6,
    BeingRemoved: 7,
    ChatRequest: 8,
    ContactShareRequest: 9,
    SuspensionOver: 10,
    NewMessage: 11,
    //to match maker
    MatchAssignedToUser: 12,
    UserPreferenceCompleted: 13,
    ReferalReady: 14,
    NewMessageToMatchMakerIndividual: 15,
    ChatEnded: 16, //to match maker
    MatchAccepted: 17,
    MatchRejected: 18,
    WaitingForMatches: 19,
    PhoneNumberIsChanged: 20,
    DisplayPictureChanged: 21,
    NameChanged: 22,
    PasswordChanged: 23,
    ExchangeNumberRequest: 24,
    UserUpdatedProfile: 25,
    NewUserJoinsApp: 36,
    //to guardian
    ChatInitiated: 27,
    ChatEndedGuardian: 28,
    NewMessageToGuardian: 29,
    GuardianAddedInAGroup: 30,
    //to matchmaker
    NewMessageToMatchMakerGroup: 31,
    PrefrencesComplete: 32,
    ReceiveReferals: 33,
    
};
