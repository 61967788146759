import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";
import Config from "../../Config";

export const NOTIFICATIONS_REQUEST = "NOTIFICATIONS_REQUEST";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_FAIL = "NOTIFICATIONS_FAIL";
export const NOTIFICATIONS_UNREAD_REQUEST = "NOTIFICATIONS_UNREAD_REQUEST";
export const NOTIFICATIONS_UNREAD_SUCCESS = "NOTIFICATIONS_UNREAD_SUCCESS";
export const GET_NOTIFICATION_PARAM = {
    Limit: Config.LIMIT,
    Column: 'CreatedAt',
    Direction: 'DESC',
};
export const NOTIFICATION_STATUS = {
    unReadStatus: 0,
    readStatus: 1,
};

export const getNotifications = (params = {}) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    dispatch({ type: NOTIFICATIONS_REQUEST, Page: params.Page });

    return fetchUtil({
        url: appendQueryParams("/notification/notifications", {
            Limit: GET_NOTIFICATION_PARAM.Limit,
            Column: GET_NOTIFICATION_PARAM.Column,
            Direction: GET_NOTIFICATION_PARAM.Direction,
            ...params
        }),
        token
    })
        .then((res) => {
            if (res && res.Data) {
                dispatch({
                    type: NOTIFICATIONS_SUCCESS,
                    payload: res.Data.Notifications,
                    count: res.Data.Count,
                    UnreadCount: res.Data.UnreadCount
                });
                return Promise.resolve(res.Data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            dispatch({ type: NOTIFICATIONS_FAIL });
            return Promise.reject(err);
        });
};



export const getUnreadNotificationCount = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    dispatch({ type: NOTIFICATIONS_UNREAD_REQUEST });

    return fetchUtil({
        url: "/notification/read-notifications",
        body: JSON.stringify({ "NotificationIds": data }),
        method: "POST",
        token
    })
        .then((res) => {
            if (res && res.Status == 200) {
                dispatch({ type: NOTIFICATIONS_UNREAD_SUCCESS, UnreadCount: res.Data.UnreadCount, unReadNotificationsArray: data });
                return Promise.resolve(res.Data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            dispatch({ type: NOTIFICATIONS_FAIL });
            return Promise.reject(err);
        });
};
