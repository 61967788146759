import {
    ENTITY_ADD_CHAT,
    ENTITY_UPDATE_CHAT
} from "../actions";

const initialState = {
    chats: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ENTITY_ADD_CHAT:
            return {
                ...state,
                chats: {
                    ...state.chats,
                    ...action.payload
                }
            };
        case ENTITY_UPDATE_CHAT:
            return {
                ...state,
                chats: {
                    ...state.chats,
                    [action.id]: {
                        ...state.chats[action.id],
                        ...action.data
                    }
                }
            };
        default:
            return state;
    }
};
