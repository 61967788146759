import Config from "../../Config";
import { SUGGESTION_USER_UPDATE } from "../actions";

const initialState = {
    users: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SUGGESTION_USER_UPDATE:
            return { users: action.users };

        default:
            return state;
    }
};
