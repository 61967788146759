import { REPORT_LISTING_REQUEST, REPORT_LISTING_SUCCESS, REPORT_LISTING_FAIL } from "../actions";

const initialState = {
    fetching: false,
    count: 0,
    list: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REPORT_LISTING_REQUEST:
            return {
                ...state,
                fetching: true,
                list: []
            };
        case REPORT_LISTING_SUCCESS:
            return {
                ...state,
                fetching: false,
                count: action.count,
                list: action.payload
            };
        case REPORT_LISTING_FAIL:
            return {
                ...state,
                fetching: false
            };
        default:
            return state;
    }
};
